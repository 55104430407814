import React, { Dispatch, ReactNode, SetStateAction } from "react";
import {
  useLocalStorageUntilLogout,
  UseLocalStorageUntilLogoutProps,
} from "../../shared/customHoooks/useLocalStorageUntilLogout";
import { CORELogo } from "../Content/CORELogo";
import { COREIcon } from "../Content/COREIcon";
import classNames from "classnames";
import {
  CORETypographyInput,
  CORETypographyInputProps,
} from "../Typography/CORETypographyInput";
import { COREDivider } from "../Layout/COREDivider";
import Menu, { Item, SubMenu } from "rc-menu";
import { icon as getIcon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Space } from "antd";
import { Link } from "react-router-dom";
import {
  generateTestId,
  TestID,
  TestIDWrapper,
} from "../../shared/testids/testids";
import { FeatureFlag } from "../../shared/customHoooks/useFeatureFlags";

export type MenuItemProps = {
  key: string;
  label: string;
  icon?: ReactNode;
  url: string;
  featureFlag: FeatureFlag;
  children?: MenuItemProps[];
};

type COREMainMenuItemProps = {
  menuItems: MenuItemProps[];
  isHidden: boolean;
  setIsHidden: ReturnType<UseLocalStorageUntilLogoutProps<boolean>>[1];
  selectedKeys: string[];
  initOpenKeys: string[];
  isPanel: boolean;
  setIsPanel: ReturnType<UseLocalStorageUntilLogoutProps<boolean>>[1];
  isMobileScreen: boolean;
  setDashboardModelVisible: Dispatch<SetStateAction<boolean>>;
};

type IconAndTitleProps = {
  testID: TestID;
  title: MenuItemProps["label"];
  type: CORETypographyInputProps["type"];
  icon?: ReactNode;
  isBarMode?: boolean;
  url: string;
  isRootLevel: boolean;
  noChildren?: boolean;
};

const linkBlock = "core-main-menu-link-block";
const iconStyle = "core-main-menu-icon";
const menuLinkContainer = "core-main-menu-link-container";

const MainMenuItem: React.FC<{
  testID: TestID;
  item?: MenuItemProps;
  isShow: boolean;
  labelType: CORETypographyInputProps["type"];
}> = ({ testID, item, isShow, labelType }) => {
  if (!isShow || item === undefined) return null;
  const { key, url, label } = item;
  return (
    <Item key={key}>
      <Link to={url} className={linkBlock}>
        <CORETypographyInput type={labelType} testID={testID}>
          {label}
        </CORETypographyInput>
      </Link>
    </Item>
  );
};

const IconMenu: React.FC<Pick<IconAndTitleProps, "testID" | "icon">> = ({
  testID,
  icon,
}) => {
  return (
    <TestIDWrapper className={iconStyle} testID={testID}>
      {icon}
    </TestIDWrapper>
  );
};

const IconAndTitle: React.FC<IconAndTitleProps> = ({
  testID,
  title,
  icon,
  isBarMode = false,
  type,
  url,
  isRootLevel,
  noChildren,
}) => {
  const renderIconMenu = () => (
    <IconMenu icon={icon} testID={`page-menu-link-${title}`} />
  );

  const renderLinkWithIcon = () => (
    <Link
      to={url}
      className={classNames(iconStyle)}
      onClick={(e) => e.stopPropagation()}
    >
      <TestIDWrapper testID={`page-menu-link-${title}`}>{icon}</TestIDWrapper>
    </Link>
  );

  const renderTypography = (linkUrl?: string) => {
    const content = (
      <CORETypographyInput
        testID={generateTestId("page", `main-menu-${title}`)}
        type="menu"
      >
        {title}
      </CORETypographyInput>
    );

    return linkUrl ? <Link to={linkUrl}>{content}</Link> : content;
  };

  const renderRootContent = () => {
    if (isBarMode && !noChildren) {
      return isRootLevel ? renderIconMenu() : null;
    }

    return isRootLevel ? renderLinkWithIcon() : null;
  };

  return (
    <div className={menuLinkContainer}>
      {renderRootContent()}
      {isRootLevel ? (
        renderTypography(noChildren ? url : undefined)
      ) : (
        <CORETypographyInput testID={testID} type={type}>
          {title}
        </CORETypographyInput>
      )}
    </div>
  );
};

const renderMenuItems = (
  items: MenuItemProps[],
  isBarMode: boolean,
  setDashboardModelVisible: COREMainMenuItemProps["setDashboardModelVisible"],
  level = 0,
  parentItem?: MenuItemProps
) => {
  return items.map((item, index) => {
    const children = item.children;
    const hasChildren = children && children.length > 0;
    const isLastItem = index === items.length - 1;
    const labelType = level === 0 ? "menu" : "default";
    if (hasChildren) {
      return (
        <React.Fragment key={`${item.key}-${item.label}`}>
          <MainMenuItem
            testID={generateTestId("page", `sub-main-menu-${item.label}`)}
            item={parentItem}
            isShow={isBarMode && index === 0 && level !== 0}
            labelType={labelType}
          />
          <SubMenu
            key={item.key}
            title={
              <IconAndTitle
                testID={generateTestId(
                  "page",
                  `${level === 0 ? "main-menu" : "sub-main-menu"}-${item.label}`
                )}
                {...(level === 0 && { icon: item.icon })}
                title={item.label}
                url={item.url}
                type="default"
                isBarMode={isBarMode}
                isRootLevel={level === 0}
              />
            }
            popupClassName="core-main-menu-popup"
            popupOffset={[0, 0]}
          >
            {renderMenuItems(
              children,
              isBarMode,
              setDashboardModelVisible,
              level + 1,
              item
            )}
          </SubMenu>
          {level !== 0 && !isLastItem && (
            <div className={"core-main-menu-divider"}>
              <COREDivider space={"none"} />
            </div>
          )}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={`${item.key}-${item.label}`}>
        <MainMenuItem
          testID={generateTestId("page", `main-menu-${item.label}`)}
          item={parentItem}
          isShow={isBarMode && index === 0 && level !== 0}
          labelType={labelType}
        />
        {item.label === "Create New" ? (
          <Item key={item.key} onClick={() => setDashboardModelVisible(true)}>
            <Space size={8}>
              <IconMenu
                icon={item.icon}
                testID={`page-menu-link-${item.label}`}
              />
              <CORETypographyInput type={"default"}>
                {item.label}
              </CORETypographyInput>
            </Space>
          </Item>
        ) : (
          <Item key={item.key}>
            {level === 0 ? (
              <IconAndTitle
                testID={generateTestId("page", `main-menu-${item.label}`)}
                icon={item.icon}
                title={item.label}
                url={item.url}
                type={"menu"}
                isBarMode={isBarMode}
                isRootLevel={true}
                noChildren
              />
            ) : (
              <Link to={item.url} className={linkBlock}>
                <CORETypographyInput
                  type={"default"}
                  testID={generateTestId(
                    "page",
                    `${item.featureFlag}-menu-item`
                  )}
                >
                  {item.label}
                </CORETypographyInput>
              </Link>
            )}
          </Item>
        )}
      </React.Fragment>
    );
  });
};

const ExpandIcon: React.FC<{ isOpen?: boolean; isBarMode: boolean }> = ({
  isOpen,
  isBarMode,
}) => {
  return (
    <div className="expand-icon">
      {isBarMode ? (
        <COREIcon icon={getIcon({ name: "caret-right", style: "solid" })} />
      ) : (
        <COREIcon
          icon={
            isOpen
              ? getIcon({ name: "caret-down", style: "solid" })
              : getIcon({ name: "caret-right", style: "solid" })
          }
        />
      )}
    </div>
  );
};

export const COREMainMenuItem: React.FC<COREMainMenuItemProps> = ({
  menuItems,
  isHidden,
  setIsHidden,
  selectedKeys,
  initOpenKeys,
  isPanel,
  setIsPanel,
  isMobileScreen,
  setDashboardModelVisible,
}) => {
  const [openKeys, setOpenKeys] = useLocalStorageUntilLogout<string[]>({
    key: "mainMenuOpenKeys",
    initialValue: initOpenKeys,
  });

  const menuMode = isMobileScreen ? "inline" : isPanel ? "inline" : "vertical";
  const isBarMode = !isPanel && !isMobileScreen;
  const classWrapper = classNames(
    "core-main-menu",
    { hidden: isMobileScreen && isHidden },
    { mobile: isMobileScreen },
    { bar: !isMobileScreen && !isPanel }
  );

  const handleOpenChange = (openKeys: string[]) => {
    setOpenKeys(openKeys);
  };

  const handleTogglePanel = () => {
    setIsPanel(!isPanel);
  };

  return (
    <div
      className={classNames(
        "side-nav-block",
        { bar: !isMobileScreen && !isPanel },
        { mobile: isMobileScreen }
      )}
    >
      <div className={classWrapper}>
        <div className={"mobile-header"}>
          <div className={"menu-header"}>
            <div className={"logo"}>
              <CORELogo />
            </div>
            <div
              className={"close-button"}
              onClick={() => {
                setIsHidden(true);
              }}
            >
              <COREIcon
                icon={getIcon({ name: "circle-xmark", style: "regular" })}
              />
            </div>
          </div>
          <div className={"progress-gradient"} />
        </div>
        <div className={"menu-content"}>
          <div className={"hidden-scrollable"}>
            <Menu
              mode={menuMode}
              onOpenChange={handleOpenChange}
              openKeys={openKeys}
              inlineIndent={0}
              triggerSubMenuAction={"click"}
              expandIcon={({ isOpen }) => (
                <ExpandIcon isOpen={isOpen} isBarMode={isBarMode} />
              )}
              selectedKeys={selectedKeys}
            >
              {renderMenuItems(menuItems, isBarMode, setDashboardModelVisible)}
            </Menu>
          </div>
          <div className={"toggle-button-row"}>
            {isMobileScreen ? (
              <></>
            ) : (
              <div onClick={handleTogglePanel} className={"toggle-button"}>
                {isPanel ? (
                  <COREIcon
                    icon={getIcon({ name: "chevrons-left", style: "regular" })}
                  />
                ) : (
                  <COREIcon
                    icon={getIcon({ name: "chevrons-right", style: "regular" })}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isMobileScreen && !isHidden && <div className={"mobile-overlay"} />}
    </div>
  );
};
