import { parse } from "url";
import qs from "qs";

const toString = Object.prototype.toString;
const OBJECT = "[object Object]";
const ARRAY = "[object Array]";

function mergePair(a, b) {
  if (a === void 0) {
    return b;
  }
  if (b === void 0) {
    return a;
  }

  const aType = toString.call(a);
  const bType = toString.call(b);
  if (aType === ARRAY) {
    return a.concat(b);
  }
  if (bType === ARRAY) {
    return [a].concat(b);
  }
  if (aType !== OBJECT || bType !== OBJECT) {
    return b;
  }
  return Object.keys(b).reduce((memo, key) => {
    memo[key] = mergePair(a[key], b[key]);
    return memo;
  }, a);
}

function merge(...args) {
  return args.length ? args.reduce(mergePair) : null;
}

function omit(object, props) {
  if (!Array.isArray(props)) {
    return { ...object };
  }

  return Object.keys(object || {}).reduce((memo, key) => {
    if (props.indexOf(key) === -1) {
      memo[key] = object[key];
    }
    return memo;
  }, {});
}

/* eslint no-useless-escape: 0 */
const rxClean = /(\(:[^\)]+\)|:[^\/]+\/?)/g;

export function urlTransform(url, params = {}, options = {}) {
  if (!url) {
    return "";
  }
  const usedKeys = {};
  const urlWithParams = Object.keys(params).reduce((url, key) => {
    const value = params[key];
    const rx = new RegExp(`(\\(:${key}\\)|:${key})(\/?)`, "g");
    return url.replace(rx, (_, _1, slash) => {
      usedKeys[key] = value;
      return value ? value + slash : value;
    });
  }, url);

  if (!urlWithParams) {
    return urlWithParams;
  }
  const { protocol, host, path } = parse(urlWithParams);
  const cleanURL = host
    ? `${protocol}//${host}${path.replace(rxClean, "")}`
    : path.replace(rxClean, "");
  const usedKeysArray = Object.keys(usedKeys);
  if (usedKeysArray.length !== Object.keys(params).length) {
    const urlObject = cleanURL.split("?");
    const { arrayFormat, delimiter } = options;
    const qsParseOptions = {
      arrayFormat,
      delimiter,
      ...options.qsParseOptions,
    };
    const mergeParams = merge(
      urlObject[1] && qs.parse(urlObject[1], qsParseOptions),
      omit(params, usedKeysArray)
    );
    const qsStringifyOptions = {
      arrayFormat,
      delimiter,
      ...options.qsStringifyOptions,
    };
    const urlStringParams = qs.stringify(mergeParams, qsStringifyOptions);
    return `${urlObject[0]}?${urlStringParams}`;
  }
  return cleanURL;
}
