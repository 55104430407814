import React from "react";
import { Query, useIsFetching, useQueryClient } from "react-query";
import { generateTestId } from "../testids/testids";
import { COREProgress } from "../../COREDesignSystem/Feedback/COREProgress";
import "./APIProgress.less";

const isFetchingQuery = (query: Query): boolean => query.state.isFetching;
const isFreshQuery = (query: Query): boolean => {
  if (isFetchingQuery(query)) return false;
  if (!query.getObserversCount()) return false;
  return !query.isStale();
};

export const APIProgress: React.FC = () => {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();

  const queries = queryCache.findAll();

  const hasFresh = queries.filter(isFreshQuery).length;
  const hasFetching = queries.filter(isFetchingQuery).length;

  const isFetching = useIsFetching();

  const percent = isFetching ? hasFresh / (hasFresh + hasFetching) : 1; // needed otherwise we show 0 percent when we have not loaded anything

  return (
    <COREProgress
      testID={generateTestId("page", "progress")}
      percent={percent}
      showInfo={false}
      type={"line"}
      size={"small"}
      status={"normal"}
      hideOnComplete={false}
      hideOnEmpty={false}
      className={"custom-progress-bar"}
    />
  );
};
