import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "react-use";

export const usePageChange = (onPageChange: (newURL: string) => void) => {
  const location = useLocation();
  const locationPath = location.pathname;
  const previousLocationPath = usePrevious(locationPath);
  useEffect(() => {
    if (locationPath !== undefined && previousLocationPath !== locationPath) {
      onPageChange(locationPath);
    }
  }, [locationPath, previousLocationPath, onPageChange]);
};
