import React, { ErrorInfo, ReactNode } from "react";
import { COREError } from "../../COREDesignSystem/Content/COREError";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface State {
  hasError: boolean;
  isChunkError: boolean;
}

class ErrorBoundary extends React.Component<
  RouteComponentProps<object>,
  State
> {
  public state: State = {
    hasError: false,
    isChunkError: false,
  };

  static getDerivedStateFromError(error: Error) {
    console.error(error);

    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      isChunkError: error.message.toLowerCase().includes("chunk"),
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  render() {
    if (this.state.hasError && this.state.isChunkError) {
      window.location.reload();
      return;
    }

    if (this.state.hasError) {
      return <COREError />;
    }

    return this.props.children;
  }
}

export const ErrorBoundaryWithRouter = withRouter(ErrorBoundary);

export const HOCErrorBoundary = <T extends object>(
  Child: React.ComponentType<T>
) =>
  function ErrorBoundaryHOC(props: T & { children?: ReactNode }) {
    return (
      <ErrorBoundaryWithRouter>
        <Child {...props} />
      </ErrorBoundaryWithRouter>
    );
  };
