import React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

type Size = "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
type Animate =
  | "none"
  | "beat"
  | "fade"
  | "beatFade"
  | "bounce"
  | "shake"
  | "spin"
  | "spinPulse"
  | "spinReverse"
  | "pulse";
export type COREIconProps = Omit<FontAwesomeIconProps, "size"> & {
  size?: Size;
  animate?: Animate;
};

const sizePixels = {
  xxs: "12px",
  xs: "16px",
  sm: "20px",
  md: "24px",
  lg: "32px",
  xl: "48px",
  xxl: "72px",
};

export const COREIcon: React.FC<COREIconProps> = ({
  icon,
  size,
  fixedWidth = true,
  color,
  animate = "none",
  ...props
}) => {
  const style = { ...(size && { fontSize: sizePixels[size] }), ...props.style };
  return (
    <FontAwesomeIcon
      color={color}
      {...props}
      icon={icon}
      style={style}
      fixedWidth={fixedWidth}
      {...(animate &&
        animate !== "none" && {
          ...(animate === "spinReverse" && { spin: true }),
          [animate]: true,
        })}
    />
  );
};
