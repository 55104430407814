import { Form, FormProps } from "antd";
import React from "react";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { Store as StoreType } from "antd/lib/form/interface";
import { FormInstance } from "antd/lib/form";
import "./COREFormItem.less";
import classNames from "classnames";
import { Size } from "./COREInput";

export type Store = StoreType;
export type COREFormInstance<T> = FormInstance<T>;
export type COREFormProps = Omit<FormProps, "layout"> & {
  testID: TestID;
  labelSize?: Size;
  layout: "horizontal" | "vertical" | "none";
  gridTemplateColumns?: string;
};

export const COREForm: React.FC<COREFormProps> = ({
  testID,
  className,
  labelSize,
  layout,
  children,
  gridTemplateColumns = "auto 1fr",
  ...props
}) => {
  return (
    <TestIDWrapper testID={testID}>
      <Form
        style={{ gridTemplateColumns }}
        className={classNames(className, "core-form", {
          [layout ?? ""]: layout,
          [labelSize ?? ""]: labelSize,
        })}
        layout={layout !== "none" ? layout : undefined}
        {...props}
      >
        {children}
      </Form>
    </TestIDWrapper>
  );
};
