import React from "react";
import {
  ColorHex,
  ColorName,
  getColor,
  grey140,
  grey60,
} from "../Content/COREColour";
import { ChartDataType, downloadCSVFile } from "./COREChart";
import Plotly, { Layout, PlotData } from "plotly.js/dist/plotly-myPlotly.js";
import { ChartProps } from "./ChartHOC";
import { COREChartLegend, Legend } from "./COREChartLegend";
import { useDeepCompareEffect } from "react-use";
import { AggregatesChartProps } from "../../modules/chartBuilder/AggregatesChartV2";
import { chartFont } from "../../shared/global";
import equal from "fast-deep-equal";

const lineColor = "line.color";

type CategoryChartValue = {
  category: string;
  value: number | null;
};

type CategoryChartTrace = {
  traceName: string;
  values: CategoryChartValue[];
  "line.color"?: ColorName | ColorHex;
  "line.dash"?: Partial<PlotData>["line.dash"];
};

type CategoryChart = CategoryChartTrace[];

export type CORERadarChartProps = {
  traces: CategoryChart;
  csvCategory?: string;
  downloadCsv?: boolean;
} & Pick<
  AggregatesChartProps,
  | "chartLegendRef"
  | "downloadPng"
  | "testID"
  | "plotRef"
  | "chartTitle"
  | "chartId"
  | "showlegend"
  | "layout"
  | "chartPanel"
  | "empty"
>;

const prepareChartColor = (trace: CategoryChart): CategoryChart =>
  trace.map((data, index) => {
    const itemColor: CategoryChartTrace[`line.color`] = data[`line.color`]
      ? data[`line.color`]
      : getColor(index);

    data = {
      ...data,
      "line.color": getColor(itemColor) ?? itemColor,
    };

    return data;
  });

const legendsWithTagStyle = (tracesWithColour: CategoryChart): Legend[] =>
  tracesWithColour?.map((t) => {
    let tagStyle = {};

    if (
      t["line.dash"] &&
      ["dot", "dash", "longdash", "dashdot", "longdashdot"].includes(
        t["line.dash"]
      )
    ) {
      tagStyle = { tagStyle: `dashed` };
    } else if (t["line.dash"] === `solid`) {
      tagStyle = { tagStyle: `outline` };
    } else {
      tagStyle = { tagStyle: `solid` };
    }

    return {
      label: t.traceName,
      color: t[lineColor] as ColorHex,
      checked: true,
      key: t.traceName?.replaceAll(" ", "-"),
      ...tagStyle,
    };
  });

const getCategories = (traces: CategoryChart) => {
  let categories: string[] = [];
  traces.forEach((t) => {
    const cateogory = t.values.map((v) => v.category);
    categories = [...categories, ...cateogory];
  });

  return [...new Set(categories)];
};

const setTracesData = (traces: CategoryChart): CategoryChart =>
  traces.map((t) => {
    const values = [...t.values, t.values[0]];

    return {
      ...t,
      values: values,
    };
  });

export const defaultCORERadarChartLayout: Partial<Layout> = {
  font: {
    family: chartFont,
  },
  polar: {
    angularaxis: {
      rotation: 90,
      direction: "clockwise",
      ticks: "",
      linecolor: grey60,
      gridcolor: grey60,
      tickfont: {
        color: grey140,
        size: 16,
      },
    },
    radialaxis: {
      angle: 0,
      ticklen: 2.3,
      tickcolor: grey140,
      tickfont: {
        color: grey140,
      },
      linecolor: grey140,
      gridcolor: grey60,
    },
  },
  showlegend: false,
  margin: {
    t: 40,
    b: 40,
    l: 40,
    r: 40,
  },
} as const;

export const RadarChart: React.FC<CORERadarChartProps & ChartProps> = ({
  chartId,
  chartTitle,
  traces = [],
  testID,
  downloadPng = false,
  downloadCsv = false,
  plotRef,
  showlegend = true,
  csvCategory = "Category",
  chartPanel = false,
  setOnDownloadCSV,
  setOnDownloadPNG,
  setOnResetChart,
  layout = {},
  chartLegendRef,
  empty,
}) => {
  const extraLayoutConfig = {
    ...{ filename: chartTitle },
    ...defaultCORERadarChartLayout,
    ...layout,
  };
  const defaultLayout = JSON.parse(JSON.stringify(extraLayoutConfig));
  const tracesData: CategoryChart = setTracesData(traces);
  const tracesWithColour: CategoryChart = prepareChartColor(tracesData);
  const legends: Legend[] = legendsWithTagStyle(tracesWithColour);
  const showLegend = showlegend && legends && legends.length > 1;
  const data: Partial<ChartDataType>[] = tracesWithColour.map(
    ({ values, ...t }) => {
      return {
        type: "scatterpolar",
        fill: "toself",
        name: t.traceName,
        r: values.map((v) => v.value),
        theta: values.map((v) => v.category),
        line: {
          color: t[lineColor],
          dash: t["line.dash"],
          width: 2,
        },
        marker: {
          size: 7,
        },
        connectgaps: true,
        ...t,
      };
    }
  );

  useDeepCompareEffect(() => {
    const handleDownloadCSV = () => {
      if (!traces || traces.length < 1) {
        return;
      }

      const categorys = getCategories(traces);

      const csvData = categorys.map((category) => [
        category,
        ...traces.map(
          (t) => t.values.find((v) => v.category === category)?.value ?? null
        ),
      ]);

      const csvDataWithHeader = [
        [csvCategory, ...traces.map((t) => t.traceName)],
        ...csvData,
      ];

      downloadCSVFile(csvDataWithHeader, true, chartTitle);
    };

    const handleDownloadPNG = () => {
      if (!plotRef?.current) {
        return;
      }

      chartLegendRef?.current?.callDrawImage();
    };
    const handleResetChart = () => {
      if (!plotRef?.current) {
        return;
      }

      Plotly.relayout(plotRef.current.el, {
        polar: {
          angularaxis: {
            ...plotRef.current.props.layout.polar?.angularaxis,
            rotation: defaultLayout?.polar?.angularaxis?.rotation,
            autorange: true,
          },
          radialaxis: {
            ...plotRef.current.props.layout.polar?.radialaxis,
            angle: defaultLayout?.polar?.radialaxis?.angle,
            ...(defaultLayout?.polar?.radialaxis?.range
              ? {
                  range: defaultLayout?.polar?.radialaxis?.range,
                }
              : { autorange: true }),
          },
        },
      });
    };

    if (setOnDownloadCSV) setOnDownloadCSV(() => handleDownloadCSV);
    if (setOnDownloadPNG) setOnDownloadPNG(() => handleDownloadPNG);
    if (setOnResetChart) setOnResetChart(() => handleResetChart);
  }, [
    chartTitle,
    plotRef,
    setOnDownloadCSV,
    setOnDownloadPNG,
    setOnResetChart,
    traces,
    chartLegendRef,
    csvCategory,
    defaultLayout,
  ]);

  return (
    <COREChartLegend
      chartId={chartId}
      chartTitle={chartTitle}
      chartPanel={chartPanel}
      downloadCsv={downloadCsv}
      downloadPng={downloadPng}
      data={data}
      layout={layout}
      testID={testID}
      legends={legends}
      showlegend={showLegend}
      legendFilter={true}
      plotRef={plotRef}
      ref={chartLegendRef}
      empty={empty}
    />
  );
};

export const CORERadarChart = React.memo<
  React.ComponentType<
    CORERadarChartProps & ChartProps & { ref?: React.ForwardedRef<any> }
  >
>(
  RadarChart,
  (prevProps, nextProps) =>
    prevProps && equal(prevProps.traces, nextProps.traces)
);
