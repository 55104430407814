/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RpcAggregatesService {

    /**
     * @param args 
     * @param prefer Preference
     * @returns any OK
     * @throws ApiError
     */
    public static postRpcAggregates(
args: {
date_range: string;
by: string;
agg_function: string;
agg_period: string;
selected_item: string;
table_name: string;
column_name: string;
include_times_without_data?: boolean;
},
prefer?: 'params=single-object',
): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/rpc/aggregates`,
            headers: {
                'Prefer': prefer,
            },
            body: args,
        });
    }

}