import moment, { DurationInputArg2, Moment, unitOfTime } from "moment";
import { formatDate } from "./DateTime";
import { aggPeriod } from "../../modules/emissions/overviewComponents/EmissionsSnapshot";
import { TrackingLevel } from "../../modules/emissions/portfolioComponents/createAndEdit/portfolio_creation_helper";

export type OpenBounds = "[" | "(";
export type CloseBounds = "]" | ")";
export type RangeString =
  | `${OpenBounds}${string},${string}${CloseBounds}`
  | "empty";
export type Bounds = `${OpenBounds}${CloseBounds}`;
export type NonEmptyRange = {
  from: moment.Moment;
  to: moment.Moment;
  bounds: Bounds;
};
export type MaybeInfRange = {
  from?: moment.Moment;
  to?: moment.Moment;
  bounds: Bounds;
};
type EmptyRange = "empty";
export type Range = NonEmptyRange | MaybeInfRange | EmptyRange;
type Interval = moment.unitOfTime.DurationConstructor;

const empty: EmptyRange = "empty";
const rangeFormatter = "YYYY-MM-DD";

const nextInterval = (time: moment.Moment, interval: Interval) =>
  time?.clone().add(1, interval).startOf(interval);

export const convertRangeToNonEmptyRange = (range: Range): NonEmptyRange => {
  if (range === empty || !range.from || !range.to)
    throw new Error(`Range is empty`);
  return createRange(range.from, range.to, range.bounds);
};

export function chunkRange(range: Range, interval: Interval) {
  if (range === empty || !range.from || !range.to) return undefined;
  const { from, to, bounds } = range;
  if (from?.clone().add(1, interval).isAfter(to)) {
    // The range to chunk is smaller then the interval,
    // so we need to just return the provided range
    return [createRange(from, to, bounds)];
  }
  const first = createRange(
    from.clone(),
    nextInterval(from, interval),
    (bounds[0] + ")") as Bounds
  );
  const last = createRange(
    to?.clone().startOf(interval),
    to?.clone(),
    ("[" + bounds[1]) as Bounds
  );

  const middle: Range[] = [];
  for (
    let cur: NonEmptyRange = createRange(
      nextInterval(first.from?.clone(), interval),
      nextInterval(first.to?.clone(), interval)
    );
    isRangeBeforeRange(cur, last);
    cur = createRange(
      nextInterval(cur.from?.clone(), interval),
      nextInterval(cur.to?.clone(), interval)
    )
  ) {
    middle.push(cur);
  }
  const isEmptyRange = ({
    from,
    to,
  }: {
    from?: NonEmptyRange["from"];
    to?: NonEmptyRange["to"];
  }) => from?.isSame(to);
  return [first, ...middle, last].filter(
    (r) => r && r !== empty && !isEmptyRange(r)
  );
}

export const createRange = (
  from: NonEmptyRange["from"],
  to: NonEmptyRange["to"],
  bounds: NonEmptyRange["bounds"] = "[)"
) => ({
  from: from.clone(),
  to: to.clone(),
  bounds,
});

export const isRangeBeforeRange = (a: MaybeInfRange, b: MaybeInfRange) => {
  const { to: aTo, bounds: aBounds } = a;
  const { from: bFrom, bounds: bBounds } = b;
  const aToInclusive = aBounds[1] === "]";
  const bFromInclusive = bBounds[0] === "[";
  if (aToInclusive) {
    return bFromInclusive ? aTo?.isBefore(bFrom) : bFrom?.isAfter(aTo);
  }
  return bFromInclusive
    ? aTo?.isSameOrBefore(bFrom)
    : bFrom?.isSameOrAfter(aTo);
};

// formatRangeForServer
export const rangeFormatToServer = (range: Range): RangeString | undefined => {
  if (range === empty) {
    return undefined;
  }
  const { from, to, bounds } = range;
  const formattedFrom =
    from && from.isValid() ? from.format(rangeFormatter) : "";
  const formattedTo = to && to.isValid() ? to.format(rangeFormatter) : "";
  return `${bounds[0]}${formattedFrom},${formattedTo}${bounds[1]}` as RangeString;
};

export const rangeToPeriod = (range: Range): string => {
  if (range === empty) return "";
  const { from, to } = range;
  const fromdate = from?.clone();
  const todate = to?.clone();
  const months = fromdate?.diff(todate, "months");
  const fromDateIsStartOfMonth = fromdate?.day() === 1;
  const toDateIsEndOfMonth = todate
    ?.clone()
    .startOf("day")
    .isSame(todate.clone().endOf("month").startOf("day"));

  if (!(fromDateIsStartOfMonth && toDateIsEndOfMonth)) {
    return `${fromdate?.format(
      // "dd MM yyyy"
      "YY-MM"
    )} -  ${todate?.format(
      //"dd MM yyyy"
      "YY-MM"
    )}`;
  }
  if (months === 12) {
    if (fromdate.month() === 1) {
      return fromdate.year().toString();
    }
    return `FY ${fromdate.year()}`;
  }
  const fromDateMonth = fromdate.month();
  if (months === 3 && [1, 4, 7, 10].some((qtr) => qtr === fromDateMonth)) {
    // WHEN months = 3 AND extract(month from fromdate) IN (1,4,7,10) THEN
    /* only handle calendar quarters as quarter until later stage when customer may specify*/
    return `Q${fromdate})) || ' ' || extract(year from todate)::text`;
  }
  return ""; //todo

  /* some sort of other garbage so we have to do it as the full date */
  /*
    RETURN (SELECT CASE
    WHEN fromdate_is_startofmonth AND todate_is_endofmonth THEN
    CASE
        WHEN months = 12 THEN
            CASE
            WHEN extract(month from fromdate) = 1 THEN
                extract(year from fromdate)::text
            ELSE
                /* if 12 months and doesn't start in Jan, consider it a fin year
  'FY ' || date_part('year', todate)::text
  END
  WHEN months = 3 AND extract(month from fromdate) IN (1,4,7,10) THEN
  /* only handle calendar quarters as quarter until later stage when customer may specify
  format('Q%s', extract(quarter from fromdate)) || ' ' || extract(year from todate)::text
  WHEN date_trunc('month', fromdate) = date_trunc('month', todate) THEN
  /* full months but don't fit into other patterns
  and todate month is same as fromdate month
  format('%s', to_char(fromdate,'Mon yyyy'))
  ELSE
  /* and todate month is different from fromdate month
  format('%s – %s', to_char(fromdate,'Mon yyyy'), to_char(todate,'Mon yyyy'))
  END
  /* some sort of other garbage so we have to do it as the full date
  ELSE format('%s – %s', to_char(fromdate,'dd Mon yyyy'), to_char(todate,'dd Mon yyyy'))
  END);
  END;*/
};

export const strToRange = (str: string): NonEmptyRange | undefined => {
  if (str === empty) return undefined;
  if (!(str[0] === "[" || str[0] === "(")) {
    throw new Error(`Invalid close bounds for: ${str}`);
  }
  if (!(str[str.length - 1] === "]" || str[str.length - 1] === ")")) {
    throw new Error(`Invalid close bounds for: ${str}`);
  }
  const openBounds: OpenBounds = str[0] as OpenBounds;
  const closeBounds: CloseBounds = str[str.length - 1] as CloseBounds;
  const bounds: NonEmptyRange["bounds"] = (openBounds +
    closeBounds) as NonEmptyRange["bounds"];
  const [from, to] = str.substring(1, str.length - 1).split(",");

  return createRange(moment(from), moment(to), bounds);
};

export const convertRangeToClosedInterval = (value: Range): Range => {
  if (value === "empty" || value.bounds?.[1] === "]") return value;
  if (!value.bounds) {
    return {
      ...value,
      bounds: "[]",
    };
  }

  return {
    ...value,
    to: value.to?.clone().subtract(1, "days"),
    bounds: (value.bounds[0] + "]") as Bounds,
  };
};

export const formatRangeForUser = (
  range: NonEmptyRange,
  formatter: string = "DD-MMM-Y",
  connecter: string = "to"
): string => {
  const newRange = convertRangeToClosedInterval(range);

  if (newRange === "empty") return "-";

  return `${newRange?.from?.format(
    formatter
  )} ${connecter} ${newRange?.to?.format(formatter)}`;
};

export const rangeToDate = (dateRange: Range): { from: string; to: string } => {
  const { from: fromDate, to: toDate } = rangeToMomentDate(dateRange);
  return {
    from: fromDate
      ? formatDate(fromDate.local().toDate(), { dateStyle: "medium" })
      : "",
    to: toDate
      ? formatDate(toDate.local().toDate(), { dateStyle: "medium" })
      : "",
  };
};

export const createArrayOfRangeBetweenDates = (
  range: MaybeInfRange,
  unit: DurationInputArg2,
  startMonth: number | undefined
) => {
  const { from, to } = range;
  const now = from?.clone();
  const getDates: moment.Moment[] = [];

  while (
    now &&
    now.isBefore(to) &&
    (!startMonth || from?.isSame(now) || now.get("month") !== startMonth - 1)
  ) {
    now && getDates.push(now.clone());
    now?.add(1, unit);
  }

  return getDates.map((d) => {
    const from = d.clone();
    return createRange(from, d.add(1, unit));
  });
};

export const getCoveringRange = (
  ranges: (NonEmptyRange | undefined)[]
): MaybeInfRange => {
  let minFromDate: Moment | undefined = undefined; // Minimum Date
  let maxToDate: Moment | undefined = undefined; // Maximum Date
  ranges.forEach((range) => {
    if (range && range.from && moment(range.from).isValid()) {
      const from =
        range.bounds[0] === "["
          ? range.from
          : range.from.clone().add(1, "days");
      if (minFromDate === undefined || minFromDate.isAfter(from)) {
        minFromDate = from;
      }
    }
  });
  ranges.forEach((range) => {
    if (range && range.to && moment(range.to).isValid()) {
      const to =
        range.bounds[1] === "]"
          ? range.to
          : range.to.clone().subtract(1, "days");
      if (maxToDate === undefined || maxToDate.isBefore(to)) {
        maxToDate = to;
      }
    }
  });
  return {
    from: minFromDate,
    to: maxToDate,
    bounds: "[]",
  };
};

const hasTime = (from: Moment | undefined, to: Moment | undefined): boolean =>
  (from !== undefined &&
    from.isValid() &&
    !from.isSame(from.clone().startOf("day"))) ||
  (to !== undefined && to.isValid() && !to.isSame(to.clone().startOf("day")));

export const rangeToMomentDate = (
  dateRange: Range
): { from: Moment | undefined; to: Moment | undefined } => {
  if (dateRange === empty)
    return {
      from: undefined,
      to: undefined,
    };
  let fromDate: Moment | undefined;
  let toDate: Moment | undefined;
  const { to, from, bounds } = dateRange;

  if (hasTime(from, to)) {
    throw new Error("dateRange should not have time");
  }

  if (bounds[0] === "[") {
    fromDate = from?.isValid() ? from.clone() : undefined;
  } else {
    fromDate = from?.isValid() ? from.clone().add(1, "d") : undefined;
  }

  if (bounds[1] === "]") {
    toDate = to?.isValid() ? to.clone() : undefined;
  } else {
    toDate = to?.isValid() ? to.clone().subtract(1, "d") : undefined;
  }

  return {
    from: fromDate,
    to: toDate,
  };
};

export const doRangesOverlap = (
  range1?: NonEmptyRange,
  range2?: NonEmptyRange
) => {
  if (range1 === undefined || range2 === undefined) {
    return false;
  }
  const { from: from1, to: to1 } = rangeToMomentDate(range1);
  const { from: from2, to: to2 } = rangeToMomentDate(range2);
  return (
    (moment(from1).isSameOrBefore(moment(to2)) &&
      moment(to1).isSameOrAfter(moment(from2))) ||
    (moment(from2).isSameOrBefore(moment(to1)) &&
      moment(to2).isSameOrAfter(moment(from1)))
  );
};

export const displayRelativeRange = (
  range: NonEmptyRange,
  connecter: string = "-",
  periodLevel?: TrackingLevel
): string => {
  const { from: fromRelativeDate, to: toDateRelativeDate } = rangeToDate(range);
  const { from: fromDate, to: toDate } = rangeToMomentDate(range);
  if (!fromDate) return "";
  if (!toDate) {
    return `${fromRelativeDate} ${connecter} Ongoing`;
  }

  if (periodLevel) {
    const unit = aggPeriod(periodLevel) as unitOfTime.Diff;
    const isSinglePeriod = range.to.diff(range?.from, unit) <= 1;
    const fromDateFormatted =
      periodLevel === "1 mon"
        ? fromDate.format("MMM YYYY")
        : periodLevel === "3 mons"
        ? `Q${Math.floor(fromDate.month() / 3) + 1} ${fromDate.year()}`
        : fromDate.year();

    if (isSinglePeriod) return `${fromDateFormatted}`;

    const toDateFormatted =
      periodLevel === "1 mon"
        ? toDate.format("MMM YYYY")
        : periodLevel === "3 mons"
        ? `Q${Math.floor(toDate.month() / 3) + 1} ${toDate.year()}`
        : toDate.year();

    return `${fromDateFormatted} ${connecter} ${toDateFormatted}`;
  }

  return `${fromRelativeDate} ${connecter} ${toDateRelativeDate}`;
};
