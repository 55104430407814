import "./COREButton.less";
import React, {
  ButtonHTMLAttributes,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useState,
} from "react";
import { Typography } from "antd";
import { BROKER_HOTLINE, BROKER_HOTLINE_DISPLAY } from "../../shared/global";
import classNames from "classnames";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { TestID } from "../../shared/testids/testids";
import { CORETooltip, CORETooltipProps } from "../Overlay/CORETooltip";
import { NavLink } from "react-router-dom";
import { ColorHex } from "../Content/COREColour";

const { Link } = Typography;

export type ButtonType = "primary" | "default" | "text" | "link" | "action";
export type sizeType = "lg" | "md" | "sm";
export type ButtonBorderRadius = "full" | "sm";

type BaseProps = {
  color?: ColorHex;
  children?: ReactNode;
  size?: sizeType;
  loading?: boolean;
  disabled?: boolean;
  danger?: boolean;
  behavior?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  testID?: TestID;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  block?: boolean;
  href?: string;
  to?: string;
  target?: React.HTMLAttributeAnchorTarget;
  tabIndex?: number;
  borderRadius?: ButtonBorderRadius;
} & (
  | {
      displayTooltip: true;
      tooltipTitle: CORETooltipProps["message"];
      tooltipPosition?: CORETooltipProps["position"];
      tooltipWidth?: CORETooltipProps["width"];
    }
  | {
      displayTooltip?: false;
      tooltipTitle?: undefined;
      tooltipPosition?: undefined;
      tooltipWidth?: undefined;
    }
);

export type COREButtonProps = BaseProps &
  (
    | {
        type?: Exclude<ButtonType, "action">;
        icon?: ReactElement<any, any> | null;
      }
    | {
        type: Extract<ButtonType, "action">;
        icon: ReactElement<any, any>;
      }
  );

type AsyncFunctionProps<T> = {
  onAsyncClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<T>;
  onSuccess?: (result: T) => void;
  onError?: (error: Error) => void;
};

export type COREButtonWithAsyncFunctionProps<T> = COREButtonProps &
  AsyncFunctionProps<T>;

const LoadingIcon: React.FC = () => (
  <COREIcon icon={icon({ name: "spinner-third", style: "regular" })} spin />
);

const OpenNewTabIcon: React.FC = () => (
  <COREIcon
    icon={icon({ name: "arrow-up-right-from-square", style: "solid" })}
  />
);

const determineIconVisibility = (
  icon: COREButtonProps["icon"],
  loading: BaseProps["loading"]
) => {
  if (icon && !loading) return icon;
  return undefined;
};
const determineLoadingIcon = (
  loading: BaseProps["loading"],
  asyncLoading: boolean
) => {
  if (loading || asyncLoading) return <LoadingIcon />;
  return undefined;
};

const handleOnAsyncClick = <T,>(
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  setAsyncLoading: React.Dispatch<React.SetStateAction<boolean>>,
  onAsyncClick: AsyncFunctionProps<T>["onAsyncClick"],
  onSuccess: AsyncFunctionProps<T>["onSuccess"],
  onError: AsyncFunctionProps<T>["onError"]
) => {
  setAsyncLoading(true);
  return (
    onAsyncClick &&
    onAsyncClick(event)
      .then((res) => {
        setAsyncLoading(false);
        onSuccess && onSuccess(res);
      })
      .catch((error) => {
        setAsyncLoading(false);
        onError && onError(error);
      })
  );
};

export const COREButton = <T,>({
  color,
  children,
  type = "default",
  loading = false,
  disabled = false,
  icon: i = null,
  danger = false,
  size,
  behavior = "button",
  testID,
  onClick,
  block = false,
  href,
  to,
  target,
  displayTooltip,
  tooltipTitle,
  tooltipPosition,
  tooltipWidth,
  tabIndex,
  borderRadius = "full",
  onAsyncClick,
  onSuccess,
  onError,
}: COREButtonWithAsyncFunctionProps<T>) => {
  const [asyncLoading, setAsyncLoading] = useState<boolean>(false);
  const icon = i ?? (target === "_blank" ? <OpenNewTabIcon /> : null);
  const classNamesString = classNames([
    "core-btn",
    {
      [`${type}`]: type,
      loading: loading || asyncLoading,
      "icon-only": (icon || loading || asyncLoading) && !children,
      danger: danger,
      [size as string]: size,
      block: block,
      "border-radius-sm": borderRadius === "sm",
    },
  ]);

  const baseButton = (
    <button
      {...(color && { style: { color: color } })}
      type={behavior}
      className={classNamesString}
      disabled={disabled}
      data-testid={testID}
      onClick={(e) => {
        if (onClick && !loading) onClick(e);
        if (onAsyncClick && !asyncLoading)
          handleOnAsyncClick<T>(
            e,
            setAsyncLoading,
            onAsyncClick,
            onSuccess,
            onError
          );
      }}
      tabIndex={tabIndex}
    >
      {asyncLoading
        ? determineIconVisibility(icon, asyncLoading)
        : determineIconVisibility(icon, loading)}
      {determineLoadingIcon(loading, asyncLoading)}
      {children && <span>{children}</span>}
    </button>
  );

  const button = to ? (
    <NavLink to={to} target={target}>
      {baseButton}
    </NavLink>
  ) : href ? (
    <Link href={href} target={target}>
      {baseButton}
    </Link>
  ) : (
    <>{baseButton}</>
  );

  if (tooltipTitle && displayTooltip && !disabled) {
    return (
      <CORETooltip
        noFitContent
        width={tooltipWidth}
        title={""}
        message={tooltipTitle}
        position={tooltipPosition}
        testID={(testID + "-tooltip") as TestID}
      >
        {button}
      </CORETooltip>
    );
  }
  return button;
};

export const BrokerCallButton: React.FC = () => (
  <COREButton
    href={`tel:${BROKER_HOTLINE}`}
    size={"md"}
    type={"primary"}
    icon={
      <COREIcon
        icon={icon({
          name: "phone",
          style: "regular",
        })}
      />
    }
  >
    {BROKER_HOTLINE_DISPLAY}
  </COREButton>
);
