import { UserAccessibleSection } from "../../openapi-typescript/common/user_accessible_section";
import { keysToCamelCase } from "../global";
import { useUserRole } from "../state/user";
import { useUpdatables } from "./useUpdatable";

type UseUserAccessibleSectionsProps = {
  pathName?: string;
};

const findByPath = (
  userAccessibleSections: UserAccessibleSection[],
  path: UseUserAccessibleSectionsProps["pathName"]
): UserAccessibleSection[] => {
  for (let item of userAccessibleSections) {
    if (item.callToActionUrl === path) {
      return [item];
    }

    if (item.children && item.children.length > 0) {
      const found = findByPath(item.children, path);
      if (found.length !== 0) {
        return found;
      }
    }
  }

  return [];
};

export const useUserAccessibleSections = (
  props?: UseUserAccessibleSectionsProps
) => {
  const isPendingRole = useUserRole() === "pending";
  const { pathName } = props ?? {};
  const { updatable, ...other } = useUpdatables<UserAccessibleSection>({
    get: ["userAccessibleSections", {}, isPendingRole],
    transformToRichTypes: (record) => keysToCamelCase(record),
  });

  let data = updatable.map(({ data }) => data) as UserAccessibleSection[];
  data = filterPreviewPages(data);
  if (pathName) {
    data = findByPath(data, pathName);
  }

  return {
    userAccessibleSections: data,
    ...other,
  };
};

export const filterPreviewPages = (
  data: UserAccessibleSection[]
): UserAccessibleSection[] => {
  return data
    ?.filter((item) => {
      if (item?.featureFlag?.endsWith("-preview")) {
        const mainValue = item.featureFlag.replace("-preview", "");
        return !data.some((item) => item.featureFlag === mainValue);
      }
      return true;
    })
    .map((item) => {
      if (item?.featureFlag?.endsWith("-preview")) {
        const mainValue = item.title.replace(" (Preview)", "");
        return {
          ...item,
          title: mainValue,
          children: filterPreviewPages(item.children),
        };
      }
      return { ...item, children: filterPreviewPages(item.children) };
    });
};
