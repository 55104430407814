import React from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { icon as getIcon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { COREIcon, COREIconProps } from "../Content/COREIcon";

type MenuIconProps = {
  size?: COREIconProps["size"];
  regularIcon: IconDefinition;
  solidIcon: IconDefinition;
};
const MenuIcon: React.FC<MenuIconProps> = ({
  regularIcon,
  solidIcon,
  size,
}) => (
  <>
    <div className={"regular-icon"}>
      <COREIcon icon={regularIcon} size={size} />
    </div>
    <div className={"solid-icon"}>
      <COREIcon icon={solidIcon} size={size} />
    </div>
  </>
);

export const menuIcons = (iconName: string, size?: COREIconProps["size"]) =>
  ({
    home: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "home", style: "regular" })}
        solidIcon={getIcon({ name: "home", style: "solid" })}
      />
    ),
    cog: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "cog", style: "regular" })}
        solidIcon={getIcon({ name: "cog", style: "solid" })}
      />
    ),
    building: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "building", style: "regular" })}
        solidIcon={getIcon({ name: "building", style: "solid" })}
      />
    ),
    "square-list": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "square-list", style: "regular" })}
        solidIcon={getIcon({ name: "square-list", style: "solid" })}
      />
    ),
    receipt: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "receipt", style: "regular" })}
        solidIcon={getIcon({ name: "receipt", style: "solid" })}
      />
    ),
    user: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "user", style: "regular" })}
        solidIcon={getIcon({ name: "user", style: "solid" })}
      />
    ),
    "user-edit": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "user-edit", style: "regular" })}
        solidIcon={getIcon({ name: "user-edit", style: "solid" })}
      />
    ),
    cogs: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "cogs", style: "regular" })}
        solidIcon={getIcon({ name: "cogs", style: "solid" })}
      />
    ),
    "Circle-question": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "circle-question", style: "regular" })}
        solidIcon={getIcon({ name: "circle-question", style: "solid" })}
      />
    ),
    "chart-mixed": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "chart-mixed", style: "regular" })}
        solidIcon={getIcon({ name: "chart-mixed", style: "solid" })}
      />
    ),
    database: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "database", style: "regular" })}
        solidIcon={getIcon({ name: "database", style: "solid" })}
      />
    ),
    "screen-users": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "screen-users", style: "regular" })}
        solidIcon={getIcon({ name: "screen-users", style: "solid" })}
      />
    ),
    code: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "code", style: "regular" })}
        solidIcon={getIcon({ name: "code", style: "solid" })}
      />
    ),
    buildings: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "buildings", style: "regular" })}
        solidIcon={getIcon({ name: "buildings", style: "solid" })}
      />
    ),
    "folder-grid": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "folder-grid", style: "regular" })}
        solidIcon={getIcon({ name: "folder-grid", style: "solid" })}
      />
    ),
    newspaper: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "newspaper", style: "regular" })}
        solidIcon={getIcon({ name: "newspaper", style: "solid" })}
      />
    ),
    books: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "books", style: "regular" })}
        solidIcon={getIcon({ name: "books", style: "solid" })}
      />
    ),
    "box-circle-check": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "box-circle-check", style: "regular" })}
        solidIcon={getIcon({ name: "box-circle-check", style: "solid" })}
      />
    ),
    users: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "users", style: "regular" })}
        solidIcon={getIcon({ name: "users", style: "solid" })}
      />
    ),
    "money-bill-trend-up": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "money-bill-trend-up", style: "regular" })}
        solidIcon={getIcon({ name: "money-bill-trend-up", style: "solid" })}
      />
    ),
    "money-check-dollar-pen": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({
          name: "money-check-dollar-pen",
          style: "regular",
        })}
        solidIcon={getIcon({ name: "money-check-dollar-pen", style: "solid" })}
      />
    ),
    "cloud-fog": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "cloud-fog", style: "regular" })}
        solidIcon={getIcon({ name: "cloud-fog", style: "solid" })}
      />
    ),
    eye: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "eye", style: "regular" })}
        solidIcon={getIcon({ name: "eye", style: "solid" })}
      />
    ),
    "list-timeline": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "list-timeline", style: "regular" })}
        solidIcon={getIcon({ name: "list-timeline", style: "solid" })}
      />
    ),
    "money-check-dollar": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "money-check-dollar", style: "regular" })}
        solidIcon={getIcon({ name: "money-check-dollar", style: "solid" })}
      />
    ),
    "location-dot": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "location-dot", style: "regular" })}
        solidIcon={getIcon({ name: "location-dot", style: "solid" })}
      />
    ),
    "file-certificate": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "file-certificate", style: "regular" })}
        solidIcon={getIcon({ name: "file-certificate", style: "solid" })}
      />
    ),
    bolt: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "bolt", style: "regular" })}
        solidIcon={getIcon({ name: "bolt", style: "solid" })}
      />
    ),
    "battery-bolt": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "battery-bolt", style: "regular" })}
        solidIcon={getIcon({ name: "battery-bolt", style: "solid" })}
      />
    ),
    "wind-turbine": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "wind-turbine", style: "regular" })}
        solidIcon={getIcon({ name: "wind-turbine", style: "solid" })}
      />
    ),
    seedling: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "seedling", style: "regular" })}
        solidIcon={getIcon({ name: "seedling", style: "solid" })}
      />
    ),
    certificate: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "certificate", style: "regular" })}
        solidIcon={getIcon({ name: "certificate", style: "solid" })}
      />
    ),
    tag: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "tag", style: "regular" })}
        solidIcon={getIcon({ name: "tag", style: "solid" })}
      />
    ),
    handshake: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "handshake", style: "regular" })}
        solidIcon={getIcon({ name: "handshake", style: "solid" })}
      />
    ),
    "user-group-simple": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "user-group-simple", style: "regular" })}
        solidIcon={getIcon({ name: "user-group-simple", style: "solid" })}
      />
    ),
    "user-tie": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "user-tie", style: "regular" })}
        solidIcon={getIcon({ name: "user-tie", style: "solid" })}
      />
    ),
    "gauge-high": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "gauge-high", style: "regular" })}
        solidIcon={getIcon({ name: "gauge-high", style: "solid" })}
      />
    ),
    "square-poll-vertical": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({
          name: "square-poll-vertical",
          style: "regular",
        })}
        solidIcon={getIcon({ name: "square-poll-vertical", style: "solid" })}
      />
    ),
    "book-open-reader": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "book-open-reader", style: "regular" })}
        solidIcon={getIcon({ name: "book-open-reader", style: "solid" })}
      />
    ),
    folders: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "folders", style: "regular" })}
        solidIcon={getIcon({ name: "folders", style: "solid" })}
      />
    ),
    "chart-line-up-down": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "chart-line-up-down", style: "regular" })}
        solidIcon={getIcon({ name: "chart-line-up-down", style: "solid" })}
      />
    ),
    "code-fork": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "code-fork", style: "regular" })}
        solidIcon={getIcon({ name: "code-fork", style: "solid" })}
      />
    ),
    "file-lines": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "file-lines", style: "regular" })}
        solidIcon={getIcon({ name: "file-lines", style: "solid" })}
      />
    ),
    megaphone: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "megaphone", style: "regular" })}
        solidIcon={getIcon({ name: "megaphone", style: "solid" })}
      />
    ),
    "chart-mixed-up-circle-dollar": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({
          name: "chart-mixed-up-circle-dollar",
          style: "regular",
        })}
        solidIcon={getIcon({
          name: "chart-mixed-up-circle-dollar",
          style: "solid",
        })}
      />
    ),
    "earth-oceania": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "earth-oceania", style: "regular" })}
        solidIcon={getIcon({ name: "earth-oceania", style: "solid" })}
      />
    ),
    "chart-line": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "chart-line", style: "regular" })}
        solidIcon={getIcon({ name: "chart-line", style: "solid" })}
      />
    ),
    "cart-flatbed": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "cart-flatbed", style: "regular" })}
        solidIcon={getIcon({ name: "cart-flatbed", style: "solid" })}
      />
    ),
    tools: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "tools", style: "regular" })}
        solidIcon={getIcon({ name: "tools", style: "solid" })}
      />
    ),
    "message-exclamation": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "message-exclamation", style: "regular" })}
        solidIcon={getIcon({ name: "message-exclamation", style: "solid" })}
      />
    ),
    globe: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "globe", style: "regular" })}
        solidIcon={getIcon({ name: "globe", style: "solid" })}
      />
    ),
    "map-location-dot": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "map-location-dot", style: "regular" })}
        solidIcon={getIcon({ name: "map-location-dot", style: "solid" })}
      />
    ),
    createDashboard: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "circle-plus", style: "regular" })}
        solidIcon={getIcon({ name: "circle-plus", style: "solid" })}
      />
    ),
    lock: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "lock", style: "regular" })}
        solidIcon={getIcon({ name: "lock", style: "solid" })}
      />
    ),
    toolbox: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "toolbox", style: "regular" })}
        solidIcon={getIcon({ name: "toolbox", style: "solid" })}
      />
    ),
    list: (
      <MenuIcon
        size={size}
        regularIcon={getIcon({ name: "list", style: "regular" })}
        solidIcon={getIcon({ name: "list", style: "solid" })}
      />
    ),
    "magnifying-glass-chart": (
      <MenuIcon
        size={size}
        regularIcon={getIcon({
          name: "magnifying-glass-chart",
          style: "regular",
        })}
        solidIcon={getIcon({ name: "magnifying-glass-chart", style: "solid" })}
      />
    ),
  }[iconName]);
