import React, { ReactNode, useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import "./CORETag.less";
import { Tag, TagProps } from "antd";
import { COREBody } from "../Typography/COREBody";
import { ColorHex, ColorName } from "./COREColour";
import { icon as generateIcon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { COREIcon } from "./COREIcon";
import { CORETooltip } from "../Overlay/CORETooltip";
import { generateTestId, TestID } from "../../shared/testids/testids";

export type CORETagColour = ColorName;

export type CORETagStyle = "outline" | "solid" | "dashed";

export type CORETagStatus =
  | "default"
  | "viewOnly"
  | "error"
  | "warning"
  | "processing"
  | "pending"
  | "success";

type CORETagStatusColor =
  | "default"
  | "red100"
  | "orange100"
  | "blue100"
  | "lime100";

type CommonProps = {
  testID?: TestID;
  maxWidth?: number;
} & Omit<TagProps, "style">;

type BasicProps = {
  type: "basic";
  closable?: boolean;
  label: ReactNode;
  checked?: boolean;
  colour?: never;
  tagStyle?: never;
  status?: never;
  icon?: never;
};

export type ColourfulProps = {
  type: "colourful";
  closable?: false;
  onClose?: never;
  label: ReactNode;
  colour: CORETagColour;
  tagStyle: CORETagStyle;
  checked?: never;
  status?: never;
  icon?: never;
};

export type ColourfulCloseableProps = {
  type: "colourful";
  closable: boolean;
  label: ReactNode;
  colour: CORETagColour;
  tagStyle: CORETagStyle;
  checked?: boolean;
  status?: never;
  icon?: never;
};

type StatusProps = {
  type: "status";
  label: ReactNode;
  status: CORETagStatus;
  icon?: boolean;
  closable?: never;
  onClose?: never;
  checked?: never;
  colour?: never;
  tagStyle?: never;
};

export type IconProps = {
  type: "icon";
  label?: ReactNode;
  icon: React.ReactElement;
  closable?: never;
  onClose?: never;
  checked?: never;
  colour: CORETagColour;
  tagStyle?: never;
  status?: never;
};

type UNSdGoalProps = {
  type: "unSdGoal";
  closable?: boolean;
  label: ReactNode;
  colour: ColorHex;
  tagStyle: CORETagStyle;
  checked?: never;
  status?: never;
  icon?: never;
};

export type CORETagProps = CommonProps &
  (
    | BasicProps
    | ColourfulProps
    | ColourfulCloseableProps
    | StatusProps
    | IconProps
    | UNSdGoalProps
  );

const statusColor: Record<CORETagStatus, CORETagStatusColor> = {
  default: "default",
  viewOnly: "default",
  error: "red100",
  warning: "orange100",
  processing: "blue100",
  pending: "orange100",
  success: "lime100",
};

const statusIcon: Record<CORETagStatus, ReactNode> = {
  default: (
    <COREIcon
      icon={generateIcon({
        name: "exclamation-circle",
        style: "solid",
      })}
    />
  ),
  viewOnly: (
    <COREIcon
      icon={generateIcon({
        name: "eye",
        style: "solid",
      })}
    />
  ),
  error: (
    <COREIcon
      icon={generateIcon({
        name: "circle-xmark",
        style: "solid",
      })}
    />
  ),
  warning: (
    <COREIcon
      icon={generateIcon({
        name: "exclamation-circle",
        style: "solid",
      })}
    />
  ),
  processing: (
    <COREIcon
      icon={generateIcon({
        name: "play",
        style: "solid",
      })}
    />
  ),
  pending: (
    <COREIcon
      icon={generateIcon({
        name: "pause",
        style: "solid",
      })}
    />
  ),
  success: (
    <COREIcon
      icon={generateIcon({
        name: "circle-check",
        style: "solid",
      })}
    />
  ),
};

const customStyleTag = (
  type: CORETagProps["type"],
  colour?: CORETagColour | ColorHex
): React.CSSProperties | undefined => {
  if (type !== "unSdGoal") return;
  if (colour === undefined) return;

  return {
    borderColor: colour as string,
    backgroundColor: colour as string,
    color: "#ffffff",
  };
};

const TagTextTruncate: React.FC<
  Pick<CORETagProps, "maxWidth" | "testID" | "label"> & {
    tagRef: React.RefObject<HTMLDivElement>;
  }
> = ({ maxWidth, tagRef, testID, label, children }) => {
  const [isOverflowed, setIsOverflow] = useState<boolean>(false);

  useLayoutEffect(() => {
    const renderedWidth = tagRef.current?.offsetWidth ?? 0;
    setIsOverflow(maxWidth ? renderedWidth === maxWidth : false);
  }, [maxWidth, tagRef, label]);

  return (
    <CORETooltip
      title={""}
      width={"auto"}
      position={"top"}
      message={label}
      testID={
        testID
          ? `${testID}-tooltips`
          : generateTestId("storybook", "tag-tooltips")
      }
      hidden={!isOverflowed}
    >
      {children}
    </CORETooltip>
  );
};

export const CORETag: React.FC<CORETagProps> = ({
  type,
  colour,
  tagStyle,
  status,
  icon,
  label,
  closable = false,
  onClose,
  checked = true,
  testID,
  maxWidth,
  className,
  ...props
}) => {
  const tagRef = useRef<HTMLDivElement>(null);
  const tagIcon = icon
    ? icon
    : type === "status" && status && statusIcon[status];

  const statusClass =
    type === "status" && status ? statusColor[status] : "default";

  const tagColorStyle = customStyleTag(type, colour);
  const TagBody = () => (
    <Tag
      ref={tagRef}
      data-testid={testID}
      style={{
        ...tagColorStyle,
        ...(maxWidth && { maxWidth: maxWidth }),
      }}
      closeIcon={
        <COREIcon icon={generateIcon({ name: "xmark", style: "solid" })} />
      }
      {...(closable ? { closable, onClose } : {})}
      className={classNames({ "unSdGoal-tag": type === "unSdGoal" }, className)}
      {...props}
    >
      {tagIcon}
      {label && (
        <COREBody
          type="p4"
          strong
          {...(maxWidth && {
            className: "truncate-tag-label",
          })}
        >
          {label}
        </COREBody>
      )}
    </Tag>
  );

  return (
    <div
      className={classNames(
        "core-tag-container",
        { [`${colour}`]: colour },
        { default: !colour },
        { [`${tagStyle}`]: !!tagStyle },
        { [statusClass]: type === "status" },
        {
          uncheck: !checked,
          outline: type === "status",
          nohover: type === "status" || type === "icon",
          solid: type === "icon",
          "only-icon": type === "icon" && !label,
        }
      )}
    >
      {maxWidth ? (
        <TagTextTruncate
          maxWidth={maxWidth}
          tagRef={tagRef}
          testID={testID}
          label={label}
        >
          <TagBody />
        </TagTextTruncate>
      ) : (
        <TagBody />
      )}
    </div>
  );
};
