import "./COREMenu.less";
import React, { cloneElement } from "react";
import { Menu, MenuProps } from "antd";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { EllipsisOutlined, CaretRightOutlined } from "@ant-design/icons";
import classNames from "classnames";

interface COREMenuProps {
  testID: TestID;
  className?: string;
  hideHorizontalBorder?: boolean;
  overflowedIndicator?: React.ReactElement;
}

export const MenuModeContext = React.createContext<string | undefined>(
  undefined
);

export const COREMenu: React.FC<COREMenuProps & MenuProps> = ({
  testID,
  mode = "vertical",
  className,
  hideHorizontalBorder = false,
  overflowedIndicator,
  ...props
}) => {
  const overflowedElement = !overflowedIndicator ? (
    <EllipsisOutlined className={"overflow-icon"} />
  ) : (
    cloneElement(overflowedIndicator, {
      className: "overflow-icon",
    })
  );

  return (
    <TestIDWrapper testID={testID} className={"core-menu-container"}>
      <MenuModeContext.Provider value={mode}>
        <Menu
          {...props}
          mode={mode}
          className={classNames(className, "core-menu", {
            "hide-menu-horizontal-border": hideHorizontalBorder,
          })}
          selectable={false}
          expandIcon={<CaretRightOutlined className={"expand-icon"} />}
          overflowedIndicator={overflowedElement}
        />
      </MenuModeContext.Provider>
    </TestIDWrapper>
  );
};
