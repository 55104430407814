import { useUserConfigs } from "./useUserConfig";
import { releaseNoteData } from "../../releaseNote";

type Config = {
  lastSeenReleaseVersion?: string;
};

export type ReleaseNote = {
  version: string;
  date: string;
  changes: string[];
};

export type UseUserReleaseNoteResponse = {
  sync?: boolean;
  loading?: boolean;
  error: Error | false;
  config: Config;
  add: (config: Config) => Promise<Config | undefined>;
  updateConfig: (config: Config) => Promise<Config | undefined>;
  releaseNoteData: ReleaseNote[];
  updateLastSeenReleaseToLatestVersion: () => void;
};

export const useUserReleaseNote = (): UseUserReleaseNoteResponse => {
  const { sync, loading, error, configs, add } =
    useUserConfigs<Config>("release");
  const {
    data: { config },
    updateConfig,
  } =
    configs.length === 0
      ? {
          data: {
            config: { lastSeenReleaseVersion: undefined },
          },
          updateConfig: Promise.resolve,
        }
      : configs[0];

  const { lastSeenReleaseVersion } = config;
  const updateLastSeenReleaseToLatestVersion = () => {
    if (releaseNoteData.length > 0) {
      if (lastSeenReleaseVersion) {
        updateConfig({
          lastSeenReleaseVersion: releaseNoteData[0].version,
        });
      } else {
        add({
          lastSeenReleaseVersion: releaseNoteData[0].version,
        });
      }
    }
  };

  const displayReleaseNoteData =
    releaseNoteData &&
    releaseNoteData.length > 0 &&
    releaseNoteData[0].version === lastSeenReleaseVersion
      ? []
      : releaseNoteData;
  return {
    sync,
    loading,
    error,
    config,
    add,
    updateConfig,
    releaseNoteData: displayReleaseNoteData,
    updateLastSeenReleaseToLatestVersion,
  };
};
