import React, { RefObject, Suspense, useEffect, useState } from "react";
import { CORELoading } from "../Feedback/CORELoading";
import "./COREChart.less";
import ImgCOREMarketsLogoSVG from "../../assets/img/COREMarketsLogo.svg";
import { Alert, message, Spin } from "antd";
import moment from "moment";
import Plotly, {
  Config,
  Icon,
  Layout,
  LayoutAxis,
  ModeBarButton,
  ModeBarDefaultButtons,
  PlotData,
} from "plotly.js/dist/plotly-myPlotly.js";
import { TestID } from "../../shared/testids/testids";
import { ConfigYAxisValue } from "../../modules/chartBuilder/AggregatesChartV2";
import { ColorHex, grey20, grey60 } from "../Content/COREColour";
import classNames from "classnames";
import { toCanvas } from "html-to-image";
import { drawImageIntoCanvas } from "./COREChartLegend";
import { PlotRefType } from "./ChartHOC";
import { PlotParams } from "react-plotly.js";
import equal from "fast-deep-equal";
import { saveAs } from "file-saver";
import { stringify } from "csv-stringify/sync";
import createPlotlyComponent from "react-plotly.js/factory";

export const Plot = createPlotlyComponent(Plotly);

const scatterGLThreshold = 10000;

export const MemoizedPlot = React.memo<
  React.ComponentType<PlotParams & { ref?: React.ForwardedRef<any> }>
>(
  Plot,
  (prevProps, nextProps) =>
    prevProps &&
    equal(prevProps.data, nextProps.data) &&
    equal(prevProps.layout, nextProps.layout)
);

type MarginObject = {
  l?: number;
  t?: number;
  r?: number;
  b?: number;
};

type RenderPlotlyProps = {
  scatterGL: boolean;
  plotRef: React.ForwardedRef<Plotly.PlotlyHTMLElement>;
  data: ChartDataType[];
  layout: Partial<Layout>;
  customModebarButtons?: Array<ModeBarDefaultButtons[] | ModeBarButton[]>;
  hoverColor?: string;
  chartId: PlotParams["divId"];
  showXGrid: boolean;
  showYGrid: boolean;
  isWidget?: boolean;
  customLayout?: boolean;
};

export type ChartDataType = Pick<
  PlotData,
  | "name"
  | "x"
  | "type"
  | "line"
  | "marker"
  | "yaxis"
  | "showlegend"
  | "hoverlabel"
  | "hovertemplate"
  | "hoverinfo"
  | "textfont"
  | "ids"
  | "labels"
  | "parents"
  | "values"
  | "opacity"
  | "r"
> & {
  close?: number | null[];
  high?: number | null[];
  low?: number | null[];
  open?: number | null[];
  insidetextorientation?: "auto" | "horizontal" | "radial" | "tangential";
  leaf?: {
    opacity: number;
  };
  sort?: boolean;
  branchvalues?: "total" | "remainder";
} & (
    | {
        mode: Exclude<PlotData["mode"], "markers">;
        y?: number[];
      }
    | {
        mode: "markers";
        y?: number[] | string[];
      }
  );

class CSV {
  xAxisTitle: string;
  chartType: string;
  rows: any;
  serieses: Set<string>;
  xs: Set<number>;
  charts: Set<string>;
  xAxisTitleType: string;

  constructor(xAxisTitle, chartType, xAxisTitleType) {
    this.xAxisTitle = xAxisTitle;
    this.xAxisTitleType = xAxisTitleType;
    this.chartType = chartType;
    this.rows = {};
    this.serieses = new Set();
    this.xs = new Set();
    this.charts = new Set(); // will hold the list of charts, eg "Jan", "Feb", etc, "Mon" "Tue"
  }

  addCell(x, y, series, chart = "default") {
    this.serieses.add(series);
    this.charts.add(chart);
    this.xs.add(x);
    if (!this.rows.hasOwnProperty(chart)) {
      this.rows[chart] = {};
    }
    if (!this.rows[chart].hasOwnProperty(x)) {
      this.rows[chart][x] = { x };
    }
    this.rows[chart][x][series] = y?.toString();
  }

  getSeriesCheck(chart, xValue) {
    const serieses = this.getSerieses();
    return serieses.map((s) => {
      if (!this.rows[chart].hasOwnProperty(xValue)) return "";
      if (!this.rows[chart][xValue].hasOwnProperty(s)) return "";
      return this.rows[chart][xValue][s] ?? "";
    });
  }

  // Generator<number, string, boolean>
  *getRows() {
    const charts = this.getCharts();
    const xs = this.getXs();
    switch (this.chartType) {
      case "week":
      case "month":
        for (const chart of charts) {
          for (const x of xs) {
            const formattedX = this.formatTime(x, formatAsTime(x));
            yield [chart, formattedX, ...this.getSeriesCheck(chart, x)];
          }
        }
        break;
      default:
        for (const chart of charts) {
          for (const x of xs) {
            const formattedX = this.formatTime(
              x,
              this.chartType === "day"
                ? formatAsTime(x)
                : moment(x).format("DD/MM/YYYY HH:mm:ss")
            );
            yield [formattedX, ...this.getSeriesCheck(chart, x)];
          }
        }
        break;
    }
  }

  formatTime(x: number, formatter: string) {
    return this.xAxisTitleType === "category" ? x : formatter;
  }

  getSerieses() {
    return Array.from(this.serieses);
  }
  getCharts() {
    return Array.from(this.charts);
  }
  getXs() {
    return Array.from(this.xs).sort((front, next) => {
      // if (isFinite(parseInt(front))) return parseInt(front) - parseInt(next);
      if (isFinite(front)) return front - next;
      return new Date(front).valueOf() - new Date(next).valueOf();
    });
  }
  getTitles() {
    switch (this.chartType) {
      case "day":
        return ["Time", ...this.getSerieses()];
      case "week":
      case "month":
        return ["", "Time", ...this.getSerieses()];
      default:
        return [this.xAxisTitle, ...this.getSerieses()];
    }
  }

  getCSVArrayOfArrays() {
    return [this.getTitles(), ...this.getRows()];
  }
}

export const formatAsTime = (x): string => {
  const mins = x % 60;
  const hrs = Math.floor(x / 60);
  return `${hrs === 0 ? "00" : hrs}:${mins === 0 ? "00" : mins}`;
};

export const formatChartDataToCsv = (chartData) => {
  const {
    data,
    layout: {
      chartType,
      barmode,
      isWaterFallChart,
      xaxis: {
        title: { text: xAxisText },
        type: xAxisTitleType,
      },
    },
  } = chartData;

  if (barmode === "stack" && isWaterFallChart) {
    const labelNames = data[0].x;
    let csvData = [labelNames];
    let rowData: number[] = [];
    data.forEach((d, index) => {
      if (index >= 1) {
        rowData.push(d.y[index - 1]);
      }
    });
    csvData.push(rowData);
    return csvData;
  }

  const xAxisTitle = xAxisTitleType === "category" ? "Period " : xAxisText;
  const csv = new CSV(xAxisTitle, chartType, xAxisTitleType);

  data.forEach((d) => {
    d.x.forEach((x, i) => {
      csv.addCell(x, d.y[i], d.name, d.chart);
    });
  });

  return csv.getCSVArrayOfArrays();
};

export const downloadFileName = (filename: string) => {
  return filename.replace(/(<([^>]+)>)/gi, "");
};

export const downloadCSVFile = (
  chartData,
  isFormattedData = false,
  fileName = ""
) => {
  const formattedData: string[][] = isFormattedData
    ? chartData
    : formatChartDataToCsv(chartData);
  if (!formattedData) {
    return message.error("Something went wrong, please try again.");
  }
  const chartFileName = isFormattedData ? fileName : chartData.layout.filename;
  const output = stringify(formattedData);
  const csvString = output.replace(/\r?\n$/, "");
  // Byte Order Mark (BOM) for Unicode-encoded files.
  const blob = new Blob(["\uFEFF" + csvString], {
    type: "text/csv;charset=utf-8",
  });
  saveAs(blob, `${chartFileName ?? "chart_export"}.csv`);
};

const getHoverColorChart = (chartData, pointIndex, hoverColor) => {
  return chartData.map((x) =>
    x.type === "bar"
      ? {
          ...x,
          marker: {
            color: x.marker.color.map((d, i) =>
              i === pointIndex ? hoverColor : d
            ),
          },
        }
      : x
  );
};

const axisStyleConfig = {
  showline: true,
  zeroline: false,
  gridwidth: 1,
  linecolor: grey60,
  linewidth: 1,
  mirror: true,
};

const axisPositionConfig = {
  rangemode: "normal",
  automargin: true,
};

const setupAxisConfiguration = (
  axis: Partial<LayoutAxis>,
  showGrid: boolean,
  gridColor: string,
  customAxisPosition: boolean
) => {
  const standoff = 8;
  const title =
    typeof axis.title === "string"
      ? {
          text: axis.title,
          standoff,
        }
      : {
          ...axis.title,
          standoff,
        };
  const resultConfig = {
    ...axis,
    ...axisStyleConfig,
    gridcolor: gridColor,
    title,
    ...(showGrid ? { showgrid: true } : { showgrid: false }),
    ...(!customAxisPosition && axisPositionConfig),
  } as Partial<LayoutAxis>;
  if (!customAxisPosition) {
    delete resultConfig.tickformat;
    delete resultConfig.domain;
    delete resultConfig.position;
  }
  return resultConfig;
};

const RenderPlotly = ({
  showXGrid = false,
  showYGrid = true,
  isWidget = false,
  layout,
  customLayout = false,
  ...props
}: RenderPlotlyProps) => {
  const [chartData, setChartData] = useState<ChartDataType[]>(props.data);
  const [oriChartData, setOriChartData] = useState<ChartDataType[]>(props.data);

  useEffect(() => {
    setChartData(props.data);
    setOriChartData(props.data);
  }, [props.data]);
  const hoverColor: string | undefined = props.hoverColor || undefined; // props.hoverColor || false;
  const removeButtons: ModeBarDefaultButtons[] = [
    "autoScale2d",
    "toImage",
    "zoom2d",
    "zoomIn2d",
    "zoomOut2d",
    "pan2d",
    "resetScale2d",
    "lasso2d",
    "select2d",
    "sendDataToCloud",
    "zoom2d",
    "pan2d",
    "zoomIn2d",
    "zoomOut2d",
    "autoScale2d",
    "resetScale2d",
    "hoverClosestCartesian",
    "hoverCompareCartesian",
    "zoom3d",
    "pan3d",
    "orbitRotation",
    "tableRotation",
    "resetCameraDefault3d",
    "resetCameraLastSave3d",
    "hoverClosest3d",
    "zoomInGeo",
    "zoomOutGeo",
    "resetGeo",
    "hoverClosestGeo",
    "hoverClosestGl2d",
    "hoverClosestPie",
    "toggleHover",
    "toImage",
    "resetViews",
    "toggleSpikelines",
  ];

  const onChartHover = (eventdata: Readonly<Plotly.PlotMouseEvent>) => {
    if (!hoverColor) return false;
    const pointIndex = eventdata.points[0].pointIndex;
    const hoverColorChart = getHoverColorChart(
      oriChartData,
      pointIndex,
      hoverColor
    );

    setChartData(hoverColorChart);
  };

  const onChartUnHover = () => {
    if (!hoverColor) return false;
    if (chartData !== oriChartData) {
      setChartData(oriChartData);
    }
  };

  if (isWidget) {
    delete layout.width;
    delete layout.height;
  }

  if (!customLayout) {
    delete layout.margin;
    const hasRightAxis =
      Object.keys(layout).filter(
        (key) => key.startsWith("yaxis") && layout[key].side === "right"
      ).length > 0;
    layout.margin = {
      t: 16,
      l: 65,
      r: hasRightAxis ? 65 : 2,
      b: 65,
    };
  }

  Object.keys(layout).forEach((key) => {
    switch (true) {
      case key.startsWith("xaxis"):
        layout[key] = setupAxisConfiguration(
          layout[key],
          showXGrid,
          grey60,
          customLayout
        );
        break;

      case layout[key] && key === "yaxis":
        layout[key] = setupAxisConfiguration(
          layout[key],
          showYGrid,
          grey60,
          customLayout
        );
        break;

      case layout[key] && key === "yaxis2":
        layout[key] = setupAxisConfiguration(
          layout[key],
          showYGrid,
          grey20,
          customLayout
        );
        break;
    }
  });

  const chartStyle = isWidget
    ? { width: "100%", height: "100%", minHeight: "300px" }
    : layout.width
    ? {}
    : { width: "100%" };

  return (
    <Suspense fallback={<CORELoading message={"Loading Chart"} size={"lg"} />}>
      {props.scatterGL ? (
        <Alert
          type="info"
          message="Due to the large number of points, we are displaying this chart in a way that can be blurry. To see sections of the chart in full detail, reduce the date range or increase the aggregation period."
          closable
        />
      ) : null}
      <MemoizedPlot
        divId={props.chartId}
        ref={props.plotRef}
        data={chartData}
        layout={layout}
        useResizeHandler
        config={{
          displaylogo: false,
          modeBarButtons: props.customModebarButtons,
          modeBarButtonsToRemove: removeButtons,
          displayModeBar: true,
          toImageButtonOptions: {
            width: 1000,
            format: "png",
            filename: downloadFileName(`${layout.filename ?? "Newplot"}`),
            scale: 1,
          },
        }}
        onHover={onChartHover}
        onUnhover={onChartUnHover}
        style={chartStyle}
      />
    </Suspense>
  );
};

const layoutOptionPlotly = (
  props: COREChartProps,
  chartMargin: MarginObject
) => {
  // currentYRange will be [] if props.useAutoScaleYAxis have value
  const customConfigXAxis = configXAxisLayout(props);

  return {
    // eslint-disable-next-line camelcase
    paper_bgcolor: "#FFFFFF",
    margin: chartMargin,
    images: !props.downloadPngCanvas
      ? ([
          {
            x: 1.09,
            y: -0.55,
            sizex: 0.3,
            sizey: 0.25,
            source: ImgCOREMarketsLogoSVG,
            xref: "paper",
            yref: "paper",
            xanchor: "right",
            yanchor: "bottom",
            opacity: 1,
            layer: "below",
            visible: true,
          },
        ] as Layout["images"])
      : [],
    hovermode: props.layout.hovermode || "x",
    autosize: props.useResizeHandler || true,
    ...props.layout,
    ...customConfigXAxis,
  };
};

const configXAxisLayout = (props: COREChartProps): Partial<Layout> => {
  const preXAxis = props?.layout?.xaxis;

  return {
    xaxis: {
      ...preXAxis,
      showline: preXAxis?.showline || true,
      showgrid: preXAxis?.showgrid || false,
      title: preXAxis?.title
        ? preXAxis?.title
        : {
            text: "",
          },
    },
  };
};

const preparePlotlyProps = (props, traceData, customModebarButtons) => {
  if (traceData.length !== 0) {
    return {
      ...props,
      customModebarButtons,
    };
  }

  return props;
};

const mapData = (props: COREChartProps) => {
  const { data: ds } = props;
  let scatterGL: boolean = false;
  // if not selected trace, It should be false
  const data = ds.map((datalist) => {
    const { type, x, ...other } = datalist;
    const checkScatter = x
      ? type === "scatter" && x.length >= scatterGLThreshold
      : false;
    scatterGL = checkScatter;
    return checkScatter ? { ...other, type: "scattergl", x } : datalist;
  });

  return {
    data,
    scatterGL,
  };
};

export const setPlotlyImage = async (
  plotRef: React.MutableRefObject<PlotRefType>
) => {
  if (!plotRef?.current) {
    return;
  }
  const chart = await Plotly.toImage(plotRef.current.el, {
    format: "svg",
    width: 1232,
    height: 586,
  });

  const createImageElement = new Image();
  createImageElement?.setAttribute("src", chart);

  return createImageElement;
};

export const drawTitleChartBlock = async ({
  titleBlock,
  ctx,
  canvasTotalWidth,
  titleMarginTop,
}: {
  titleBlock?: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  canvasTotalWidth: number;
  titleMarginTop: number;
}) => {
  if (titleBlock && titleBlock.height !== 0) {
    ctx.drawImage(
      titleBlock,
      (canvasTotalWidth - titleBlock.width) / 2,
      titleMarginTop
    );
  }
};

export const drawLegendBlock = async ({
  legendBlock,
  ctx,
  titleBlockHeight,
  marginLegendTop,
  canvasTotalWidth,
}: {
  legendBlock?: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  titleBlockHeight: number;
  marginLegendTop: number;
  canvasTotalWidth: number;
}) => {
  if (legendBlock && legendBlock.height !== 0) {
    ctx.drawImage(
      legendBlock,
      (canvasTotalWidth - legendBlock.width) / 2,
      titleBlockHeight + marginLegendTop * 1.5
    );
  }
};

export const drawPlotlyBlock = async ({
  plotlyBlock,
  ctx,
  canvasTotalWidth,
  titleBlockHeight,
  legendBlockHeight,
  canvasTotalHeight,
}: {
  plotlyBlock?: HTMLImageElement;
  ctx: CanvasRenderingContext2D;
  canvasTotalWidth: number;
  titleBlockHeight: number;
  legendBlockHeight: number;
  canvasTotalHeight: number;
}) => {
  if (plotlyBlock) {
    ctx.drawImage(
      plotlyBlock,
      0,
      0,
      canvasTotalWidth,
      canvasTotalHeight,
      0,
      titleBlockHeight + legendBlockHeight * 1.2,
      canvasTotalWidth,
      canvasTotalHeight
    );
  }
};

export const canvasTitleBlock = async ({
  titleDOM,
}: {
  titleDOM: RefObject<HTMLDivElement>;
}) => {
  if (!titleDOM?.current) return undefined;

  return toCanvas(titleDOM.current, { pixelRatio: 1 });
};

export const canvasLegendBlock = async ({
  legendDOM,
}: {
  legendDOM: RefObject<HTMLDivElement>;
}) => {
  if (!legendDOM?.current) return undefined;

  return toCanvas(legendDOM.current, { pixelRatio: 1 });
};

const createCustomModebar = (props, csvActive) => {
  const {
    downloadCsv,
    downloadPng,
    downloadPngCanvas,
    plotRef,
    legendRef,
    titleRef,
    chartTitle,
  }: {
    downloadCsv?: COREChartProps["downloadCsv"];
    downloadPng?: COREChartProps["downloadPng"];
    downloadPngCanvas?: COREChartProps["downloadPngCanvas"];
    plotRef?: COREChartProps["plotRef"];
    legendRef?: COREChartProps["legendRef"];
    titleRef?: COREChartProps["titleRef"];
    chartTitle?: COREChartProps["chartTitle"];
  } = props;

  const downloadScreenshot = () => {
    drawImageIntoCanvas(plotRef, titleRef, legendRef, chartTitle);
  };

  const downloadCSVIcon: Icon = {
    width: 612,
    height: 612,
    path: "M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z",
    transform: "matrix(1 0 0 1 0 1)",
  };

  const downloadImageIcon: Icon = {
    width: 612,
    height: 612,
    path: "M194.6 32H317.4C338.1 32 356.4 45.22 362.9 64.82L373.3 96H448C483.3 96 512 124.7 512 160V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V160C0 124.7 28.65 96 64 96H138.7L149.1 64.82C155.6 45.22 173.9 32 194.6 32H194.6zM256 384C309 384 352 341 352 288C352 234.1 309 192 256 192C202.1 192 160 234.1 160 288C160 341 202.1 384 256 384z",
    transform: "matrix(1 0 0 1 0 1)",
  };

  const toCsvButton: ModeBarButton = {
    name: "toCsv",
    title: "Download as a CSV",
    icon: downloadCSVIcon,
    click: (e) => downloadCSVFile(e),
  };

  const toImageButton: ModeBarButton = {
    name: "toImage",
    title: "Download plot as a png",
    icon: downloadImageIcon,
    click: downloadScreenshot,
  };

  const customModebarButtons: Array<ModeBarDefaultButtons[] | ModeBarButton[]> =
    [];
  if (downloadCsv !== false && csvActive) {
    customModebarButtons.push([toCsvButton]);
  }

  if (downloadPng !== false) {
    if (downloadPngCanvas) {
      customModebarButtons.push([toImageButton]);
    } else {
      customModebarButtons.push(["toImage"]);
    }
  }

  return customModebarButtons;
};

export type COREChartProps = {
  chartId: string;
  chartTitle?: string;
  enableEmptyIcon?: boolean;
  hoverColor?: ColorHex;
  config?: Partial<Config>;
  configRangeOfYAxis?: ConfigYAxisValue[];
  data: Partial<ChartDataType>[];
  layout: Partial<Layout>;
  useResizeHandler?: boolean;
  loading?: boolean;
  downloadCsv?: boolean;
  showTimeButtons?: boolean;
  downloadPng?: boolean;
  downloadPngCanvas?: boolean;
  margin?: MarginObject;
  testID: TestID;
  plotRef?: React.MutableRefObject<PlotRefType>;
  legendRef?: RefObject<HTMLDivElement>;
  titleRef?: RefObject<HTMLDivElement>;
  maxHeight?: number;
  isWidget?: RenderPlotlyProps["isWidget"];
  customLayout?: boolean;
};

export const COREChart: React.FC<COREChartProps> = ({
  maxHeight,
  isWidget,
  ...props
}) => {
  useEffect(() => {
    const resizeCurrent = props.plotRef?.current?.resizeHandler;
    if (resizeCurrent) resizeCurrent();
  }, [props.plotRef]);

  const { data, scatterGL } = mapData(props);

  const chartMargin: MarginObject = props.margin || { l: 2, t: 2, r: 2, b: 2 };
  const layout: Partial<Layout> = layoutOptionPlotly(props, chartMargin);
  const allowCsvType: string[] = ["scatter", "scattergl", "bar", "lines"];
  const csvActive: boolean = data[0]?.type
    ? allowCsvType.includes(data[0].type)
    : false;

  const customModebarButtons: Array<ModeBarDefaultButtons[] | ModeBarButton[]> =
    createCustomModebar(props, csvActive);

  const showTimeButtons: boolean = props.showTimeButtons ?? true;
  const timeSeries: Partial<Plotly.RangeSelector> = {
    buttons: [
      {
        count: 7,
        label: "1W",
        step: "day",
        stepmode: "backward",
      },
      {
        count: 1,
        label: "1M",
        step: "month",
        stepmode: "backward",
      },
      {
        count: 3,
        label: "1Q",
        step: "month",
        stepmode: "backward",
      },
      {
        count: 1,
        label: "1Y",
        step: "year",
        stepmode: "backward",
      },
    ],
  };
  if (showTimeButtons && layout["xaxis"])
    layout["xaxis"]["rangeselector"] = timeSeries;

  const renderPlotlyProps: RenderPlotlyProps = preparePlotlyProps(
    { ...props, scatterGL, data, layout },
    data,
    customModebarButtons
  );

  if (isWidget) {
    return (
      <div className={classNames("core-chart-fit-parent")}>
        <Spin spinning={props.loading || false} delay={100}>
          <div
            style={{ height: maxHeight ?? "100%" }}
            className={classNames("core-chart-block")}
          >
            <RenderPlotly isWidget {...renderPlotlyProps} />
          </div>
        </Spin>
      </div>
    );
  }

  return (
    <Spin spinning={props.loading || false} delay={100}>
      <div className={classNames("core-chart-block")}>
        <RenderPlotly {...renderPlotlyProps} />
      </div>
    </Spin>
  );
};
