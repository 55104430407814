/* eslint-disable react/prop-types */
import React from "react";
import { Select, SelectProps } from "antd";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import "./CORESelect.less";
import { CORETag } from "../Content/CORETag";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { WidthSize } from "./COREInput";
import { CORELoading } from "../Feedback/CORELoading";

type Size = "sm" | "md" | "lg";
export type CORESelectProps = Omit<
  SelectProps,
  "className" | "size" | "mode"
> & {
  testID: TestID;
  size?: Size;
  widthSize?: WidthSize;
  mode?: "basic" | "multiple";
};

export type SuffixIconProp = {
  showSearch: boolean;
  open: boolean;
};

export const SuffixIcon: React.FC<SuffixIconProp> = ({ showSearch, open }) => {
  if (open && showSearch) {
    return (
      <COREIcon icon={icon({ name: "magnifying-glass", style: "regular" })} />
    );
  } else if (open) {
    return <COREIcon icon={icon({ name: "caret-up", style: "solid" })} />;
  } else {
    return <COREIcon icon={icon({ name: "caret-down", style: "solid" })} />;
  }
};

const removeSelectFocused = (visible: boolean) => {
  if (!visible) {
    const elements = document.getElementsByClassName("ant-select-focused");
    for (const element of elements) {
      element.classList.remove("ant-select-focused");
    }
  }
};

export const onPreventMouseDown = (event: {
  preventDefault: () => void;
  stopPropagation: () => void;
}) => {
  event.preventDefault();
  event.stopPropagation();
};
const CORESizeToAntSize: Record<Size, SelectProps["size"]> = {
  lg: "large",
  md: "middle",
  sm: "small",
};
export const CORESelect: React.FC<CORESelectProps> = ({
  size = "default",
  testID,
  suffixIcon = ({ showSearch, open }: SuffixIconProp) => (
    <SuffixIcon showSearch={showSearch} open={open} />
  ),
  menuItemSelectedIcon = (
    <COREIcon icon={icon({ name: "circle-check", style: "solid" })} />
  ),
  tagRender = (tagProps) => (
    <CORETag
      type={"basic"}
      label={tagProps.label}
      closable={tagProps.closable}
      onClose={tagProps.onClose}
      onMouseDown={onPreventMouseDown}
    />
  ),
  maxTagPlaceholder = (tags) => `+${tags.length}`,
  onDropdownVisibleChange,
  widthSize = "md",
  filterOption = (search, option) => {
    if (!option?.label) return false;
    return option.label.toString().toLowerCase().includes(search.toLowerCase());
  },
  mode,
  loading,
  ...props
}) => (
  <CORELoading
    layout={"horizontal"}
    size={CORESizeToAntSize[size]}
    loading={loading}
    delay={0}
  >
    <TestIDWrapper testID={testID} className={"core-select"}>
      <div className={`input-width-${widthSize}`}>
        <Select
          maxTagCount={"responsive"}
          dropdownStyle={{ padding: "2px" }}
          size={CORESizeToAntSize[size]}
          suffixIcon={suffixIcon}
          tagRender={tagRender}
          maxTagPlaceholder={maxTagPlaceholder}
          dropdownClassName={"core-select-dropdown"}
          onDropdownVisibleChange={(visible) => {
            removeSelectFocused(visible);
            onDropdownVisibleChange && onDropdownVisibleChange(visible);
          }}
          {...(mode === "multiple" && {
            mode,
            showArrow: true,
            allowClear: true,
            menuItemSelectedIcon: menuItemSelectedIcon,
          })}
          {...props}
          filterOption={filterOption}
          showSearch={true}
        />
      </div>
    </TestIDWrapper>
  </CORELoading>
);
