/* eslint-disable react/prop-types */
import {
  ChartDataType,
  COREChart,
  COREChartProps,
  downloadCSVFile,
} from "./COREChart";
import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
} from "react";
import { AggregatesChartProps } from "../../modules/chartBuilder/AggregatesChartV2";
import "./COREChart.less";
import { drawImageIntoCanvas } from "./COREChartLegend";
import { bodyFontFamily } from "../../shared/global";
import { ChartProps } from "./ChartHOC";
import Plotly from "plotly.js/dist/plotly-myPlotly.js";
import equal from "fast-deep-equal";

type chartProps = Pick<
  AggregatesChartProps,
  | "chartId"
  | "chartTitle"
  | "downloadCsv"
  | "downloadPng"
  | "downloadPngCanvas"
  | "enableEmptyIcon"
  | "testID"
  | "showlegend"
  | "legendFilter"
  | "plotRef"
  | "chartPanel"
  | "ref"
  | "empty"
> &
  Pick<COREChartProps, "data" | "configRangeOfYAxis" | "hoverColor">;

export type CORESunburstChartProps = chartProps & {
  titleRef?: MutableRefObject<HTMLDivElement | null>;
  legendRef?: MutableRefObject<HTMLDivElement | null>;
  rawData?: sunBurstDataType[];
  formatCSVDataWithHeader?: (
    rawData: CORESunburstChartProps["rawData"]
  ) => (string | number | null)[][];
};

type chartActionProps = Pick<AggregatesChartProps, "plotRef" | "chartTitle"> &
  Pick<
    ChartProps,
    "setOnDownloadCSV" | "setOnDownloadPNG" | "setOnResetChart"
  > &
  Pick<CORESunburstChartProps, "rawData" | "formatCSVDataWithHeader"> & {
    data: Partial<ChartDataType>[];
  };

export const hexToRgb = (hex: string) => {
  const arrBuff = new ArrayBuffer(4);
  const vw = new DataView(arrBuff);
  vw.setUint32(0, parseInt(hex, 16), false);
  const arrByte = new Uint8Array(arrBuff);

  return arrByte[1] + "," + arrByte[2] + "," + arrByte[3];
};

export const genSunburstColour = (
  colorName: string,
  colorTint: number,
  alphaLevel: number
) => {
  const computedStyle = getComputedStyle(document.body);
  const varColor = computedStyle
    .getPropertyValue(`--${colorName}-${colorTint}`)
    .replace("#", "");

  return `rgba(${hexToRgb(varColor)}, ${alphaLevel})`;
};

export type SunburstColourType = {
  position: number | null;
  level: number | null;
  baseColor: string[] | null;
  rawData: sunBurstDataType;
};

export const sunburstColour = ({
  position,
  level,
  baseColor,
}: SunburstColourType) => {
  if (position === null || level === null || baseColor === null) return " ";

  const baseColorCount = baseColor.length;
  const remainder = position % baseColorCount;
  const quotient = (position - remainder) / baseColorCount;
  const colorName = baseColor[remainder];
  const baseColorTint = colorName === "grey" ? 160 : 120;
  const colorTint = baseColorTint - 20 * quotient;
  const alphaLevel = 1 - 0.1 * level;

  return genSunburstColour(colorName, colorTint, alphaLevel);
};

export const defaultConfigCORESunburstChart: Partial<ChartDataType> = {
  type: "sunburst",
  textfont: {
    color: "#FFF",
    family: bodyFontFamily,
  },
  hoverlabel: {
    font: {
      color: "#FFF",
      family: bodyFontFamily,
    },
  },
  insidetextorientation: "radial",
  leaf: { opacity: 1 },
  sort: false,
  branchvalues: "total",
  hoverinfo: "none",
};

export type sunBurstDataType = {
  labels: string[] | null;
  assigned: number;
  gross: number;
  grossRatio: number | null;
};

export const defaultSunBurstColor = [
  "indigo",
  "green",
  "turquoise",
  "grey",
  "purple",
  "blue",
];

export const convertSunBurstData = (
  data: sunBurstDataType[],
  baseColor = defaultSunBurstColor,
  leafColorFunc = sunburstColour
) => {
  const ids: string[] = [];
  const parents: string[] = [];
  const values: number[] = [];
  const colors: string[] = [];

  const dataWithInner = [
    ...data,
    {
      labels: null,
      assigned: 0,
      gross: 0,
      grossRatio: null,
    },
  ];

  const rootLevel = dataWithInner
    .filter((d) => d.labels?.length === 1)
    .map((d) => d.labels)
    .flat();

  const cleanData = dataWithInner.filter(
    (d) => d.labels && d.labels?.length > 0
  );

  const labels = cleanData.map((d) => {
    const keyJoin = " - ";

    if (d.labels === null || d.labels.length === 0) {
      ids.push("root");
      parents.push("");
      values.push(0);
      return "";
    }

    const parent =
      d.labels.length === 1 ? " " : d.labels.slice(0, -1).join(keyJoin);
    const value = d.gross ?? 1;

    const level0 = d.labels[0];

    const parentIndex = rootLevel.findIndex((e) => e === level0);

    const color = leafColorFunc({
      position: parentIndex,
      level: d.labels.length - 2,
      baseColor,
      rawData: d,
    });

    ids.push(d.labels.join(keyJoin));
    parents.push(parent);
    values.push(value);
    colors.push(color);

    return d.labels[d.labels.length - 1];
  });

  return {
    ids: [" ", ...ids],
    parents: ["", ...parents],
    labels: ["", ...labels],
    values: ["", ...values],
    colors: ["", ...colors],
  };
};

export const CORESunburstLayout = {
  margin: { l: 0, r: 0, b: 0, t: 0 },
  autosize: true,
  responsive: true,
};

const emptyDataCheck = (data: sunBurstDataType[]) => {
  if (data.length === 0) return true;
  return data.every((datalist) => {
    return datalist.gross === 0 || datalist.gross === undefined;
  });
};

const handleResetChart = (
  plotRef: chartActionProps["plotRef"],
  data: chartActionProps["data"]
) => {
  if (!plotRef?.current) {
    return;
  }

  const resetData = [
    {
      ...data[0],
      level: " ",
    },
  ];

  Plotly.newPlot(plotRef.current.el, resetData, CORESunburstLayout);
};

const handleDownloadCSV = (
  rawData: chartActionProps["rawData"],
  chartTitle: chartActionProps["chartTitle"],
  formatCSVDataWithHeader?: chartActionProps["formatCSVDataWithHeader"]
) => {
  if (formatCSVDataWithHeader) {
    const csvDataWithHeader = formatCSVDataWithHeader(rawData);
    return downloadCSVFile(csvDataWithHeader, true, chartTitle);
  }

  const csvData =
    rawData &&
    rawData.map((d) => {
      if (!d.labels) return [];
      return [d.labels?.join(" - "), d.assigned, d.gross, d.grossRatio];
    });

  const csvDataWithHeader = [
    [
      "Categories",
      "Assigned Emissions tCO₂e",
      "Gross Emissions tCO₂e",
      "Category Gross Emissions tCO₂e Ratio",
    ],
    ...(csvData ?? []).map((row) => [...row]),
  ];

  return downloadCSVFile(csvDataWithHeader, true, chartTitle);
};

const handleDownloadPNG = (
  plotRef: chartActionProps["plotRef"],
  chartTitle: chartActionProps["chartTitle"]
) => {
  if (!plotRef?.current) {
    return;
  }

  Plotly.downloadImage(plotRef.current.el, {
    format: "png",
    width: 1200,
    height: 1200,
    filename: `${chartTitle ?? "Newplot"}`,
  });
};

const useChartAction = ({
  plotRef,
  data,
  chartTitle,
  rawData,
  setOnDownloadCSV,
  setOnDownloadPNG,
  setOnResetChart,
  formatCSVDataWithHeader,
}: chartActionProps): void => {
  useEffect(() => {
    const getHandleResetChart = () => {
      return handleResetChart(plotRef, data);
    };

    const getHandleDownloadCSV = () => {
      return handleDownloadCSV(rawData, chartTitle, formatCSVDataWithHeader);
    };

    const getHandleDownloadPNG = () => {
      return handleDownloadPNG(plotRef, chartTitle);
    };

    if (setOnDownloadCSV) setOnDownloadCSV(() => getHandleDownloadCSV);
    if (setOnDownloadPNG) setOnDownloadPNG(() => getHandleDownloadPNG);
    if (setOnResetChart) setOnResetChart(() => getHandleResetChart);
  }, [
    chartTitle,
    data,
    plotRef,
    rawData,
    setOnDownloadCSV,
    setOnDownloadPNG,
    setOnResetChart,
    formatCSVDataWithHeader,
  ]);
};

// eslint-disable-next-line react/display-name
export const SunburstChart: React.FC<CORESunburstChartProps & ChartProps> =
  forwardRef(
    (
      {
        chartId,
        chartTitle,
        downloadCsv,
        downloadPng,
        downloadPngCanvas,
        data,
        configRangeOfYAxis,
        hoverColor,
        enableEmptyIcon,
        testID,
        plotRef,
        titleRef,
        legendRef,
        rawData = [],
        empty,
        setOnDownloadCSV,
        setOnDownloadPNG,
        setOnResetChart,
        formatCSVDataWithHeader,
      },
      ref
    ) => {
      useImperativeHandle(ref, () => ({
        callDrawImage() {
          drawImageIntoCanvas(plotRef, titleRef, legendRef, chartTitle);
        },
      }));

      useChartAction({
        plotRef,
        data,
        chartTitle,
        rawData,
        setOnDownloadCSV,
        setOnDownloadPNG,
        setOnResetChart,
        formatCSVDataWithHeader,
      });

      if (emptyDataCheck(rawData)) return empty;

      return (
        <div
          className={"core-sunburst-chart-container"}
          style={{ margin: "0 auto" }}
        >
          <COREChart
            chartId={chartId}
            chartTitle={chartTitle}
            testID={testID}
            plotRef={plotRef}
            downloadCsv={downloadCsv}
            downloadPng={downloadPng}
            downloadPngCanvas={downloadPngCanvas}
            data={data}
            layout={CORESunburstLayout}
            useResizeHandler={true}
            configRangeOfYAxis={configRangeOfYAxis}
            config={{
              displaylogo: false,
              displayModeBar: false,
            }}
            customLayout
            showTimeButtons={false}
            hoverColor={hoverColor}
            enableEmptyIcon={enableEmptyIcon}
            legendRef={legendRef}
            titleRef={titleRef}
          />
        </div>
      );
    }
  );

export const CORESunburstChart = React.memo<
  React.ComponentType<CORESunburstChartProps & ChartProps>
>(
  SunburstChart,
  (prevProps, nextProps) =>
    prevProps &&
    equal(prevProps.data, nextProps.data) &&
    equal(prevProps.rawData, nextProps.rawData)
);
