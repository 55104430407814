import React from "react";
import { COREAccordion, COREAccordionProps } from "./COREAccordion";
import { COREAccordionItem, COREAccordionItemProps } from "./COREAccordionItem";
import "./COREContainerPanel.less";
import classNames from "classnames";

export type COREContainerProps = {
  disabled?: boolean;
  fullHeight?: boolean;
} & Omit<
  COREAccordionProps,
  | "activeKey"
  | "defaultActiveKey"
  | "accordion"
  | "collapsible"
  | "onChange"
  | "spacing"
> &
  Omit<
    COREAccordionItemProps,
    "isOpen" | "collapsible" | "testID" | "key" | "headerSummary"
  >;

export const COREContainer: React.FC<COREContainerProps> = ({
  testID,
  destroyInactivePanel,
  expandIcon,
  expandIconPosition,
  ghost,
  interactionsLeft = [],
  interactionsRight = [],
  interactionsMenu = [],
  menuType = "useLeftRightForMenu",
  children,
  disabled,
  fullHeight = true,
  bordered,
  ...props
}) => (
  <div
    className={classNames({
      "full-height": fullHeight,
    })}
  >
    <COREAccordion
      testID={`${testID}-container`}
      activeKey={[0]}
      defaultActiveKey={[0]}
      accordion={false}
      destroyInactivePanel={destroyInactivePanel}
      expandIcon={expandIcon}
      expandIconPosition={expandIconPosition}
      ghost={ghost}
      spacing={"none"}
      bordered={bordered}
    >
      <COREAccordionItem
        isOpen={true}
        testID={`${testID}-container-panel`}
        key={0}
        collapsible={disabled ? "disabled" : "header"}
        menuType={menuType}
        interactionsLeft={interactionsLeft}
        interactionsRight={interactionsRight}
        interactionsMenu={interactionsMenu}
        {...props}
      >
        {children}
      </COREAccordionItem>
    </COREAccordion>
  </div>
);
