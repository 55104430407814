import { useDebugValue, useEffect, useState } from "react";
import { useDeepCompareEffect } from "react-use";
import { useQueries } from "react-query";
import moment from "moment";
import { chunkRange, rangeFormatToServer } from "../date/ranges";
import { useUserLoggedInStatus } from "../state/user";
import { appQueryClient as queryClient } from "../state/appQueryClient";
import { keysToSnake } from "../global";

const chunkApiRequest = (range, interval, by) =>
  by !== undefined ? [range] : chunkRange(range, interval);

export const useAPIRange = (action, apiOptions) => {
  const options = apiOptions.flatMap(
    ({
      skip,
      rangeOptions: { field, range, interval },
      params,
      body,
      ...otherOptions
    }) => {
      const rangeIntervals = chunkApiRequest(range, interval, body.by);
      return rangeIntervals.map((ri) => {
        // const p = { ...params, [field]: formatRange(ri) };
        const b = { ...body, [field]: rangeFormatToServer(ri) };
        return { ...otherOptions, params: params, body: keysToSnake(b) };
      });
    }
  );

  const api = useAPIQueryRequests(action, options);

  let lengthSoFar = 0;
  const results = apiOptions.map((apiOption) => {
    const {
      rangeOptions: { range, interval },
      body,
    } = apiOption;
    const length = chunkApiRequest(range, interval, body.by).length;
    let start = lengthSoFar;
    lengthSoFar = start + length;
    return api.results.slice(start, lengthSoFar).reduce(
      (
        {
          data: aData = [],
          error: aError,
          sync: aSync,
          loading: aLoading,
          progress: aProgress = { complete: 0, total: 0 },
        },
        { error: cError, data: cData, sync: cSync, loading: cLoading }
      ) => {
        const sync = cSync || aSync;
        const loading = cLoading || aLoading;
        let data;
        if (aSync && cSync) {
          data = [...aData, ...cData];
        } else {
          if (aSync) {
            data = aData;
          } else if (cSync) {
            data = cData;
          }
        }
        const error = aError ?? cError;

        return {
          error,
          sync,
          loading,
          data,
          apiOption,
        };
      },
      {
        loading: false,
        error: false,
        data: undefined,
        sync: false,
        headers: undefined,
        apiOption: undefined,
      }
    );
  });

  const ret = { ...api, results };
  useDebugValue(ret);
  return ret;
};

const apiPaginationData = (results) => {
  return results.reduce(
    (
      {
        data: aData = [],
        error: aError,
        sync: aSync,
        loading: aLoading,
        progress: aProgress = { complete: 0, total: 0 },
      },
      { error: cError, data: cData, sync: cSync, loading: cLoading }
    ) => {
      const sync = cSync || aSync;
      const loading = cLoading || aLoading;
      let data;
      const error = aError ?? cError;

      if (aSync && cSync && !error) {
        data = [...aData, ...cData];
      } else {
        if (aSync && !aError) {
          data = aData;
        } else if (cSync && !cError) {
          data = cData;
        }
      }

      return {
        error,
        sync,
        loading,
        data,
      };
    },
    {
      loading: false,
      error: false,
      data: undefined,
      sync: false,
      headers: undefined,
    }
  );
};

const onRetryFunction = (failureCount, error) => {
  if (
    error &&
    error.response &&
    (error.response.status === 416 || error.response.status === 400)
  ) {
    return false;
  }
  return failureCount < 3;
};

const isPlaceholderData = (query) => {
  if (query.isPlaceholderData) {
    return query.data;
  }

  return query.data && query.data.hasOwnProperty("data")
    ? query.data.data
    : undefined;
};

const generateResult = (queries) => {
  return queries.map((query) => {
    return {
      loading: query.isLoading || query.isFetching,
      error: query.error ?? false,
      headers:
        query.data && query.data.hasOwnProperty("headers")
          ? Object.entries(query.data.headers)
          : undefined,
      data: isPlaceholderData(query),
      sync: !query.isLoading && !query.isError,
      forceRefresh: query.refetch,
      lastUpdate: moment(query.dataUpdatedAt),
    };
  });
};

export const useAPIQueryRequest = (
  action,
  params,
  skip = false,
  body,
  customHeader
) => {
  const { results, forceRefresh } = useAPIQueryRequests(action, [
    { params, body, skip, customHeader },
  ]);
  const [{ data, error, ...rest } = { data: undefined }] = results;
  useDebugValue({ ...rest, data: { data } });
  return {
    ...rest,
    error: error !== null ? error : false,
    data: { data },
    forceRefresh,
  };
};

export const useAPIQueryRequests = (action, apiOptions, queryFn) => {
  const isLoggedIn = useUserLoggedInStatus();

  const queries = useQueries(
    apiOptions.map((option) => {
      const { fetchOptions, params, body, skip, customHeader } = option;
      return {
        ...(queryFn && {
          queryFn: async () => {
            return await queryFn([
              action,
              {
                action,
                fetchOptions,
                params,
                body,
                enabled: !isLoggedIn ? false : !skip, // enabled: true -> is call API
                retry: !isLoggedIn ? false : !skip,
              },
            ]);
          },
        }),
        queryKey: [
          action,
          {
            action,
            fetchOptions,
            params,
            body,
            customHeader,
            enabled: !isLoggedIn ? false : !skip, // enabled: true -> is call API
            retry: !isLoggedIn ? false : !skip,
          },
        ],
        retry: onRetryFunction,
      };
    })
  );

  const progress =
    queries.filter((i) => !i.isError && !i.isLoading).length / queries.length;
  return {
    progress: { percent: progress || 0 },
    results: generateResult(queries),
    forceRefresh: () => {
      queries.forEach((query) => query.refetch());
    },
    sync: queries.some((q) => q.isSuccess),
    loading: queries.some((q) => q.isLoading),
    error: queries.some((q) => q.isError),
  };
};

export const useAPIQueryRequestWithPlaceHolder = (
  action,
  params,
  skip = false,
  body,
  callback
) => {
  const { results, forceRefresh } = useAPIQueryRequestsWithPlaceHolder(
    action,
    [{ params, body, skip }],
    callback
  );
  const [{ data, error, ...rest } = { data: undefined }] = results;
  useDebugValue({ ...rest, data: { data } });
  return {
    ...rest,
    error: error !== null ? error : false,
    data: { data },
    forceRefresh,
  };
};

export const useAPIQueryRequestsWithPlaceHolder = (
  action,
  apiOptions,
  callback
) => {
  const isLoggedIn = useUserLoggedInStatus();

  const queries = useQueries(
    apiOptions.map((option) => {
      const { fetchOptions, params, body, skip } = option;
      return {
        queryKey: [
          action,
          {
            action,
            fetchOptions,
            params,
            body,
            enabled: !isLoggedIn ? false : !skip, // enabled: true -> is call API
            retry: !isLoggedIn ? false : !skip,
          },
        ],
        retry: onRetryFunction,
        placeholderData: () => {
          const getQueryData = queryClient
            .getQueryCache()
            .queries?.find((d) => {
              return d.queryKey[0] === action;
            });

          if (!getQueryData?.state?.data?.data) return undefined;

          if (callback) {
            return callback(getQueryData?.state?.data?.data);
          }

          return getQueryData?.state?.data?.data;
        },
      };
    })
  );

  const progress =
    queries.filter((i) => !i.isError && !i.isLoading).length / queries.length;
  return {
    progress: { percent: progress || 0 },
    results: generateResult(queries),
    forceRefresh: () => {
      queries.forEach((query) => query.refetch());
    },
    loading: queries.some((q) => q.isLoading),
    error: queries.some((q) => q.isError),
  };
};

export const useAPIQueryPagination = (action, paramsObj, pagination) => {
  const [page, setPageNumber] = useState(1);
  const [shouldLoadAll, setShouldLoadAll] = useState(pagination.shouldLoadAll);

  useDeepCompareEffect(
    () => setTotalDataPages(1),
    [paramsObj.params, paramsObj.apiOption]
  ); //reset if params change

  const [totalPages, setTotalDataPages] = useState(1);
  useEffect(() => {
    if (shouldLoadAll) {
      setPageNumber(totalPages);
    }
  }, [shouldLoadAll, totalPages]);
  const getFirstPage = () => setPageNumber(1);
  const getNextPage = () => setPageNumber((curPage) => curPage + 1);
  const loadAll = () => setShouldLoadAll(true);

  const paginatedApiOptions = [...Array(page).keys()].map((p) => ({
    ...paramsObj.apiOption,
    params: {
      ...paramsObj.params,
      limit: pagination.perPage,
      offset: p * pagination.perPage,
    },
  }));

  const { results, ...metadata } = useAPIQueryRequests(
    action,
    paginatedApiOptions
  );
  const [{ error }] = results;
  const data = apiPaginationData(results);

  const [, contentRange] = results[results.length - 1].headers
    ? results[results.length - 1].headers.find(
        ([header, _]) => header === "content-range"
      )
    : [null, null];

  const itemTotal = contentRange
    ? parseFloat(contentRange?.split("/")[0].split("-")[1]) + 1
    : null;
  const newTotal = contentRange ? parseFloat(contentRange.split("/")[1]) : null;
  const newTotalPages = newTotal
    ? Math.ceil(newTotal / pagination.perPage)
    : null;

  useEffect(() => {
    if (newTotalPages === null) return;
    if (newTotalPages === "*") {
      // TODO:: // please have a look on this Condition is always false since types 'number' and 'string' have no overlap
      return setTotalDataPages((curTotalPages) => curTotalPages + 1);
    }
    setTotalDataPages(newTotalPages);
  }, [newTotalPages, results.length]);
  const ret = {
    ...metadata,
    getNextPage,
    getFirstPage,
    loadAll,
    error,
    loading: results?.some((r) => r.loading === true) ?? false,
    sync: results?.some((r) => r.sync === false) ? false : true,
    data: data.data,
    hasMore: itemTotal !== newTotal,
  };
  useDebugValue(ret);
  return ret;
};

export const useAPIQuery = useAPIQueryRequest;
export const useAPIQueryWithPlaceHolder = useAPIQueryRequestWithPlaceHolder;
