import { Col, Collapse, Row, Space } from "antd";
import { CollapsePanelProps, CollapseProps } from "antd/lib/collapse";
import React, { useEffect, useState } from "react";
import { COREProgress } from "../Feedback/COREProgress";
import "./COREContainerPanel.less";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { COREHeading } from "../Typography/COREHeading";
import { CORESelect, CORESelectProps } from "../Form/CORESelect";
import { COREButton } from "../Action/COREButton";
import { COREMenuItem } from "../Navigation/COREMenuItem";
import classNames from "classnames";
import {
  defaultResizeValue,
  OnResize,
  SetSizeObject,
} from "../../shared/OnResize";
import { COREDropdownMenu, DropdownMenuItem } from "../Form/COREDropdownMenu";
import { CORECSVButton, CORECSVButtonProps } from "../Action/CORECSVButton";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { spacing } from "../../shared/global";

export type InteractionProps =
  | React.ReactElement<typeof COREButton>
  | React.ReactElement<typeof CORESelect>;

type LeftRightInteractionMenuProps = {
  menuType?: "useLeftRightForMenu";
  interactionsLeft?: InteractionProps[];
  interactionsRight?: InteractionProps[];
  interactionsMenu?: DropdownMenuItem[];
};

type DifferentInteractionMenuProps = {
  menuType: "differentMenu";
  interactionsLeft?: InteractionProps[];
  interactionsRight?: InteractionProps[];
  interactionsMenu: DropdownMenuItem[];
};

export type InteractionMenuItem = DropdownMenuItem;

type CommonProps = {
  isOpen?: boolean;
  bannerImageUrl?: string;
  bannerImageHeight?: number;
  maxHeight?: number;
  noScroll?: boolean;
  progress?: React.ReactElement<typeof COREProgress>;
  interactionSpace?: number;
  showMenuAlways?: boolean;
  expandIconSize?: SetSizeObject;
  testID: TestID;
  onShowMenu?: (isShowMenu: boolean) => void;
  headerSummary?: React.ReactNode;
  expandIcon?: CollapseProps["expandIcon"];
  type?: "panel" | "container";
  roundCorner?: boolean;
};

export type COREAccordionItemProps = CommonProps &
  (LeftRightInteractionMenuProps | DifferentInteractionMenuProps) &
  CollapsePanelProps;

type HeaderWithInteractionsProps = {
  stopPropagationWhenNotCollapsed: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  isActive: boolean;
  showMenu: boolean;
  isDisabled: boolean;
  interactionSpace: Exclude<
    COREAccordionItemProps["interactionSpace"],
    undefined
  >;
} & Pick<
  COREAccordionItemProps,
  | "headerSummary"
  | "collapsible"
  | "expandIcon"
  | "testID"
  | "header"
  | "interactionsLeft"
>;

export const InteractionMenuButton: React.FC<{
  showMenu: boolean;
  menuType: "useLeftRightForMenu" | "differentMenu";
  interactionsLeft?: InteractionProps[];
  interactionsRight?: InteractionProps[];
  interactionsMenu?: InteractionMenuItem[];
  testID: TestID;
  isDisabled: boolean;
}> = ({
  showMenu,
  menuType,
  interactionsMenu,
  interactionsLeft,
  interactionsRight,
  testID,
  isDisabled,
  children,
}) => {
  let menuItems: InteractionMenuItem[] = [];
  if (!showMenu) return null;

  if (menuType === "useLeftRightForMenu") {
    const interactionsLeftRight = interactionsLeft?.concat(
      interactionsRight ? interactionsRight : []
    );
    menuItems = interactionsLeftRight
      ? interactionsLeftRight.map((interaction) => {
          testID = (testID + interaction.key) as TestID;
          switch (interaction.type) {
            case COREButton:
            case CORECSVButton: {
              const coreCsvButton =
                interaction.props as unknown as CORECSVButtonProps;
              return {
                component: (
                  <COREMenuItem
                    testID={testID}
                    key={testID}
                    icon={coreCsvButton.icon}
                  >
                    {coreCsvButton.children}
                  </COREMenuItem>
                ),
                closeWhenClick: true,
              } as InteractionMenuItem;
            }
            case CORESelect:
              return {
                component: (
                  <COREMenuItem testID={testID} key={testID}>
                    <div>
                      <CORESelect
                        {...(interaction.props as unknown as CORESelectProps)}
                        placement={"bottomRight"}
                      />
                    </div>
                  </COREMenuItem>
                ),
                closeWhenClick: false,
              } as InteractionMenuItem;
            default:
              return {
                component: (
                  <COREMenuItem testID={testID} key={testID}>
                    {interaction}
                  </COREMenuItem>
                ),
                closeWhenClick: false,
              } as InteractionMenuItem;
          }
        })
      : [];
  } else {
    menuItems = interactionsMenu ? interactionsMenu : [];
  }

  if (menuItems.length === 0) return null;

  return (
    <COREDropdownMenu
      interactionsMenu={menuItems}
      isDisabled={isDisabled}
      testID={(testID + "-dropdown-menu") as TestID}
      overlayStyle={{ minWidth: "192px" }}
    >
      {children}
    </COREDropdownMenu>
  );
};

const Interactions = ({
  interactions,
  space,
  menu,
  interactionsClassName,
  showMenu,
  isDisabled,
}: {
  space: number;
  interactions?: InteractionProps[];
  menu?: React.ReactElement<typeof InteractionMenuButton>;
  interactionsClassName?: string;
  showMenu: boolean;
  isDisabled: boolean;
}) => (
  <div
    className={"interactions"}
    onClick={(event) => {
      event.stopPropagation();
    }}
  >
    <Space size={space}>
      {!showMenu &&
        interactions &&
        interactions
          .filter((l) => l)
          .map((l, i) => (
            <div
              className={interactionsClassName}
              key={`warpper-${i}-${l?.key}`}
            >
              {React.cloneElement(l as React.ReactElement, {
                disabled: isDisabled,
                ...l.props,
              })}
            </div>
          ))}
      {menu}
    </Space>
  </div>
);

const HeaderWithInteractions: React.FC<HeaderWithInteractionsProps> = ({
  stopPropagationWhenNotCollapsed,
  headerSummary,
  collapsible,
  expandIcon,
  isActive,
  testID,
  header,
  showMenu,
  interactionsLeft,
  interactionSpace,
  isDisabled,
}) => {
  return (
    <Row
      onClick={stopPropagationWhenNotCollapsed}
      gutter={[spacing.sm, spacing.sm]}
      align="middle"
    >
      <Col {...(headerSummary && { flex: "190px" })}>
        <Row align="middle">
          {collapsible === undefined && expandIcon && (
            <Col>{expandIcon({ isActive: isActive })}</Col>
          )}
          <Col>
            <div className={"collapse-header-block"}>
              <COREHeading marginBottom={false} testID={testID} level={2}>
                {header}
              </COREHeading>
            </div>
          </Col>
        </Row>
      </Col>
      {headerSummary && !isActive && <Col span={12}>{headerSummary}</Col>}
      {!showMenu && interactionsLeft && interactionsLeft.length > 0 && (
        <Col>
          <div className={"interactions-left"}>
            <Interactions
              space={interactionSpace}
              interactions={interactionsLeft}
              showMenu={showMenu}
              isDisabled={isDisabled}
            />
          </div>
        </Col>
      )}
    </Row>
  );
};

export const COREAccordionItem: React.FC<COREAccordionItemProps> = ({
  testID,
  isOpen = true,
  interactionsLeft = [],
  interactionsRight = [],
  interactionsMenu = [],
  progress,
  collapsible,
  interactionSpace = 8,
  bannerImageUrl,
  bannerImageHeight = 220,
  noScroll = false,
  children,
  className,
  showMenuAlways = false,
  menuType = "useLeftRightForMenu",
  onShowMenu,
  expandIconSize,
  maxHeight,
  header,
  headerSummary,
  expandIcon,
  type = "container",
  roundCorner = true,
  ...props
}) => {
  let isActive = isOpen;

  if (!isActive) {
    interactionsLeft = [];
    interactionsRight = [];
    interactionsMenu = [];
  }

  const showArrow = collapsible !== "header";
  const isDisabled = collapsible === "disabled";
  const panelClass = classNames(className, "core-container-panel", {
    "show-menu-always": showMenuAlways,
    "panel-style": type === "panel",
    "round-corner-panel": roundCorner,
  });
  const [panelLeftSize, setPanelLeftSize] =
    useState<SetSizeObject>(defaultResizeValue);
  const [panelRigthSize, setPanelRightSize] =
    useState<SetSizeObject>(defaultResizeValue);
  const [panelSize, setPanelSize] = useState<SetSizeObject>(defaultResizeValue);
  const panelSizeWidth = panelSize?.width ? panelSize?.width : 0;
  const panelRigthSizeWidth = panelRigthSize?.width ? panelRigthSize?.width : 0;
  const panelLeftSizeWidth = panelLeftSize?.width ? panelLeftSize?.width : 0;
  const [interactionMaxSize, setInteractionMaxSize] = useState<number>(0);

  useEffect(() => {
    setInteractionMaxSize((prevState) =>
      Math.max(prevState, panelLeftSizeWidth + panelRigthSizeWidth)
    );
  }, [panelLeftSizeWidth, panelRigthSizeWidth]);

  const showMenu = panelSizeWidth - interactionMaxSize < 0 || showMenuAlways;

  useEffect(() => {
    onShowMenu && onShowMenu(showMenu);
  }, [onShowMenu, showMenu]);

  const stopPropagationWhenNotCollapsed = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (collapsible === "header") {
      e.stopPropagation();
    }
  };

  const headerPanel =
    collapsible === "header" ? (
      <Row onClick={stopPropagationWhenNotCollapsed}>
        <Col>
          <HeaderWithInteractions
            stopPropagationWhenNotCollapsed={stopPropagationWhenNotCollapsed}
            headerSummary={headerSummary}
            collapsible={collapsible}
            expandIcon={expandIcon}
            isActive={isActive}
            testID={testID}
            header={header}
            showMenu={showMenu}
            interactionsLeft={interactionsLeft}
            interactionSpace={interactionSpace}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
    ) : (
      <HeaderWithInteractions
        stopPropagationWhenNotCollapsed={stopPropagationWhenNotCollapsed}
        headerSummary={headerSummary}
        collapsible={collapsible}
        expandIcon={expandIcon}
        isActive={isActive}
        testID={testID}
        header={header}
        showMenu={showMenu}
        interactionsLeft={interactionsLeft}
        interactionSpace={interactionSpace}
        isDisabled={isDisabled}
      />
    );

  const extra = (
    <Space className={"space-interaction"} align="center">
      <Interactions
        isDisabled={isDisabled}
        space={interactionSpace}
        interactionsClassName={"interactions-right"}
        interactions={interactionsRight}
        menu={
          <InteractionMenuButton
            isDisabled={isDisabled}
            showMenu={showMenu}
            interactionsRight={interactionsRight}
            interactionsLeft={interactionsLeft}
            interactionsMenu={interactionsMenu}
            menuType={menuType}
            testID={testID}
          >
            <COREButton
              type={"default"}
              size={"md"}
              icon={
                <COREIcon icon={icon({ name: "bars", style: "regular" })} />
              }
              testID={`${testID}-bars-button`}
            ></COREButton>
          </InteractionMenuButton>
        }
        showMenu={showMenu}
      />
    </Space>
  );

  const headerWithExtra = (
    <OnResize onResize={setPanelSize}>
      <Row
        onClick={stopPropagationWhenNotCollapsed}
        className={"header-row"}
        gutter={[0, spacing.sm]}
        justify="space-between"
      >
        <Col {...(headerSummary && { span: 17 })}>
          <OnResize onResize={setPanelLeftSize}>{headerPanel}</OnResize>
        </Col>
        <Col>
          <OnResize onResize={setPanelRightSize}>{extra}</OnResize>
        </Col>
      </Row>
    </OnResize>
  );

  const cover = bannerImageUrl ? (
    <div
      className={classNames("image-cover", "custom-image-cover")}
      style={{
        height: bannerImageHeight,
        backgroundImage: `url(${bannerImageUrl})`,
      }}
    />
  ) : null;

  const progressBar = progress ? (
    <div className={"progress-bar"}>{progress}</div>
  ) : null;

  const bodyClassNames = classNames(
    "panel-body",
    { "with-progress-bar": progress },
    { "no-scroll": noScroll }
  );
  return (
    <TestIDWrapper testID={testID} className={"core-container-panel-block"}>
      <Collapse.Panel
        {...props}
        className={panelClass}
        data-testid={testID}
        header={headerWithExtra}
        showArrow={showArrow}
        collapsible={collapsible}
      >
        {progressBar}
        {cover}
        <div className={"panel-body-padding"}>
          <div
            className={bodyClassNames}
            style={maxHeight ? { maxHeight: maxHeight } : undefined}
          >
            {children}
          </div>
        </div>
      </Collapse.Panel>
    </TestIDWrapper>
  );
};
