import "./COREMainMenuBlock.less";
import React, { useState } from "react";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { useIsMobileScreen } from "../../shared/customHoooks/useBreakpoint";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { UseLocalStorageUntilLogoutProps } from "../../shared/customHoooks/useLocalStorageUntilLogout";
import { useUserDashboard } from "../../shared/customHoooks/useUserDashboard";
import { DashboardConfigurationModal } from "../../modules/dashboards/dashboardsComponent/addEditDeleteDasboard/DashboardConfigurationModal";
import { useUserAccessibleSections } from "../../shared/customHoooks/useUserAccessibleSections";
import { CORELoading } from "../Feedback/CORELoading";
import { UserAccessibleSection } from "../../openapi-typescript/common/user_accessible_section";
import { COREMainMenuItem, MenuItemProps } from "./COREMainMenuItem";
import { menuIcons } from "./COREMainMenuIcon";
import {
  FeatureFlag,
  useFeatureFlags,
} from "../../shared/customHoooks/useFeatureFlags";

type IsPanel = boolean;
type IsHidden = boolean;
export type COREMainMenuBlockProps = {
  testID: TestID;
  isHidden: IsHidden;
  setIsHidden: ReturnType<UseLocalStorageUntilLogoutProps<IsHidden>>[1];
  isPanel: IsPanel;
  setIsPanel: ReturnType<UseLocalStorageUntilLogoutProps<IsPanel>>[1];
};

const convertMenuData = (
  data: UserAccessibleSection[],
  features: FeatureFlag[],
  parentKey = ""
): MenuItemProps[] =>
  data
    .filter((item) => item.isShownInMenu)
    .map((item, index) => {
      const key = `${parentKey}${index + 1}`;
      if (
        item.title === "Dashboards" &&
        features.includes("dashboard-create")
      ) {
        item.children.push({
          featureFlag: "dashboard",
          icon: "createDashboard",
          isShownInMenu: true,
          title: "Create New",
          callToActionUrl: "",
          children: [],
          sortOrder: 0,
          subtitle: "",
        });
      }
      const children =
        item.children?.length > 0
          ? convertMenuData(item.children, features, `${key}-`)
          : undefined;

      return {
        key,
        label: item.title,
        url: item.callToActionUrl,
        icon: item.icon ? menuIcons(item.icon) : undefined,
        featureFlag: item.featureFlag,
        children,
      };
    });

const getInitSelectedMenu = (
  apiData: MenuItemProps[],
  currentPath: string
): string[] => {
  let bestMatchKey: string | undefined;
  let bestMatchLength = 0;

  const findBestMatch = (items: MenuItemProps[], path: string): void => {
    for (const item of items) {
      const itemUrlLength = item.url.length;

      if (
        (path === item.url || path.startsWith(item.url)) &&
        itemUrlLength > bestMatchLength
      ) {
        bestMatchLength = itemUrlLength;
        bestMatchKey = item.key;
      }

      if (item.children && item.children.length > 0) {
        findBestMatch(item.children, path);
      }
    }
  };
  findBestMatch(apiData, currentPath);
  return bestMatchKey ? [bestMatchKey] : [];
};

export const COREMainMenuBlock: React.FC<COREMainMenuBlockProps> = ({
  testID,
  isHidden,
  setIsHidden,
  isPanel = true,
  setIsPanel,
}) => {
  const isMobileScreen = useIsMobileScreen();
  const { features } = useFeatureFlags();
  const { sync, loading, userAccessibleSections } = useUserAccessibleSections();

  const {
    sync: syncDashboard,
    loading: loadingDashboard,
    configs: { dashboards },
    addDashboard,
  } = useUserDashboard();
  const currentPath = useLocation().pathname;
  const [dashboardModelVisible, setDashboardModelVisible] = useState(false);

  if ((loading && !sync) || (loadingDashboard && !syncDashboard))
    return <CORELoading size={"lg"} />;
  if (!sync || !syncDashboard) return null;
  const menuItems = convertMenuData(userAccessibleSections, features);
  const selectedKeys = getInitSelectedMenu(menuItems, currentPath);

  return (
    <TestIDWrapper
      className={classNames("main-menu-container", {
        mobile: isMobileScreen,
      })}
      testID={testID}
    >
      <COREMainMenuItem
        isMobileScreen={isMobileScreen}
        selectedKeys={selectedKeys}
        initOpenKeys={selectedKeys}
        isHidden={isHidden}
        setIsHidden={setIsHidden}
        menuItems={menuItems}
        isPanel={isPanel}
        setIsPanel={setIsPanel}
        setDashboardModelVisible={setDashboardModelVisible}
      />
      <DashboardConfigurationModal
        addDashboard={addDashboard}
        modalVisible={dashboardModelVisible}
        closeModal={() => {
          setDashboardModelVisible(false);
        }}
        userDashboards={dashboards}
      />
    </TestIDWrapper>
  );
};
