import {
  useAPIQuery as jsUseAPIQuery,
  useAPIQueryRequests as jsUseAPIQueries,
} from "./useAPI";
import moment from "moment";
import { action } from "../datum";
import { AxiosError, AxiosResponse } from "axios";

export type UseAPIQuery<Response> = (
  action: action,
  params: object,
  skip?: boolean,
  body?: string,
  customHeader?: object
) => {
  error: AxiosError | false;
  loading: boolean;
  forceRefresh: () => Promise<Response>;
  lastUpdate?: moment.Moment;
  headers: [string, string][];
} & (
  | {
      sync: true;
      data: Response;
    }
  | { sync: false; data: undefined }
);
export const useAPIQuery = <T extends any>(
  ...args: Parameters<UseAPIQuery<T>>
): ReturnType<UseAPIQuery<T>> => {
  const { data: { data } = { data: undefined }, ...other } = jsUseAPIQuery(
    ...args
  );
  return { ...other, data } as ReturnType<UseAPIQuery<T>>;
};

export type UseAPIQueriesResponse<Response> = {
  error: Error | false;
  loading: boolean;
  sync: boolean;
  forceRefresh: () => Promise<Response>;
  progress: { percent: number };
  results: ReturnType<UseAPIQuery<Response>>[];
};

type ApiOption = {
  params: object;
  skip?: boolean;
  body?: string;
  fetchOptions?: {
    method: string;
  };
  data?: Response;
};

export type UseAPIQueriesRequest = {
  action: action;
  apiOptions: ApiOption[];
};

export const useAPIQueries = <T extends any>(
  action: UseAPIQueriesRequest["action"],
  apiOptions: UseAPIQueriesRequest["apiOptions"],
  queryFn?: (
    params: [UseAPIQueriesRequest["action"], ApiOption]
  ) => Promise<AxiosResponse | undefined>
): UseAPIQueriesResponse<T> => {
  return jsUseAPIQueries(
    action,
    apiOptions,
    queryFn
  ) as UseAPIQueriesResponse<T>;
};
