import React from "react";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { COREIcon } from "../../COREDesignSystem/Content/COREIcon";
import { CORETag } from "../../COREDesignSystem/Content/CORETag";
import { Col } from "antd";
import "./DevEnvTag.less";
import { generateTestId } from "../testids/testids";

const WarningIcon = () => (
  <COREIcon size={"xxs"} icon={icon({ name: "warning", style: "solid" })} />
);

export const DevEnvTag: React.FC = () => (
  <CORETag
    label={"Dev Env"}
    type={"icon"}
    testID={generateTestId("page", "dev-env-tag")}
    colour={"purple100"}
    icon={<WarningIcon />}
  />
);

export const DevEnvTagMobileScreen: React.FC = () => (
  <Col className={"dev-env-tag"}>
    <DevEnvTag />
  </Col>
);
