import ColorHash from "color-hash";
import { ChartApiData } from "../../modules/chartBuilder/useChartBuilderApiV2";
import { Trace } from "../../modules/chartBuilder/AggregatesChartV2";
import { DotPlotTrace } from "../Chart/COREDotPlotChart";
import { BasicTrace } from "../Chart/COREBasicChart";

let colorHash = new ColorHash();
interface cityColorType {
  qld: string;
  nsw: string;
  vic: string;
  tas: string;
  sa: string;
}

interface fixedColorType {
  demand: cityColorType;
  price: cityColorType;
  generation: cityColorType;
  availability: cityColorType;
}

export type Colors = typeof allColors;
export type ColorName = keyof Colors;
export type ColorHex = Uppercase<Colors[ColorName]>;

export const indigo100 = "#242849";
export const green100 = "#00E9AC";
export const turquoise100 = "#227996";
export const grey100 = "#B9BBBB";

// Darker Tints
export const indigo120 = "#181B30";
export const indigo140 = "#0D101B";
export const indigo160 = "#05060A";
export const black = "#000";

export const green120 = "#00C290";
export const green140 = "#00A378";
export const green160 = "#008F69";

export const turquoise120 = "#1A5E74";
export const turquoise140 = "#134353";
export const turquoise160 = "#0B2832";

export const grey120 = "#979A9B";
export const grey140 = "#646768";
export const grey160 = "#3C3E3F";
export const grey180 = "#1E1F1F";
export const grey200 = "#363636";

// Lighter Tints
export const indigo80 = "#363C6D";
export const indigo60 = "#545B96";
export const indigo40 = "#7D84BF";
export const indigo20 = "#B4B7DA";
export const indigo10 = "#EAEBF5";

export const green80 = "#31FFC9";
export const green60 = "#70FFD9";
export const green40 = "#ADFFE9";
export const green20 = "#D6FFF4";
export const green10 = "#EBFFFA";

export const turquoise80 = "#2A94B7";
export const turquoise60 = "#48B2D5";
export const turquoise40 = "#8BCEE5";
export const turquoise20 = "#BCE3F0";
export const turquoise10 = "#EEF8FB";

export const grey80 = "#D8D9D9";
export const grey60 = "#E0E1E1";
export const grey40 = "#EDEDED";
export const grey20 = "#F5F5F5";
export const grey10 = "#FAFAFA";
export const white = "#FFF";

// --- Secondary ---
// Main Tint
export const blue100 = "#1669B6";
export const purple100 = "#873AC0";
export const pink100 = "#E86AF2";
export const red100 = "#D32C2C";
export const orange100 = "#FF9900";
export const yellow100 = "#FFD22E";
export const lime100 = "#73D13D";

// Darker Tints
export const blue120 = "#10497F";
export const blue140 = "#0B345B";
export const blue160 = "#082440";

export const purple120 = "#5D2885";
export const purple140 = "#35164B";
export const purple160 = "#220A34";

export const pink120 = "#D414E1";
export const pink140 = "#A210AD";
export const pink160 = "#680A6F";

export const red120 = "#A92323";
export const red140 = "#781919";
export const red160 = "#551111";

export const orange120 = "#E08700";
export const orange140 = "#B76E00";
export const orange160 = "#8F5600";

export const yellow120 = "#F5C000";
export const yellow140 = "#CCA000";
export const yellow160 = "#A38000";

export const lime120 = "#59AE29";
export const lime140 = "#407C1D";
export const lime160 = "#2F5B15";

// Lighter Tints
export const blue80 = "#1B7DD9";
export const blue60 = "#499CE9";
export const blue40 = "#89BEF0";
export const blue20 = "#C9E1F8";
export const blue10 = "#EDF5FD";

export const purple80 = "#A062D0";
export const purple60 = "#BD91DE";
export const purple40 = "#D0B0E8";
export const purple20 = "#E3D0F1";
export const purple10 = "#F6EFFA";

export const pink80 = "#EE8EF5";
export const pink60 = "#F3B0F8";
export const pink40 = "#F7C7FA";
export const pink20 = "#F9D9FC";
export const pink10 = "#FCECFD";

export const red80 = "#D94545";
export const red60 = "#DE5E5E";
export const red40 = "#EA9A9A";
export const red20 = "#F5CCCC";
export const red10 = "#FBEEEE";

export const orange80 = "#FFAD33";
export const orange60 = "#FFBE5C";
export const orange40 = "#FFD699";
export const orange20 = "#FFE7C2";
export const orange10 = "#FFF7EB";

export const yellow80 = "#FFDC5C";
export const yellow60 = "#FFE485";
export const yellow40 = "#FFE999";
export const yellow20 = "#FFF2C2";
export const yellow10 = "#FFFBEB";

export const lime80 = "#94DC6A";
export const lime60 = "#ACE48B";
export const lime40 = "#CAEEB5";
export const lime20 = "#E1F5D6";
export const lime10 = "#F3FBEE";

export const allColors = {
  indigo100,
  indigo120,
  indigo140,
  indigo160,
  black,
  green100,
  green120,
  green140,
  green160,
  turquoise100,
  turquoise120,
  turquoise140,
  turquoise160,
  grey100,
  grey120,
  grey140,
  grey160,
  grey180,
  grey200,
  indigo80,
  indigo60,
  indigo40,
  indigo20,
  indigo10,
  green80,
  green60,
  green40,
  green20,
  green10,
  turquoise80,
  turquoise60,
  turquoise40,
  turquoise20,
  turquoise10,
  grey80,
  grey60,
  grey40,
  grey20,
  grey10,
  white,
  blue100,
  purple100,
  pink100,
  red100,
  orange100,
  yellow100,
  lime100,
  blue120,
  blue140,
  blue160,
  purple120,
  purple140,
  purple160,
  pink120,
  pink140,
  pink160,
  red120,
  red140,
  red160,
  orange120,
  orange140,
  orange160,
  yellow120,
  yellow140,
  yellow160,
  lime120,
  lime140,
  lime160,
  blue80,
  blue60,
  blue40,
  blue20,
  blue10,
  purple80,
  purple60,
  purple40,
  purple20,
  purple10,
  pink80,
  pink60,
  pink40,
  pink20,
  pink10,
  red80,
  red60,
  red40,
  red20,
  red10,
  orange80,
  orange60,
  orange40,
  orange20,
  orange10,
  yellow80,
  yellow60,
  yellow40,
  yellow20,
  yellow10,
  lime80,
  lime60,
  lime40,
  lime20,
  lime10,
} as const;

type AllColors = typeof allColors;
export type AllColorName = keyof AllColors;
export type AllColorHEX = Uppercase<Colors[ColorName]> | Colors[ColorName];

export const COREElectricityChartColor = (parent, child): ColorHex => {
  const fixedColor: fixedColorType = {
    demand: {
      qld: "#008080",
      nsw: "#800000",
      vic: "#808000",
      tas: "#000075",
      sa: "#9a6324",
    },
    price: {
      qld: "#911eb4",
      nsw: "#e6194b",
      vic: "#3cb44b",
      tas: "#4363d8",
      sa: "#f58231",
    },
    generation: {
      qld: "#512E5F",
      nsw: "#B7950B",
      vic: "#F4D03F",
      tas: "#A04000",
      sa: "#CD6155",
    },
    availability: {
      qld: "#EDBB99",
      nsw: "#D2B4DE",
      vic: "#58D68D",
      tas: "#ABEBC6",
      sa: "#F5B041",
    },
  };
  const getUniqueColor = (strColor) => {
    const dupKey = Object.keys(fixedColor).find((key) =>
      Object.values(fixedColor[key]).includes(strColor)
    );
    if (dupKey !== undefined) {
      getUniqueColor(colorHash.hex(new Date().valueOf().toString()));
    } else {
      return strColor;
    }
  };
  const parentKey: string = parent.toLowerCase();
  const childKey: string = child.toLowerCase();
  let traceColor: ColorHex = getUniqueColor(
    colorHash.hex(`${parentKey}${childKey}${new Date().valueOf().toString()}`)
  );
  if (
    Object.prototype.hasOwnProperty.call(fixedColor, childKey) &&
    Object.prototype.hasOwnProperty.call(fixedColor[childKey], parentKey)
  ) {
    traceColor = fixedColor[childKey][parentKey];
  }
  return traceColor;
};

export const coreChartColor = {
  indigo100: indigo100,
  green100: green100,
  purple100: purple100,
  grey120: grey120,
  turquoise100: turquoise100,
  blue100: blue100,
  lime100: lime100,
  yellow100: yellow100,
  orange100: orange100,
  pink100: pink100,
  indigo60: indigo60,
  green60: green60,
  purple60: purple60,
  grey60: grey60,
  turquoise60: turquoise60,
  blue60: blue60,
  lime60: lime60,
  yellow60: yellow60,
  orange60: orange60,
  pink60: pink60,
} as const;

const hexColors: Uppercase<ColorHex>[] = Object.values(allColors);
const nameColors: ColorName[] = Object.keys(allColors) as ColorName[];
const nameChartColor: ColorName[] = Object.keys(coreChartColor) as ColorName[];

export const getColor = (color: number | ColorName | ColorHex): ColorHex => {
  if (typeof color === "string" && color[0] === "#") {
    return color as ColorHex;
  }
  if (typeof color === "number") {
    return getColor(nameChartColor[color % nameChartColor.length]);
  }
  return allColors[color];
};

export const getColorName = (color: ColorHex): ColorName => {
  if (typeof color === "string" && color[0] === "#") {
    const index = hexColors.findIndex(
      (h) => h.toUpperCase() === color.toUpperCase()
    );
    return nameColors[index];
  }
  return color as ColorName;
};

export const prepareChartColor = <T extends Trace | DotPlotTrace | BasicTrace>(
  trace: T[]
): T[] => {
  return trace.map((data, index) => {
    const itemColor: Trace[`line.color`] = data[`line.color`]
      ? data[`line.color`]
      : getColor(index);

    data = {
      ...data,
      "line.color": allColors[itemColor] ?? itemColor,
    };

    return data;
  });
};

export const prepareChartColorV1 = (apiData: ChartApiData[]): ChartApiData[] =>
  apiData.map((data, index) => {
    const itemColor: Trace["line.color"] = data.chartOptions["line.color"]
      ? data.chartOptions["line.color"]
      : getColor(index);

    data.chartOptions = {
      ...data.chartOptions,
      "line.color": itemColor,
    };

    return data;
  });
