import "./COREMenu.less";
import React from "react";
import { Menu, MenuItemProps } from "antd";
import { TestID } from "../../shared/testids/testids";
import classNames from "classnames";
import { CORETypographyInput } from "../Typography/CORETypographyInput";

interface COREMenuItemProps {
  testID: TestID;
  iconAlign?: "left" | "right";
  className?: string;
}

interface TitleProps {
  icon: React.ReactNode;
}

const Title: React.FC<TitleProps> = ({ icon, children }) => {
  if (icon) {
    return (
      <span>
        {children}
        <span className={"icon-right"}>{icon}</span>
      </span>
    );
  }
  return <>{children}</>;
};

export const COREMenuItem: React.FC<COREMenuItemProps & MenuItemProps> = ({
  testID,
  iconAlign = "left",
  icon,
  className,
  children,
  ...props
}) => {
  if (iconAlign === "right") {
    children = <Title icon={icon}>{children}</Title>;
    icon = null;
  }
  return (
    <Menu.Item
      {...props}
      data-testid={testID}
      icon={icon}
      className={classNames(className, "core-menu-item")}
    >
      <CORETypographyInput type={"default"}>{children}</CORETypographyInput>
    </Menu.Item>
  );
};
