import {
  Dashboard,
  DashboardOutput,
} from "../../shared/customHoooks/useUserDashboard";

export const uniqueName = async (
  value: string,
  dashboards?: Dashboard[] | DashboardOutput[],
  defaultDashboardName?: string
) => {
  if (value === undefined || value.trim() !== value || value === "") {
    throw Error("Dashboard name is required");
  }
  const newDashboardName = value.trim();
  const dashboardNames = dashboards?.map((item: Dashboard) => item.name.trim());
  if (
    dashboardNames?.includes(newDashboardName) &&
    defaultDashboardName?.trim() !== newDashboardName
  ) {
    throw Error("Name already in use. Dashboard name must be unique");
  }
};
