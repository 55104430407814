import React from "react";
import {
  CORESelect,
  CORESelectProps,
} from "../../COREDesignSystem/Form/CORESelect";
import { useDashboardTemplate } from "../customHoooks/useDashboardTemplate";

export const DashboardTemplateSelect: React.FC<CORESelectProps> = (props) => (
  <CORESelect
    size="lg"
    options={[
      { label: "Blank dashboard", value: "blank" },
      ...useDashboardTemplate(),
    ]}
    widthSize="xxl"
    placeholder="Select dashboard templates"
    {...props}
  />
);
