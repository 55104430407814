import { Form, FormItemProps } from "antd";
import React, { FC } from "react";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { CORETypographyInput } from "../Typography/CORETypographyInput";
import "./COREFormItem.less";
import classnames from "classnames";
import { Size, WidthSize } from "./COREInput";
import classNames from "classnames";
import { CORESwitch } from "./CORESwitch";
import {
  BreakpointKeys,
  useBreakpoint,
} from "../../shared/customHoooks/useBreakpoint";

type GridItemRowColumn = {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
};
type GridItemRowColumnKey = keyof GridItemRowColumn;
type GridItemOrder = Partial<Record<BreakpointKeys, number | "unset">>;

const breakpoints: BreakpointKeys[] = [
  "xxs",
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "xxl",
];

const generateGridOrderStyle = (
  screenSize: BreakpointKeys,
  gridItemOrder?: GridItemOrder
) => {
  if (gridItemOrder === undefined) {
    return undefined;
  }

  let gridStyleValue: number | "unset" | undefined = undefined;

  for (const breakpoint of breakpoints) {
    if (gridItemOrder[screenSize] || gridItemOrder[breakpoint]) {
      gridStyleValue = gridItemOrder[screenSize] || gridItemOrder[breakpoint];
    }

    if (breakpoint === screenSize) {
      break;
    }
  }

  return { order: gridStyleValue };
};

const generateGridStyle = (
  screenSize: GridItemRowColumnKey,
  type: "gridColumn" | "gridRow",
  gridItemRowColumn?: GridItemRowColumn
) => {
  if (gridItemRowColumn === undefined) {
    return undefined;
  }
  let gridStyleValue: string | undefined = undefined;
  if (gridItemRowColumn) {
    switch (screenSize) {
      case "sm":
        gridStyleValue = gridItemRowColumn[screenSize];
        break;
      case "md":
        gridStyleValue = gridItemRowColumn[screenSize] || gridItemRowColumn.sm;
        break;
      case "lg":
        gridStyleValue =
          gridItemRowColumn[screenSize] ||
          gridItemRowColumn.md ||
          gridItemRowColumn.sm;
        break;
      case "xl":
        gridStyleValue =
          gridItemRowColumn[screenSize] ||
          gridItemRowColumn.lg ||
          gridItemRowColumn.md ||
          gridItemRowColumn.sm;
        break;
      case "xxl":
        gridStyleValue =
          gridItemRowColumn[screenSize] ||
          gridItemRowColumn.xl ||
          gridItemRowColumn.lg ||
          gridItemRowColumn.md ||
          gridItemRowColumn.sm;
        break;
    }
  }
  return { [type]: gridStyleValue };
};

export type COREFormItemProps = FormItemProps & {
  testID: TestID;
  className?: string;
  marginBottom?: boolean;
  label?: React.ReactElement<typeof CORETypographyInput>;
  widthSize?: WidthSize;
  labelSize?: Size;
  tooltip?: React.ReactElement;
  indent?: "indent" | "no-indent" | "auto";
  gridItemColumn?: GridItemRowColumn;
  gridItemRow?: GridItemRowColumn;
  gridOrder?: GridItemOrder;
};

const hasRequired = (
  required: COREFormItemProps["required"],
  rules: COREFormItemProps["rules"]
) =>
  required ||
  (rules ?? []).some(
    (rule) =>
      typeof rule === "object" && "required" in rule && rule.required === true
  );

const indentComponentList = [CORESwitch];

export const COREFormItem: FC<COREFormItemProps> = ({
  testID,
  label,
  className,
  marginBottom = true,
  widthSize = "full",
  labelSize,
  tooltip,
  required,
  rules,
  indent = "auto",
  children,
  name,
  gridItemColumn = { sm: "unset" },
  gridItemRow,
  gridOrder,
  ...props
}) => {
  const classes = classnames([
    className,
    { "no-margin-bottom": !marginBottom },
    { [`form-item-width-${widthSize}`]: widthSize },
  ]);
  const firstChild = React.Children.toArray(children)[0];
  const isFirstChildInIndentList =
    React.isValidElement(firstChild) &&
    typeof firstChild.type === "function" &&
    indentComponentList.includes(firstChild.type as React.FC);
  const hasIndent =
    indent === "indent" || (indent === "auto" && isFirstChildInIndentList);

  const screenSize = useBreakpoint() as GridItemRowColumnKey;
  const gridOrderStyle = generateGridOrderStyle(screenSize, gridOrder);
  const gridRowColumnStyle = {
    ...generateGridStyle(screenSize, "gridColumn", gridItemColumn),
    ...generateGridStyle(screenSize, "gridRow", gridItemRow),
    ...gridOrderStyle,
  };
  return (
    <>
      <TestIDWrapper
        testID={`${testID}-label`}
        className={classNames("label-block", {
          "no-label": !label,
        })}
        style={gridOrderStyle}
      >
        <div className={classNames({ [labelSize ?? ""]: labelSize })}>
          {label}
          {tooltip && label && (
            <div className={"form-item-info-icon"}>{tooltip}</div>
          )}
          {hasRequired(required, rules) && label && (
            <TestIDWrapper
              testID={`${testID}-label-required`}
              className={"required-mark"}
            >
              *
            </TestIDWrapper>
          )}
        </div>
      </TestIDWrapper>
      <TestIDWrapper
        testID={testID}
        style={gridRowColumnStyle}
        className={classNames("form-item-block", "core-form-item", {
          indent: hasIndent,
        })}
      >
        <Form.Item
          {...props}
          name={name}
          className={classes}
          rules={rules}
          required={required}
        >
          {children}
        </Form.Item>
      </TestIDWrapper>
    </>
  );
};
