import { useEffect } from "react";
import { useAPIRange } from "../../shared/customHoooks/useAPI";
import { message } from "antd";
import {
  getDateFromRelativeDateRange,
  RelativeRangeValue,
} from "../../COREDesignSystem/Form/CORERangePicker";
import { Axis, By, Trace } from "./AggregatesChartV2";
import { RpcAggregatesService as RpcAggregatesServiceAdmin } from "../../openapi-typescript/admin";
import { RpcAggregatesService as RpcAggregatesServiceApproved } from "../../openapi-typescript/approved";
import { NonEmptyRange, Range } from "../../shared/date/ranges";

const { postRpcAggregates: postRpcAdmin } = RpcAggregatesServiceAdmin;
const { postRpcAggregates: postRpcApproved } = RpcAggregatesServiceApproved;

export type AggType = Exclude<
  Parameters<typeof postRpcAdmin>[0] | Parameters<typeof postRpcApproved>[0],
  undefined
>;

export type RpcByType = By; // AggType["by"];
export type RpcDateRangeType = AggType["date_range"];
export type RpcAggFuncType = AggType["agg_function"];
export type RpcAggPeriodType = AggType["agg_period"];
export type RpcSelectedItemType = AggType["selected_item"];
export type RpcTableNameType = AggType["table_name"];
export type RpcColumnNameType = AggType["column_name"];

type TreeConfiguration = {
  treeName: string;
  config: {
    key: string;
    tableName: RpcTableNameType;
    columnNames: RpcColumnNameType;
    aggFunc: RpcAggFuncType;
    axis: { label: string; side: string };
  }[];
  value: string;
};

export type AGGOptionType = { batch: string; value: string; label: string };
export type AGGFuncOptionType = { value: string; label: string };
type APIOptionType = {
  body: any;
  rangeOptions: {
    range: Range;
    interval: string;
    field: string;
  };
};
type TreeSelectionType = {
  id: number;
  traceName: string;
  variable?: string;
};
export type ChartSelect = {
  tree: string;
  aggPeriod?: RpcAggPeriodType;
  aggFunc?: RpcAggFuncType;
  dateRange: RelativeRangeValue;
  selection: TreeSelectValue;
};
export type ConvertDataType = "positive" | "negative";
export type TracesAPIConfig = {
  chartOptions: Trace;
  params: TraceParam;
};

export type TraceParamsBody = {
  by?: RpcByType;
  aggFunction: RpcAggFuncType;
  aggPeriod: RpcAggPeriodType;
  columnName: RpcColumnNameType;
  dateRange: RpcDateRangeType; //This should be string to support this value "dateRange": "[2021-09-02,2021-09-05)"
  selectedItem: RpcSelectedItemType;
  tableName: RpcTableNameType;
  includeTimesWithoutData?: boolean;
};

// Type for query parameters
type TraceParam = {
  params: TraceParamsBody;
  rangeOptions: { range: NonEmptyRange; interval: string; field: string };
};

export type AggAPI = {
  date: string;
  // eslint-disable-next-line camelcase
  x_axis_label: string | null;
  value: number;
};
export type ChartApiData = {
  data?: (AggAPI | { time: string; value: number; group: number })[];
  chartOptions: Trace;
};

type ChartResponse = {
  error: boolean;
  loading: boolean;
  progress: { percent: number };
  data: ChartApiData[] | undefined;
  aggFunc?: RpcAggFuncType;
};

export type TreeSelectValue = {
  id: number;
  traceName: string;
  variable?: string;
  aemoId?: string;
  shortName?: string;
  region?: string;
  co2eEnergySource?: number;
  aggPeriod?: RpcAggPeriodType;
  aggFunc?: RpcAggFuncType;
  order?: number;
};
export type TreeSelectionObject = {
  checkedKeys: string[];
  change: TreeSelectValue[];
  value: TreeSelectValue[];
};

export interface ChartBuilderTreeSelection {
  [key: string]: TreeSelectionObject;
}

const AxisLabelCertificate: string = "$/certificate";
const AxisVolumeCertificate: string = "Volume (Certificates)";
const AxisLeft: Axis["side"] = "left";
const AxisRight: Axis["side"] = "right";

const configTree: TreeConfiguration[] = [
  {
    treeName: "electricityTree",
    config: [
      {
        key: "price",
        tableName: "dispatch",
        columnNames: "rrp",
        aggFunc: "avg",
        axis: { label: "$/MWh", side: "left" },
      },
      {
        key: "demand",
        tableName: "dispatch",
        columnNames: "clearedsupply",
        aggFunc: "avg",
        axis: { label: "MW", side: "right" },
      },
      {
        key: "generation",
        tableName: "dispatch",
        columnNames: "dispatchablegeneration",
        aggFunc: "avg",
        axis: { label: "MW", side: "right" },
      },
      {
        key: "availability",
        tableName: "dispatch",
        columnNames: "semischedule_clearedmw",
        aggFunc: "avg",
        axis: { label: "MW", side: "right" },
      },
      {
        key: "rooftop",
        tableName: "rooftop",
        columnNames: "power",
        aggFunc: "avg",
        axis: { label: "MW", side: "right" },
      },
      {
        key: "stpasa demand",
        tableName: "stpasa",
        columnNames: "demand50",
        aggFunc: "avg",
        axis: { label: "MW", side: "right" },
      },
      {
        key: "mtpasa demand",
        tableName: "mtpasa",
        columnNames: "demand50",
        aggFunc: "avg",
        axis: { label: "MW", side: "right" },
      },
    ],
    value: "aemoId",
  },
  {
    treeName: "FCASTree",
    config: [
      {
        key: "raise 6 sec rrp",
        tableName: "dispatch",
        columnNames: "raise6secrrp",
        aggFunc: "avg",
        axis: { label: "$/MWh", side: "left" },
      },
      {
        key: "raise 60 sec rrp",
        tableName: "dispatch",
        columnNames: "raise60secrrp",
        aggFunc: "avg",
        axis: { label: "$/MWh", side: "left" },
      },
      {
        key: "raise 5 min rrp",
        tableName: "dispatch",
        columnNames: "raise5minrrp",
        aggFunc: "avg",
        axis: { label: "$/MWh", side: "left" },
      },
      {
        key: "raise reg rrp",
        tableName: "dispatch",
        columnNames: "raiseregrrp",
        aggFunc: "avg",
        axis: { label: "$/MWh", side: "left" },
      },
      {
        key: "lower 6 sec rrp",
        tableName: "dispatch",
        columnNames: "lower6secrrp",
        aggFunc: "avg",
        axis: { label: "$/MWh", side: "left" },
      },
      {
        key: "lower 60 sec rrp",
        tableName: "dispatch",
        columnNames: "lower60secrrp",
        aggFunc: "avg",
        axis: { label: "$/MWh", side: "left" },
      },
      {
        key: "lower 5 min rrp",
        tableName: "dispatch",
        columnNames: "lower5minrrp",
        aggFunc: "avg",
        axis: { label: "$/MWh", side: "left" },
      },
      {
        key: "lower reg rrp",
        tableName: "dispatch",
        columnNames: "lowerregrrp",
        aggFunc: "avg",
        axis: { label: "$/MWh", side: "left" },
      },
    ],
    value: "aemoId",
  },
  {
    treeName: "generationProfileTree",
    config: [
      {
        key: "all",
        tableName: "generation_profile_shapes",
        columnNames: "generation",
        aggFunc: "avg",
        axis: { label: "MWh", side: "right" },
      },
    ],
    value: "id",
  },
  {
    treeName: "loadProfileTree",
    config: [
      {
        key: "all",
        tableName: "load_profile_shapes",
        columnNames: "load",
        aggFunc: "avg",
        axis: { label: "MWh", side: "right" },
      },
    ],
    value: "id",
  },
  {
    treeName: "forwardPriceCurveTree",
    config: [
      {
        key: "all",
        tableName: "forward_price_curve_shapes",
        columnNames: "price",
        aggFunc: "avg",
        axis: { label: "MWh", side: "right" },
      },
    ],
    value: "id",
  },
  {
    treeName: "weatherObservationTree",
    config: [
      {
        key: "max temp",
        tableName: "weather",
        columnNames: "MAX_TEMP",
        aggFunc: "avg",
        axis: { label: "°C", side: "right" },
      },
      {
        key: "min temp",
        tableName: "weather",
        columnNames: "MIN_TEMP",
        aggFunc: "avg",
        axis: { label: "°C", side: "right" },
      },
      {
        key: "wind",
        tableName: "weather",
        columnNames: "WIND",
        aggFunc: "avg",
        axis: { label: "m/s", side: "right" },
      },
      {
        key: "solar",
        tableName: "weather",
        columnNames: "SOLAR",
        aggFunc: "avg",
        axis: { label: "W/m²", side: "right" },
      },
    ],
    value: "id",
  },
  {
    treeName: "interconnectorsTree",
    config: [
      {
        key: "import limit",
        tableName: "interconnector",
        columnNames: "importlimit",
        aggFunc: "avg",
        axis: { label: "MW", side: "right" },
      },
      {
        key: "export limit",
        tableName: "interconnector",
        columnNames: "exportlimit",
        aggFunc: "avg",
        axis: { label: "MW", side: "right" },
      },
      {
        key: "flow",
        tableName: "interconnector",
        columnNames: "mwflow",
        aggFunc: "avg",
        axis: { label: "MW", side: "right" },
      },
    ],
    value: "id",
  },
  {
    treeName: "SCADATree",
    config: [
      {
        key: "all",
        tableName: "scada",
        columnNames: "scadavalue",
        aggFunc: "avg",
        axis: { label: "MW", side: "right" },
      },
    ],
    value: "id",
  },
  {
    treeName: "productTree",
    config: [
      {
        key: "broker curve",
        tableName: "trading",
        columnNames: "curve",
        aggFunc: "avg",
        axis: { label: "$/MWh", side: "left" },
      },
      {
        key: "trades (price)",
        tableName: "trading",
        columnNames: "trade_price",
        aggFunc: "avg",
        axis: { label: "$/MWh", side: "left" },
      },
      {
        key: "trades (volume)",
        tableName: "trading",
        columnNames: "trade_volume",
        aggFunc: "avg",
        axis: { label: "Volume (MWh)", side: "right" },
      },
    ],
    value: "id",
  },
  {
    treeName: "productVWAPsTree",
    config: [
      {
        key: "vwap",
        tableName: "trading",
        columnNames: "vwap",
        aggFunc: "avg",
        axis: { label: "$/MWh", side: "left" },
      },
    ],
    value: "id",
  },
  {
    treeName: "environmentalProductTree",
    config: [
      {
        key: "broker curve",
        tableName: "trading",
        columnNames: "curve",
        aggFunc: "avg",
        axis: { label: AxisLabelCertificate, side: "right" },
      },
      {
        key: "trades (price)",
        tableName: "trading",
        columnNames: "trade_price",
        aggFunc: "avg",
        axis: { label: AxisLabelCertificate, side: "right" },
      },
      {
        key: "trades (volume)",
        tableName: "trading",
        columnNames: "trade_volume",
        aggFunc: "avg",
        axis: { label: AxisVolumeCertificate, side: "right" },
      },
      {
        key: "strip trades (volume)",
        tableName: "trading",
        columnNames: "trade_volume",
        aggFunc: "avg",
        axis: { label: AxisVolumeCertificate, side: "right" },
      },
      {
        key: "vwap",
        tableName: "trading",
        columnNames: "vwap",
        aggFunc: "avg",
        axis: { label: AxisLabelCertificate, side: "right" },
      },
      {
        key: "strip trades (price)",
        tableName: "trading",
        columnNames: "trade_price",
        aggFunc: "avg",
        axis: { label: AxisLabelCertificate, side: "right" },
      },
      {
        key: "agriculture",
        tableName: "certificate_product_premium_curves",
        columnNames: "1",
        aggFunc: "avg",
        axis: { label: AxisLabelCertificate, side: "right" },
      },
      {
        key: "energy efficiency",
        tableName: "certificate_product_premium_curves",
        columnNames: "2",
        aggFunc: "avg",
        axis: { label: AxisLabelCertificate, side: "right" },
      },
      {
        key: "industrial",
        tableName: "certificate_product_premium_curves",
        columnNames: "3",
        aggFunc: "avg",
        axis: { label: AxisLabelCertificate, side: "right" },
      },
      {
        key: "landfill gas capture",
        tableName: "certificate_product_premium_curves",
        columnNames: "4",
        aggFunc: "avg",
        axis: { label: AxisLabelCertificate, side: "right" },
      },
      {
        key: "savanna burning",
        tableName: "certificate_product_premium_curves",
        columnNames: "5",
        aggFunc: "avg",
        axis: { label: AxisLabelCertificate, side: "right" },
      },
      {
        key: "vegetation",
        tableName: "certificate_product_premium_curves",
        columnNames: "6",
        aggFunc: "avg",
        axis: { label: AxisLabelCertificate, side: "right" },
      },
      {
        key: "reforestation",
        tableName: "certificate_product_premium_curves",
        columnNames: "7",
        aggFunc: "avg",
        axis: { label: AxisLabelCertificate, side: "right" },
      },
    ],
    value: "id",
  },
];
export const aggOptions: AGGOptionType[] = [
  { batch: "quarter", value: "none", label: "All Data" },
  { batch: "quarter", value: "half-hour", label: "30 Min" },
  { batch: "quarter", value: "day", label: "Daily" },
  { batch: "quarter", value: "month", label: "Monthly" },
  { batch: "quarter", value: "quarter", label: "Quarterly" },
  { batch: "year", value: "year", label: "Yearly" },
];

export const aggFuncOptions: AGGFuncOptionType[] = [
  { value: "avg", label: "Average" },
  { value: "sum", label: "Sum" },
  { value: "max", label: "High" },
  { value: "min", label: "Low" },
  { value: "last", label: "Close" },
  { value: "first", label: "Open" },
];

export const defaultFunction: RpcAggFuncType = "avg";
export const defaultPeriod: RpcAggPeriodType = "half-hour";

const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined;
};

const getChartConfigurations = (allSelection: ChartSelect[]): Trace[] =>
  allSelection
    .sort((a, b) => {
      const orderA = a.selection.order;
      const orderB = b.selection.order;
      if (orderA === undefined) return -1;
      if (orderB === undefined) return 1;
      return orderA - orderB;
    })
    .map(
      ({
        tree: treeName,
        aggPeriod,
        aggFunc,
        dateRange,
        selection: treeSelection,
      }: ChartSelect): Trace | null => {
        const selectionData = configTree.find(
          (item) => item.treeName === treeName
        );
        if (selectionData) {
          const treeConfig = getTreeConfig(treeSelection, selectionData);

          if (Object.keys(treeConfig).length === 0) return null;

          const tableName: RpcTableNameType = treeConfig.tableName;
          const columnName: RpcColumnNameType = treeConfig.columnNames;
          const treeValue: number = selectionData.hasOwnProperty("value")
            ? treeSelection[selectionData.value]
            : treeSelection;
          const axisTitle: string = treeConfig.axis.label;
          const axisSide: Axis["side"] =
            treeConfig.axis.side === "right" ? AxisRight : AxisLeft;
          const traceName = getTraceName(treeSelection, tableName, columnName);
          return {
            aggFunction: aggFunc || treeConfig.aggFunc || defaultFunction,
            aggPeriod: aggPeriod || defaultPeriod,
            dateRange: dateRange,
            type: "scatter" as const,
            axis: {
              side: axisSide,
              title: axisTitle,
            },
            columnName: columnName,
            tableName: tableName,
            title: { text: traceName },
            name: traceName,
            selectedValue: treeValue,
          };
        } else {
          return null;
        }
      }
    )
    .filter(notEmpty);

export const generateChartSelection = (
  allSelection: ChartSelect[]
): Trace[] => {
  if (!allSelection) {
    return [];
  } else {
    return getChartConfigurations(allSelection);
  }
};

const getTraceName = (
  treeSelection: TreeSelectionType,
  tableName: RpcTableNameType | undefined,
  columnName: RpcColumnNameType | undefined
) => {
  return typeof treeSelection === "object" &&
    treeSelection.hasOwnProperty("traceName")
    ? treeSelection.traceName
    : `${tableName || "Unknown"} - ${columnName || "Unknown"}`;
};

const getTreeConfig = (
  treeSelection: TreeSelectionType,
  selectionData
): {
  key: string;
  tableName: RpcTableNameType;
  columnNames: RpcColumnNameType;
  aggFunc: RpcAggFuncType;
  axis: { label: string; side: string };
} => {
  return treeSelection.hasOwnProperty("variable")
    ? selectionData?.config.find(
        (t) => t.key === treeSelection.variable?.toLowerCase()
      ) || {}
    : selectionData?.config[0];
};
export function getChartTitle(
  selections: ChartSelect[] | undefined,
  dateRange: RelativeRangeValue
): string {
  if (!selections || selections.length <= 0) return "Chart Builder";
  let configTitle;
  let selectionTraceLabel = "";
  let hasOthers = "";
  for (let ind = 0; ind < selections.length; ind++) {
    if (ind > 1) {
      hasOthers = ` + others`;
      break;
    }
    const treeSelection = selections[ind];
    const selectionData = configTree.find(
      (item) => item.treeName === treeSelection.tree
    );
    const treeConfig = getTreeConfig(treeSelection.selection, selectionData);
    const tableName: RpcTableNameType = treeConfig.tableName;
    const columnName: RpcColumnNameType = treeConfig.columnNames;
    const traceName = getTraceName(
      treeSelection.selection,
      tableName,
      columnName
    );

    if (treeSelection.tree === "weatherObservationTree") {
      selectionTraceLabel =
        ind > 0 ? `${selectionTraceLabel},<br> ${traceName}` : `${traceName}`;
    } else {
      selectionTraceLabel =
        ind > 0 ? `${selectionTraceLabel}, ${traceName}` : `${traceName}`;
    }
  }

  const [startDate, endDate] = getDateFromRelativeDateRange(dateRange) || [];
  configTitle = `${selectionTraceLabel} from ${startDate?.format(
    "YYYY-MM-DD"
  )} to ${endDate?.format("YYYY-MM-DD")}${hasOthers}`;
  return configTitle;
}

const transformCheckedKeysV2 = (
  checkedKeys: string[],
  aggPeriod?: RpcAggPeriodType,
  aggFunction?: RpcAggFuncType
): string[] => {
  return checkedKeys.map((key) => {
    let transformedKey = key;
    if (aggPeriod && aggFunction) {
      const periodLabel: string | undefined = aggOptions.find(
        (p) => p.value === aggPeriod
      )?.label;
      const funcLabel: string | undefined = aggFuncOptions.find(
        (p) => p.value === aggFunction
      )?.label;
      if (
        periodLabel &&
        funcLabel &&
        key.search(`-${periodLabel}-${funcLabel}`) === -1
      ) {
        const currentKeys = key.split("-");
        const lastElement = currentKeys.pop();
        transformedKey = `${currentKeys.join(
          "-"
        )}-${periodLabel}-${funcLabel}-${lastElement}`;
      }
    }
    return transformedKey;
  });
};

export const transformTreeSelectV2 = (
  treeSelect: ChartBuilderTreeSelection,
  aggPeriod?: RpcAggPeriodType,
  aggFunc?: RpcAggFuncType
) => {
  Object.keys(treeSelect).forEach((key) => {
    const checkTree = treeSelect[key];
    if (checkTree.value.length > 0) {
      const transformedData: TreeSelectValue[] = checkTree.value.map((item) => {
        return {
          ...item,
          aggPeriod: item.aggPeriod
            ? item.aggPeriod
            : aggPeriod || defaultPeriod,
          aggFunc: item.aggFunc ? item.aggFunc : aggFunc || defaultFunction,
        };
      });
      treeSelect[key].change = transformedData;
      treeSelect[key].value = transformedData;
      treeSelect[key].checkedKeys = treeSelect[key].checkedKeys
        ? transformCheckedKeysV2(
            treeSelect[key].checkedKeys,
            aggPeriod || defaultPeriod,
            aggFunc || defaultFunction
          )
        : [];
    }
  });
  return treeSelect;
};

export function useChartBuilder(apiConfig: TracesAPIConfig[]): ChartResponse {
  const apiOptions: TraceParam[] = apiConfig.map((ap) => ap.params);
  const chartOptions: Trace[] = apiConfig.map((ap) => ap.chartOptions);

  const apiOptionsNew: APIOptionType[] = apiOptions.map((o) => {
    const {
      params: { includeTimesWithoutData, by, ...otherParams },
    } = o;

    return {
      rangeOptions: o.rangeOptions,
      body: {
        includeTimesWithoutData: includeTimesWithoutData ?? false,
        ...(by !== undefined && { by: `by=${by}` }),
        ...otherParams,
      },
    };
  });

  const { results, progress, forceRefresh } = useAPIRange(
    "aggregates",
    apiOptionsNew
  );

  const { error, loading } = results;
  const responses = results.map(
    ({
      data,
      apiOption: {
        body: { aggFunction },
      },
    }) => {
      return { data, aggFunction };
    }
  );

  if (!loading && error) {
    console.error(error);
    message.error(
      "There was a error loading a chart. A refresh might fix the problem."
    );
  }

  useEffect(() => {
    const timer = setTimeout(() => forceRefresh(), 5 * 60 * 1000);
    return () => clearTimeout(timer);
  }, [forceRefresh]);

  return {
    error,
    loading,
    progress,
    data: responses.map((r, i) => ({
      data: r ? r.data : undefined,
      chartOptions: chartOptions[i],
      aggFunc: r ? r.aggFunction : undefined,
    })),
  };
}
