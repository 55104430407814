import { Dropdown, DropDownProps, MenuProps } from "antd";
import React, { cloneElement, useState } from "react";
import { TestID } from "../../shared/testids/testids";
import { COREMenu } from "../Navigation/COREMenu";
import { MenuInfo } from "rc-menu/lib/interface";
import "./COREDropdownMenu.less";
import "../Navigation/COREMenu.less";
import classNames from "classnames";
import {
  COREMenuItemGroup,
  COREMenuItemGroupProps,
} from "../Navigation/COREMenuItemGroup";

export type DropdownMenuItem =
  | {
      isGroupMenu?: never;
      groupHeaderProps?: never;
      component: React.ReactElement;
      closeWhenClick: boolean;
    }
  | {
      isGroupMenu: true;
      groupHeaderProps: Omit<COREMenuItemGroupProps, "testID">;
      component: React.ReactElement[];
      closeWhenClick: boolean;
    };

export const COREDropdownMenu: React.FC<{
  interactionsMenu: DropdownMenuItem[];
  testID: TestID;
  isDisabled: boolean;
  selectedKeys?: MenuProps["selectedKeys"];
  placement?: DropDownProps["placement"];
  onClickEvent?: (key: MenuInfo["key"]) => void;
  overlayStyle?: DropDownProps["overlayStyle"];
  className?: string;
}> = ({
  interactionsMenu,
  testID,
  isDisabled,
  selectedKeys,
  onClickEvent,
  placement,
  className,
  overlayStyle,
  children,
}) => {
  const [visiblePopup, setVisiblePopup] = useState(false);
  const handleVisibleChange = (visible: boolean) => {
    setVisiblePopup(visible);
  };
  const handleOnClickOption = (
    info: MenuInfo,
    closeWhenClick: boolean = true
  ) => {
    handleVisibleChange(!closeWhenClick);
    onClickEvent && onClickEvent(info.key);
    info.domEvent.stopPropagation();
  };

  const menuItems = interactionsMenu?.map((interaction) => {
    if (interaction.isGroupMenu) {
      const items = interaction.component.map((c) => {
        return cloneElement(c, {
          testID: (testID + "-menu-item") as TestID,
          ...c.props,
          onClick: (info: MenuInfo) => {
            c.props.onClick && c.props.onClick();
            handleOnClickOption(info, true);
          },
        });
      });

      return (
        <COREMenuItemGroup
          key={testID + "-portfolio-options-title"}
          {...interaction.groupHeaderProps}
          testID={(testID + "-portfolio-options-title") as TestID}
        >
          {items}
        </COREMenuItemGroup>
      );
    } else {
      return cloneElement(interaction.component, {
        testID: (testID + "-menu-item") as TestID,
        ...interaction.component.props,
        onClick: (info: MenuInfo) => {
          interaction.component.props.onClick &&
            interaction.component.props.onClick();
          handleOnClickOption(info, interaction.closeWhenClick);
        },
      });
    }
  });

  const menu: React.ReactElement<typeof COREMenu> = (
    <COREMenu testID={(testID + "-menu") as TestID} selectedKeys={selectedKeys}>
      {menuItems}
    </COREMenu>
  );

  return (
    <div className={"core-dropdown-menu"}>
      <Dropdown
        overlayClassName={classNames(
          "core-menu-popup-container",
          "panel-dropdown-popup",
          className
        )}
        overlayStyle={overlayStyle}
        overlay={menu}
        onVisibleChange={handleVisibleChange}
        visible={visiblePopup}
        trigger={["click"]}
        disabled={isDisabled}
        placement={placement}
      >
        {children}
      </Dropdown>
    </div>
  );
};
