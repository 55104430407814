import { useUserCompanyId, useUserRole } from "../state/user";
import { hasDownloadFeatureForTrees } from "../../modules/chartBuilder/AggregatesChart";
import { ChartSelect } from "../../modules/chartBuilder/useChartBuilderApiV2";
import { useCompanies } from "./useCompanies";
import { UserScopes } from "../../openapi-typescript/common/user_scopes";
import { CompanyFeatures } from "../../openapi-typescript/common/company_features";

type NoFeatureFlag = "noFeatureFlagRequired";
export type FeatureFlag = UserScopes | CompanyFeatures["feature"];

type Link = string;
export interface Page {
  link: Link;
  feature: FeatureFlag | NoFeatureFlag;
  name: string;
  icon: string;
  child?: Page[];
}

export const useDownloadCsvButtonFeature = (
  allSelections: ChartSelect[] | undefined
) => {
  const { sync, loading, features: featureList } = useFeatureFlags();
  const trees = allSelections?.map(({ tree, selection: { variable } }) => ({
    tree,
    variable,
  }));
  const isLoading = loading && !sync;
  return !isLoading && allSelections
    ? hasDownloadFeatureForTrees(trees, featureList)
    : false;
};

export const pathToFeature = (path: string) => path.replace("/", "").split("/");

export const useFeatureFlags = (): {
  loading: boolean;
  sync: boolean;
  error?: Error | false;
  features: FeatureFlag[];
} => {
  const isPendingRole = useUserRole() === "pending";
  const companyId = useUserCompanyId();
  const { loading, error, sync, updatable } = useCompanies(companyId);

  const scopes = updatable?.[0]?.data?.scopes?.map((scope) => {
    return scope.scope as FeatureFlag;
  });

  const features = updatable?.[0]?.data?.features?.map((feature) => {
    return feature.feature as FeatureFlag;
  });

  const data = [...(features ?? []), ...(scopes ?? [])];

  return {
    error: error,
    loading: loading,
    sync: sync,
    features: isPendingRole ? [] : data,
  };
};

export const productScopesPattern = /^core\.product\.([a-zA-Z0-9_-]+)\.read$/;

export const useProductScopes = () => {
  const { error, loading, sync, features } = useFeatureFlags();
  const isLoading = loading && !sync;
  let scopes: FeatureFlag[] = [];
  if (!isLoading) {
    scopes = features.filter((item) => productScopesPattern.test(item));
  }
  return {
    error: error,
    loading: loading,
    sync: sync,
    scopes,
  };
};

export const useGetMenuPermission = (featureList: FeatureFlag[]) => {
  const { sync, loading, error, features } = useFeatureFlags();
  const availableFeatures = features.filter((element) =>
    featureList.includes(element)
  );
  return {
    sync,
    loading,
    error,
    isHavePermission: availableFeatures.length > 0,
    availableFeatures,
  };
};
