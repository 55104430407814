import React from "react";
import { Result } from "antd";
import { Link } from "react-router-dom";
import { ResultStatusType } from "antd/lib/result";
import { COREButton } from "../Action/COREButton";
import { generateTestId } from "../../shared/testids/testids";
import { COREIcon } from "./COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

export const COREError: React.FC<{
  title?: string;
  extra?: string;
  status?: ResultStatusType;
  path?: string;
  onClick?: () => void;
}> = ({
  title = "An unknown error occurred. Refreshing the page might help.",
  extra = "The most likely cause is a required site update. Refreshing the page will resolve this.",
  status = "warning",
  path = "/",
  onClick,
  ...otherProps
}) => {
  return (
    <Result
      status={status}
      title={title}
      extra={[
        <div key={"bnt-home-block"} className="bnt-home-block">
          <Link to={path}>
            <COREButton
              testID={generateTestId("page", "core-error-back-to-home-btn")}
              danger
              icon={
                <COREIcon
                  icon={icon({ name: "arrow-left", style: "regular" })}
                  size="xs"
                />
              }
              type="primary"
              onClick={onClick}
            >
              BACK TO HOME
            </COREButton>
          </Link>
        </div>,
      ]}
      className="core-result-error"
      {...otherProps}
    />
  );
};
