import { useQuery } from "react-query";

export const fetchVersionFromIndexHtml = async () => {
  const response = await fetch("/index.html");
  return response.headers.get("X-FE-VERSION");
};

export const isVersionValid = (value: string | undefined | null): boolean => {
  return value !== undefined && value !== null && value.trim() !== "";
};

export const CURRENT_VERSION = process.env.REACT_APP_TAG;
const aSec = 1_000;
const aMin = 60 * aSec;
const twoMins = 2 * aMin;
export const useCheckFEVersionHasChanged = () => {
  const { data: version } = useQuery<string | null>(
    "getVersion",
    fetchVersionFromIndexHtml,
    {
      cacheTime: 0,
      initialData: CURRENT_VERSION,
      refetchInterval: twoMins,
    }
  );

  return (
    version !== CURRENT_VERSION &&
    isVersionValid(CURRENT_VERSION) &&
    isVersionValid(version)
  );
};
