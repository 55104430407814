import "./COREMenu.less";
import React from "react";
import { Menu } from "antd";
import { TestID } from "../../shared/testids/testids";
import classNames from "classnames";
import { MenuItemGroupProps } from "antd/lib/menu";
import { COREBody } from "../Typography/COREBody";
import { grey120 } from "../Content/COREColour";

export type COREMenuItemGroupProps = {
  testID: TestID;
  className?: string;
} & MenuItemGroupProps;

export const COREMenuItemGroup: React.FC<COREMenuItemGroupProps> = ({
  testID,
  className,
  children,
  title,
  ...props
}) => {
  return (
    <Menu.ItemGroup
      {...props}
      title={
        <COREBody type={"p3"} marginBottom={false} color={grey120}>
          {title}
        </COREBody>
      }
      data-testid={testID}
      className={classNames(className, "core-menu-item-group")}
    >
      {children}
    </Menu.ItemGroup>
  );
};
