import {
  APIResponse,
  TableOptions,
  UpdatableItem,
  Updatables,
  UpdatablesHook,
  useUpdatable,
  useUpdatables,
} from "../../../shared/customHoooks/useUpdatable";
import { Scenario, ScenarioAPI, ScenarioWSData } from "./scenarios_helper";
import { keysToCamelCase, keysToSnake } from "../../../shared/global";
import { QueryClient, useQueryClient } from "react-query";
import { mutation } from "../../../shared/state/appQueryClient";
import { Job, useJobs } from "./useJobs";
import { QueryFilters } from "react-query/types/core/utils";
import { WebsocketMessage } from "../../../shared/state/websocket_helper";

type UpdateField = Partial<
  Record<
    keyof Scenario,
    (newValue: Scenario[keyof Scenario]) => Promise<APIResponse<Response>>
  >
>;

type CopyScenario = ({
  newName,
  generate,
}: {
  newName: string;
  generate?: boolean;
}) => Promise<APIResponse<Job>>;

export type ScenarioParams = Partial<{
  id: number;
  search: string;
  scenarioType: Scenario["scenarioType"][];
  scenarioStatus: Scenario["status"][];
  isCreator: Scenario["isCreator"];
  order: string;
  skip: boolean;
}>;

export type ScenarioItemAction = {
  delete: () => Promise<APIResponse<Scenario>>;
  data: Scenario;
  updateField: UpdateField;
  resetScenario: () => Promise<APIResponse<null>>;
  prepare: () => Promise<APIResponse<null>>;
  updateScenario: () => Promise<APIResponse<null>>;
  setStale: () => Promise<APIResponse<null>>;
  copyScenario: CopyScenario;
};

export type UseScenarioItems = Omit<
  UpdatablesHook<Scenario, Scenario[], Scenario, Scenario, Scenario, Scenario>,
  "updatable"
> & {
  updatables: ScenarioItemAction[];
};

type UseScenarioItem = Omit<UseScenarioItems, "updatables"> & {
  updatable: ScenarioItemAction | undefined;
};

const orderByString = (order: ScenarioParams["order"]) => {
  return {
    ...(order
      ? {
          order: order,
        }
      : {
          order: "modified_at.desc",
        }),
  };
};

const buildScenarioParams = ({ ...args }: ScenarioParams) => {
  if (args.id)
    return {
      id: `eq.${args.id}`,
    };

  const search = args.search && { name: `ilike.*${args.search}*` };
  const scenarioType = args.scenarioType &&
    args.scenarioType.length > 0 && {
      scenarioType: `in.(${args.scenarioType})`,
    };
  const scenarioStatus =
    args.scenarioStatus && args.scenarioStatus.length > 0
      ? {
          status: `in.(${args.scenarioStatus})`,
        }
      : {
          status: `not.eq.Private draft`,
        };
  const isCreator = args.isCreator !== undefined && {
    isCreator: `eq.${args.isCreator}`,
  };

  return keysToSnake({
    ...search,
    ...scenarioType,
    ...scenarioStatus,
    ...isCreator,
    ...orderByString(args.order),
  });
};

const scenarioUpdatableHelpers = (
  args?: ScenarioParams
): Updatables<Scenario, ScenarioAPI, Scenario[]> => ({
  get: [
    "scenarios",
    {
      select:
        "*,created_user:users!scenario_created_user_fkey(id,name),generated_user:users!scenario_generated_user_fkey(id,name)",
      ...(args && buildScenarioParams(args)),
    },
    args?.skip,
  ],
  add: undefined,
  delete: (oldItem) => ({
    queryKey: [
      "deleteScenarios",
      {
        params: { id: `eq.${oldItem.id}` },
        action: "deleteScenarios",
        enabled: true,
      },
    ],
  }),
  update: (newItem: Scenario, oldItem: Scenario) => ({
    queryKey: [
      "updateScenarios",
      {
        params: { id: `eq.${oldItem.id}` },
        action: "updateScenarios",
        body: JSON.stringify({
          ...newItem,
          ...keysToSnake({ isOutOfDate: true }),
        }),
        enabled: true,
      },
    ],
  }),
  transformToRichTypes: (record): Scenario => {
    const camelScenario = keysToCamelCase(record);
    return {
      ...camelScenario,
      createdByName: camelScenario.createdUser.name,
      preparedAt:
        camelScenario.preparedAt === null
          ? null
          : new Date(camelScenario.preparedAt),
      generatedAt:
        camelScenario.generatedAt === null
          ? null
          : new Date(camelScenario.generatedAt),
    };
  },
});

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];
const getEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Entries<T>;

const generateScenarioActions = (
  queryClient: QueryClient,
  updatable: UpdatableItem<Scenario, Scenario, Scenario, Scenario> | undefined,
  jobs: (Job | undefined)[]
): ScenarioItemAction | undefined => {
  if (!updatable || !updatable.data) return undefined;
  const { data, update, delete: deleter } = updatable;
  const updateField = Object.fromEntries(
    getEntries(data).map(([key]) => {
      return [
        key as keyof Scenario,
        (newValue: Scenario[keyof Scenario]) => {
          queryClient.setQueriesData(
            {
              // @ts-ignore
              predicate: (query) => query.queryKey[0].startsWith(key),
            },
            (current) => {
              const { data: scenariosData } = current as {
                data: Scenario[];
              } & Object;
              return {
                ...(current as Object),
                data: scenariosData.map((d) =>
                  d.id === data.id ? { ...d, [key]: newValue } : d
                ),
              };
            }
          );
          return update(keysToSnake({ [key]: newValue }));
        },
      ];
    })
  );
  const setStale = async () => {
    return await mutation<APIResponse<null>>({
      queryKey: [
        "updateScenarios",
        {
          action: "updateScenarios",
          params: { id: `eq.${data.id}` },
          body: JSON.stringify(keysToSnake({ isOutOfDate: true })),
          enabled: true,
        },
      ],
    });
  };
  const resetScenario = async () => {
    return await mutation<APIResponse<null>>({
      queryKey: [
        "resetScenarios",
        {
          action: "resetScenarios",
          body: JSON.stringify(keysToSnake({ scenarioId: data.id })),
          enabled: true,
        },
      ],
    });
  };
  const copyScenario: CopyScenario = async ({ newName, generate }) => {
    return await mutation<APIResponse<Job>>({
      queryKey: [
        "copyScenarios",
        {
          action: "copyScenarios",
          body: JSON.stringify(
            keysToSnake({
              sourceScenarioId: data.id,
              newName: newName,
              generate: generate ?? true,
            })
          ),
          enabled: true,
        },
      ],
    });
  };
  const prepare = async () => {
    return await mutation<APIResponse<null>>({
      queryKey: [
        "updateScenariosPrepare",
        {
          action: "updateScenariosPrepare",
          body: JSON.stringify(keysToSnake({ scenarioId: data.id })),
          enabled: true,
        },
      ],
    });
  };
  const updateScenario = async () => {
    return await mutation<APIResponse<null>>({
      queryKey: [
        "updateScenariosGenerate",
        {
          action: "updateScenariosGenerate",
          body: JSON.stringify(keysToSnake({ scenarioId: data.id })),
          enabled: true,
        },
      ],
    });
  };
  return {
    delete: deleter,
    data: {
      ...data,
      generationJobDetails:
        jobs?.find((j) => {
          if (j === undefined) return undefined;
          return j.id === data.generationJob;
        }) ?? null,
      preparationJobDetails:
        jobs?.find((j) => {
          if (j === undefined) return undefined;
          return j.id === data.preparationJob;
        }) ?? null,
    },
    updateField,
    resetScenario,
    updateScenario,
    copyScenario,
    prepare,
    setStale,
  };
};

export const useScenariosAction = (
  args?: ScenarioParams,
  tableOption?: TableOptions
): UseScenarioItems => {
  const queryClient = useQueryClient();
  const { loading, sync, error, updatable, ...rest } = useUpdatables<
    Scenario,
    ScenarioAPI,
    Scenario[]
  >(scenarioUpdatableHelpers(args), tableOption);

  const { error: errorJobs, jobs } = useJobs(
    updatable.flatMap((updatable) => {
      return [
        updatable.data?.generationJob ?? null,
        updatable.data?.preparationJob ?? null,
      ];
    })
  );

  return {
    loading,
    sync,
    error: error || errorJobs,
    updatables: updatable.flatMap((updatable) => {
      const result = generateScenarioActions(queryClient, updatable, jobs);
      return result && updatable.sync
        ? {
            ...updatable,
            ...result,
          }
        : [];
    }),
    ...rest,
  };
};

export const useScenarioAction = (args?: ScenarioParams): UseScenarioItem => {
  const queryClient = useQueryClient();
  const { loading, sync, error, updatable, ...rest } = useUpdatable<
    Scenario,
    ScenarioAPI,
    Scenario[]
  >(scenarioUpdatableHelpers(args));

  const { error: errorJobs, jobs } = useJobs([
    updatable?.data?.generationJob ?? null,
    updatable?.data?.preparationJob ?? null,
  ]);

  return {
    loading,
    sync,
    error: error || errorJobs,
    updatable: generateScenarioActions(queryClient, updatable, jobs),
    ...rest,
  };
};

const mapWSToRest = (
  wsRow: ScenarioWSData,
  baseRestData: ScenarioAPI | null
): ScenarioAPI => {
  if (baseRestData === null) {
    return {
      ...wsRow,
      createdUser: { id: wsRow.createdUser, name: "" },
      generatedUser:
        wsRow.generatedUser === null
          ? null
          : { id: wsRow.generatedUser, name: "" },
      preparationUser:
        wsRow.preparationUser === null
          ? null
          : { id: wsRow.preparationUser, name: "" },
    };
  }
  return {
    ...wsRow,
    createdUser: {
      id: wsRow.createdUser,
      name: baseRestData.createdUser.name,
    },
    generatedUser:
      wsRow.generatedUser === null || baseRestData.generatedUser == null
        ? null
        : {
            id: wsRow.generatedUser,
            name: baseRestData.generatedUser.name,
          },
    preparationUser:
      wsRow.preparationUser === null || baseRestData.preparationUser == null
        ? null
        : {
            id: wsRow.preparationUser,
            name: baseRestData.preparationUser.name,
          },
  };
};
export const scenariosHandler = (
  data: WebsocketMessage<ScenarioWSData>,
  queryClient: QueryClient
) => {
  if (!data.channel.startsWith("scenario-")) return;
  const key = "scenarios";
  const operation = data.payload.operation;
  const row: ScenarioWSData = data.payload.row;
  const queryFilter: QueryFilters = {
    predicate: (query) => (query.queryKey[0] as string).startsWith(key),
  };
  queryClient.setQueriesData(
    queryFilter,
    (current: { data: ScenarioAPI[] } | undefined) => {
      const curData: ScenarioAPI[] =
        current && current.data ? keysToCamelCase(current?.data) : [];
      const newData: ScenarioAPI[] =
        operation === "INSERT"
          ? [...curData, mapWSToRest(row, null)]
          : curData.map(
              (d): ScenarioAPI => (d.id === row.id ? mapWSToRest(row, d) : d)
            );
      return {
        ...current,
        data: newData,
      };
    }
  );
  queryClient.invalidateQueries(queryFilter);
};
