import { QueryClient } from "react-query";
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from "axios";
import { DEFAULT_REQUEST_PRIORITY, domain, ENDPOINTS } from "./rest";
import { urlTransform } from "../urlUtil";
import PriorityQueue from "javascript-priority-queue";
import { logout } from "./user";
import { defaultStrToInt } from "../global";

export type Action = keyof typeof ENDPOINTS;
export type QueryPrefetch = {
  queryKey: Action;
  action: Action;
  params?:
    | { me: string; type: string }
    | { limit: number; offset: number }
    | { in_nem: string } // eslint-disable-line camelcase
    | null;
};

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const MAX_REQUESTS_COUNT: number = defaultStrToInt(
  localStorage.getItem("max_requests_count"),
  10
);

let PENDING_REQUESTS = 0;

const axiosRequest = axios.create();

const overflowQueue = new PriorityQueue("min");

axiosRequest.interceptors.request.use(
  (config) =>
    new Promise((resolve, reject) => {
      overflowQueue.enqueue(() => {
        resolve(config);
      }, DEFAULT_REQUEST_PRIORITY);
      maybeRunNextRequest();
    })
);
const maybeRunNextRequest = () => {
  if (PENDING_REQUESTS >= MAX_REQUESTS_COUNT) {
    return;
  }
  if (overflowQueue.size() <= 0) {
    // requestFinished, and nothing in the queue. Job done.
    return;
  }
  //requestFinished, so triggering next in the queue
  const triggerNextRequest = overflowQueue.dequeue();
  PENDING_REQUESTS++;
  triggerNextRequest();
};

const handleResponse = () => {
  PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
  maybeRunNextRequest();
};

axiosRequest.interceptors.response.use(
  (response) => {
    handleResponse();
    return Promise.resolve(response);
  },
  (error) => {
    handleResponse();
    return Promise.reject(error);
  }
);

const sessionExpired = (error: AxiosError) =>
  error.isAxiosError && error?.response?.status === 401;

const makeRequest: <T extends unknown>(
  fetchOptions,
  finalUrl,
  source: CancelTokenSource,
  headers,
  body,
  action
) => Promise<AxiosResponse<T>> = (
  fetchOptions,
  finalUrl,
  source: CancelTokenSource,
  headers,
  body,
  action
) => {
  const isNotGet =
    fetchOptions.method && fetchOptions?.method?.toLowerCase() !== "get";
  const isPost =
    fetchOptions.method && fetchOptions?.method?.toLowerCase() === "post";
  const isPostAggregates = isPost && finalUrl.includes("aggregates");
  const shouldSkipRateLimit = isNotGet && !isPostAggregates;

  if (shouldSkipRateLimit) {
    return axios.request({
      // Pass the source token to your request
      cancelToken: source.token,
      withCredentials: true,
      headers,
      method: fetchOptions.method.toLowerCase(),
      url: finalUrl,
      data: body,
    });
  }

  const method = fetchOptions.method?.toLowerCase() ?? "get";
  const maybeBody = method === "get" ? {} : { data: body };
  return axiosRequest
    .request({
      method,
      // Pass the source token to your request
      cancelToken: source.token,
      withCredentials: true,
      url: finalUrl,
      headers,
      ...maybeBody,
    })
    .then((res) => {
      if (ENDPOINTS[action].hasOwnProperty("transformer")) {
        res.data = ENDPOINTS[action].transformer(res.data);
      }
      return res;
    });
};

export const defaultQueryFn = ({ queryKey }): Promise<AxiosResponse> | void => {
  // Create a new CancelToken source for this request
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const {
    action,
    fetchOptions: fo = {},
    params,
    body,
    enabled,
    customHeader,
  }: any = queryKey[1];
  const actionName: string = action;
  let endpoint: any = ENDPOINTS;
  endpoint = endpoint[actionName];
  const { url, options } = endpoint;

  let fetchOptions = { ...options, ...fo };
  const fetchOptionsHeaders = fetchOptions.headers ?? {};
  const headers = {
    ...defaultHeaders,
    ...fetchOptionsHeaders,
    ...customHeader,
  };

  const finalUrl = domain() + urlTransform(url, params);

  if (!enabled) {
    return;
  }

  let promise = makeRequest(
    fetchOptions,
    finalUrl,
    source,
    headers,
    body,
    action
  );

  // Cancel the request if React Query calls the `promise.cancel` method

  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };

  return promise.catch((error: AxiosError) => {
    if (sessionExpired(error)) {
      logout();
    }
    //has no error.response
    throw error;
  });
};

export const appQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      staleTime: 5 * 60000,
    },
  },
});

export const mutation = <T>(options, ...other): Promise<T> =>
  appQueryClient.fetchQuery<T>(
    { staleTime: 0, cacheTime: 0, ...options },
    ...other
  );

export const addMutation = async <T extends any, Value = T>(
  queryKey: Action,
  values: Value
) =>
  await mutation<AxiosResponse<T>>({
    queryKey: [
      queryKey,
      {
        action: queryKey,
        body: JSON.stringify(values),
        enabled: true,
      },
    ],
  });

export const deleteMutation = async <T>(
  queryKey: Action,
  id: number | string,
  deleteKey?: string
) =>
  await mutation<AxiosResponse<T>>({
    queryKey: [
      queryKey,
      {
        params: { [deleteKey ?? "id"]: `eq.${id}` },
        action: queryKey,
        enabled: true,
      },
    ],
  });
