import React from "react";
import { COREIcon, COREIconProps } from "./COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./CORESpinner.less";

export type CORESpinnerSize = Exclude<
  COREIconProps["size"],
  "xxs" | "xs" | "xl" | "xxl" | undefined
>;

export type CORESpinnerProps = {
  spinIcon?: COREIconProps["icon"];
  size?: CORESpinnerSize;
  color?: COREIconProps["color"];
};

export const CORESpinner: React.FC<CORESpinnerProps> = ({
  spinIcon = icon({ name: "spinner-third", style: "solid" }),
  color,
  size,
}) => (
  <COREIcon
    className={"core-spinner"}
    icon={spinIcon}
    animate={"spin"}
    size={size}
    {...(color && { color: color, style: { opacity: 0.5 } })}
  />
);
