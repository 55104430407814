import React, { useState } from "react";
import { LoginOutlined, MenuOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { generateTestId, TestIDWrapper } from "../../shared/testids/testids";
import { ModalFeedback } from "../../modules/pages/feedback/ModalFeedback";
import { APIProgress } from "../../shared/progress/APIProgress";
import { UserDrawer } from "../../shared/drawers/UserDrawer";
import { BreadcrumbItem, COREBreadcrumb } from "./COREBreadcrumb";
import { COREButton } from "../Action/COREButton";
import { CORELogo } from "../Content/CORELogo";
import "./CORETopHeader.less";
import classnames from "classnames";
import { useIsMobileScreen } from "../../shared/customHoooks/useBreakpoint";
import { isDevEnv } from "../../shared/state/rest";
import { DevEnvTag, DevEnvTagMobileScreen } from "../../shared/tags/DevEnvTag";

const HeaderLogo = (): JSX.Element => {
  return (
    <TestIDWrapper
      testID={generateTestId("page", "core-top-header-logo")}
      className={"logo-image"}
    >
      <CORELogo />
    </TestIDWrapper>
  );
};

export const CORETopHeader: React.FC<{
  isPublicPage?: boolean;
  toggleMobileMenu?: () => void;
  breadcrumbs?: BreadcrumbItem[];
}> = ({ isPublicPage = false, toggleMobileMenu, breadcrumbs }) => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);
  const isMobileScreen = useIsMobileScreen();

  return (
    <TestIDWrapper
      testID={generateTestId("page", "core-top-header-component")}
      className={"core-top-header"}
    >
      <Row
        align={"middle"}
        className={classnames(["top-header-row", { "dev-env": isDevEnv() }])}
      >
        {!isPublicPage && (
          <Col
            className={classNames(
              "mobile-hamburger-icon-block",
              "mobile-hamburger-icon"
            )}
            onClick={toggleMobileMenu}
          >
            <TestIDWrapper
              testID={generateTestId("page", "core-top-header-hamburger-menu")}
            >
              <MenuOutlined />
            </TestIDWrapper>
          </Col>
        )}

        <Col className={classNames("top-header-logo", "top-header-logo-block")}>
          <Link to={"/"}>
            <HeaderLogo />
          </Link>
        </Col>

        {isDevEnv() && !isMobileScreen && <DevEnvTagMobileScreen />}

        {!isPublicPage ? (
          <>
            <Col flex={"auto"} className={"breadcrumb-top-header-block"}>
              <COREBreadcrumb
                testID={"page-header-title"}
                breadcrumbs={breadcrumbs}
              />
            </Col>

            <Col className={"user-panel-top-header-block"}>
              <UserDrawer />
            </Col>
          </>
        ) : (
          <Col flex={"auto"}>
            <div className={"public-login-block"}>
              <COREButton type={"link"}>
                <LoginOutlined className={"login-icon"} />
                LOG IN
              </COREButton>
            </div>
          </Col>
        )}

        <ModalFeedback
          isFeedbackModalOpen={isFeedbackModalOpen}
          setIsFeedbackModalOpen={setIsFeedbackModalOpen}
        />
      </Row>
      <div className={"top-header-progress-bar"}>
        <div className={"top-header-progress-bar-block"}>
          <APIProgress />
        </div>
        <div
          className={classnames([
            "top-header-logo-border",
            { "dev-env": isDevEnv() },
          ])}
        />
      </div>
      <Row className={"header-mobile-size-block"}>
        <Col className={"top-header-logo"}>
          <Link to={"/"}>
            <HeaderLogo />
          </Link>
        </Col>
        {isDevEnv() && isMobileScreen && (
          <Col>
            <DevEnvTag />
          </Col>
        )}
      </Row>
    </TestIDWrapper>
  );
};
