import { Company, useUpdatableCompanies } from "./useUpdatableCompanies";
import { addMutation, deleteMutation } from "../state/appQueryClient";
import { UserScopes } from "../../openapi-typescript/common/user_scopes";
import { CompanyFeatures } from "../../openapi-typescript/common/company_features";
import { keysToSnake } from "../global";

type AddScopesProps = {
  scope: UserScopes;
  company: Company["id"];
}[];

type AddFeatureProps = {
  feature: CompanyFeatures["feature"];
  company: Company["id"];
}[];

type AddSubscriptionPackagesProps = {
  company: Company["id"];
  subscriptionPackage: number;
}[];

type AddRenewalSubscriptionPackagesProps = {
  company: Company["id"];
  renewalSubscriptionPackage: number;
}[];

export const useCompanies = (id?: Company["id"]) => {
  const updatables = useUpdatableCompanies({ id });

  const addScopes = async (scopeParams: AddScopesProps) =>
    await addMutation("addScopesCompany", scopeParams);

  const deleteScopes = async (companyID: Company["id"]) =>
    await deleteMutation("deleteScopesCompany", companyID, "company");

  const addFeatures = async (featureParams: AddFeatureProps) =>
    await addMutation("addFeatureCompany", featureParams);

  const deleteFeatures = async (companyID: Company["id"]) =>
    await deleteMutation("deleteFeatureCompany", companyID, "company");

  const addSubscriptionPackages = async (
    subscriptionParams: AddSubscriptionPackagesProps
  ) =>
    addMutation(
      "addCompanySubscriptionPackages",
      keysToSnake(subscriptionParams)
    );
  const deleteSubscriptionPackages = async (companyID: Company["id"]) =>
    deleteMutation("deleteCompanySubscription", companyID, "company");
  const addRenewalSubscriptionPackages = async (
    renewalSubscriptionParams: AddRenewalSubscriptionPackagesProps
  ) =>
    addMutation(
      "addCompanySubscriptionPackages",
      keysToSnake(renewalSubscriptionParams)
    );
  const deleteRenewalSubscriptionPackages = async (companyID: Company["id"]) =>
    deleteMutation("deleteCompanyRenewalSubscription", companyID, "company");

  return {
    ...updatables,
    addScopes,
    deleteScopes,
    addFeatures,
    deleteFeatures,
    addSubscriptionPackages,
    addRenewalSubscriptionPackages,
    deleteSubscriptionPackages,
    deleteRenewalSubscriptionPackages,
  };
};

export const useCompany = (id: Company["id"]) => useCompanies(id);
