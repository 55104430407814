import React from "react";
import { useOpenClose } from "../../../shared/global";
import { COREModal } from "../../../COREDesignSystem/Overlay/COREModal";
import { generateTestId, TestID } from "../../../shared/testids/testids";
import { COREButton } from "../../../COREDesignSystem/Action/COREButton";
import {
  useUserReleaseNote,
  UseUserReleaseNoteResponse,
} from "../../../shared/customHoooks/useUserReleaseNote";
import { Col, Row } from "antd";
import { COREHeading } from "../../../COREDesignSystem/Typography/COREHeading";
import { COREBody } from "../../../COREDesignSystem/Typography/COREBody";
import "./ReleaseNoteModal.less";
import { useUserRole } from "../../../shared/state/user";

type ReleaseNoteModalWithDataParams = Pick<
  UseUserReleaseNoteResponse,
  "releaseNoteData" | "updateLastSeenReleaseToLatestVersion"
>;

export const ReleaseNoteModal: React.FC = () => {
  const {
    sync,
    loading,
    error,
    releaseNoteData,
    updateLastSeenReleaseToLatestVersion,
  } = useUserReleaseNote();

  const isPendingRole = useUserRole() === "pending";

  if (loading && !sync) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (isPendingRole) {
    return null;
  }

  return (
    <ReleaseNoteModalWithData
      releaseNoteData={releaseNoteData}
      updateLastSeenReleaseToLatestVersion={
        updateLastSeenReleaseToLatestVersion
      }
    />
  );
};

const ReleaseNoteModalWithData: React.FC<ReleaseNoteModalWithDataParams> = ({
  releaseNoteData,
  updateLastSeenReleaseToLatestVersion,
}) => {
  const { closer, isOpen } = useOpenClose(releaseNoteData.length > 0);
  const testID = generateTestId("page", "release-note-modal");
  const handleCloseModal = () => {
    updateLastSeenReleaseToLatestVersion();
    closer();
  };
  return (
    <COREModal
      className="release-note-modal"
      visible={isOpen}
      testID={testID}
      onCancel={handleCloseModal}
      type="basic"
      title="Release notes"
      width={576}
      footer={[
        <COREButton
          key="close"
          size="lg"
          block
          testID={`${testID}-close-btn` as TestID}
          type="primary"
          onClick={handleCloseModal}
        >
          Continue to app
        </COREButton>,
      ]}
      footerFullWidth={true}
    >
      <Row className="release-note-modal-body" gutter={[0, 16]}>
        {releaseNoteData.map((d) => (
          <React.Fragment key={`release-note-${d.version}`}>
            <Col span={24}>
              <COREHeading
                marginBottom={false}
                level={4}
                testID={`${testID}-version-header` as TestID}
              >
                What’s new in v{d.version}?
              </COREHeading>
            </Col>
            <Col span={24}>
              <COREBody marginBottom={false} type="p2">
                {d.date}
              </COREBody>
            </Col>
            <Col span={24}>
              <ul className="release-note-modal-ul-paragraph">
                {d.changes.map((c) => (
                  <li key={c}>
                    <COREBody marginBottom={false} type="p2">
                      {c}
                    </COREBody>
                  </li>
                ))}
              </ul>
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </COREModal>
  );
};
