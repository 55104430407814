import { useWindowSize } from "react-use";

export type BreakpointKeys = "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
type Breakpoints = {
  [key in BreakpointKeys]: number; // eslint-disable-line no-unused-vars
};

export const SCREEN_XXS_SIZE = 575;
export const SCREEN_XS_SIZE = 767;
export const SCREEN_SM_SIZE = 991;
export const SCREEN_MD_SIZE = 1199;
export const SCREEN_LG_SIZE = 1599;
export const SCREEN_XL_SIZE = 2599;

const breakpoints: Breakpoints = {
  xxs: 0,
  xs: SCREEN_XXS_SIZE,
  sm: SCREEN_XS_SIZE,
  md: SCREEN_SM_SIZE,
  lg: SCREEN_MD_SIZE,
  xl: SCREEN_LG_SIZE,
  xxl: SCREEN_XL_SIZE,
};

export const useIsMobileScreen = () =>
  ["sm", "xs", "xxs"].includes(useBreakpoint());

export const useBreakpoint = () => {
  const { width } = useWindowSize();

  return Object.entries(breakpoints).reduce(
    (acc, [name, value]) => (width > value ? name : acc),
    "xl"
  );
};

const maxSize = 24;

export const getBreakpointForItem = (
  currentIndex: number,
  totalItems: number,
  desiredNumberOfCols: number
): number => {
  const currentItem = currentIndex + 1;

  const numItemsInLastRow = totalItems % desiredNumberOfCols;
  const isLastRow = totalItems - numItemsInLastRow >= currentItem;
  return Math.floor(
    isLastRow ? maxSize / desiredNumberOfCols : maxSize / numItemsInLastRow
  );
};

export const getBreakpointsForGrid = (
  currentIndex: number,
  totalItems: number,
  desiredNumberOfCols: Breakpoints
): Breakpoints => ({
  xxs: getBreakpointForItem(
    currentIndex,
    totalItems,
    desiredNumberOfCols["xxs"]
  ),
  xs: getBreakpointForItem(currentIndex, totalItems, desiredNumberOfCols["xs"]),
  sm: getBreakpointForItem(currentIndex, totalItems, desiredNumberOfCols["sm"]),
  md: getBreakpointForItem(currentIndex, totalItems, desiredNumberOfCols["md"]),
  lg: getBreakpointForItem(currentIndex, totalItems, desiredNumberOfCols["lg"]),
  xl: getBreakpointForItem(currentIndex, totalItems, desiredNumberOfCols["xl"]),
  xxl: getBreakpointForItem(
    currentIndex,
    totalItems,
    desiredNumberOfCols["xxl"]
  ),
});
