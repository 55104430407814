import React, { useEffect } from "react";
import useResizeAware from "react-resize-aware";

export type SetSizeObject = {
  width: number | null;
  height: number | null;
};

export type OnResizeProps = {
  onResize: React.Dispatch<React.SetStateAction<SetSizeObject>>;
  children: React.ReactNode;
};

export const defaultResizeValue: SetSizeObject = {
  width: null,
  height: null,
};

export const OnResize = ({ onResize, children }: OnResizeProps) => {
  const [resizeListener, sizes] = useResizeAware();

  useEffect(() => {
    if (onResize === undefined) return;
    onResize({ width: sizes.width, height: sizes.height });
  }, [onResize, sizes.width, sizes.height]);

  return (
    <div style={{ position: "relative" }}>
      {resizeListener}
      {children}
    </div>
  );
};
