import React from "react";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  Loading3QuartersOutlined,
  MessageFilled,
} from "@ant-design/icons";
import { message, MessageArgsProps } from "antd";
import { TestID } from "../../shared/testids/testids";
import { COREBody } from "../Typography/COREBody";
import "./COREMessage.less";

export type MessageArgsTypes = MessageArgsProps & {
  testID?: TestID;
};

export const coreMessage = (props: MessageArgsTypes) => {
  let icon = props.icon;
  if (!icon) {
    switch (props.type) {
      case "info":
        icon = <MessageFilled className={"icon-info"} />;
        break;
      case "success":
        icon = <CheckCircleFilled className={"icon-success"} />;
        break;
      case "warning":
        icon = <ExclamationCircleFilled className={"icon-warning"} />;
        break;
      case "error":
        icon = <CloseCircleFilled className={"icon-error"} />;
        break;
      case "loading":
        icon = <Loading3QuartersOutlined spin className={"icon-loading"} />;
        break;
    }
  }

  message.open({
    ...props,
    content: (
      <COREBody className={"message-content"} marginBottom={false} type="p3">
        {props.content}
      </COREBody>
    ),
    className: `${props.className} ${"core-message"}`,
    icon: icon,
  });
};
