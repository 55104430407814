import { Switch, SwitchProps } from "antd";
import React, { FC } from "react";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import "./CORESwitch.less";

type Size = "sm" | "lg";

export type CORESwitchProps = Omit<
  SwitchProps,
  "size" | "checkedChildren" | "unCheckedChildren"
> & {
  testID: TestID;
  size?: Size;
};

const CORESizeToAntSize: Record<Size, SwitchProps["size"]> = {
  sm: "small",
  lg: "default",
};

export const CORESwitch: FC<CORESwitchProps> = ({
  testID,
  size = "lg",
  ...props
}) => (
  <TestIDWrapper testID={testID} className={"core-switch"}>
    <Switch {...props} size={CORESizeToAntSize[size]} />
  </TestIDWrapper>
);
