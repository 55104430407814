/* eslint-disable react/prop-types */
import React, { Suspense, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Layout } from "antd";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { useIdle, useInterval } from "react-use";
import "./DashboardLayout.less";
import { ErrorBoundaryWithRouter as ErrorBoundary } from "../../../shared/errors/ErrorBoundary";
import { useUserDashboard } from "../../../shared/customHoooks/useUserDashboard";
import { useMutation } from "react-query";
import { mutation } from "../../../shared/state/appQueryClient";
import { generateTestId } from "../../../shared/testids/testids";
import { COREBackToTop } from "../../../COREDesignSystem/Navigation/COREBackToTop";
import classNames from "classnames";
import { CORETopHeader } from "../../../COREDesignSystem/Navigation/CORETopHeader";
import { useBreakpoint } from "../../../shared/customHoooks/useBreakpoint";
import { COREMainMenuBlock } from "../../../COREDesignSystem/Navigation/COREMainMenuBlock";
import { useLocalStorageUntilLogout } from "../../../shared/customHoooks/useLocalStorageUntilLogout";
import { BreadcrumbItem } from "../../../COREDesignSystem/Navigation/COREBreadcrumb";
import { ReleaseNoteModal } from "./ReleaseNoteModal";
import { COREEmpty } from "../../../COREDesignSystem/Content/COREEmpty";
import { useUserAccessibleSections } from "../../../shared/customHoooks/useUserAccessibleSections";
import { useUserRole } from "../../../shared/state/user";
import { checkUserAccess } from "./DashboardLayoutRoute";

const refreshInterval = 60e3; // 60 secs
const isIdleInterval = 10 * 60e3; // 10 mins

export const COREMaterialTableIsFullScreenModeContext = React.createContext<{
  isFullScreenMode?: boolean;
  setIsFullScreenMode?: React.Dispatch<React.SetStateAction<boolean>>;
}>({});

function useRefreshToken() {
  const tokenMutation = useMutation(
    () => {
      return mutation({
        queryKey: [
          "refreshToken",
          {
            action: "refreshToken",
            enabled: true,
          },
        ],
      });
    },
    {
      onSuccess: () => {},
    }
  );

  const isIdle = useIdle(isIdleInterval);
  useInterval(() => {
    if (!isIdle) {
      tokenMutation.mutate();
    }
  }, refreshInterval);
}

export const PageLayout: React.FC<{
  blur?: boolean;
  breadcrumbs?: BreadcrumbItem[];
}> = React.memo(({ blur, children, breadcrumbs }) => {
  const isPendingRole = useUserRole() === "pending";
  const [isMenuHidden, setIsMenuHidden] = useState(true);
  const [isPanel, setIsPanel] = useLocalStorageUntilLogout<boolean>({
    key: "mainMenuIsPanel",
    initialValue: true,
  });
  const {
    sync,
    loading,
    error,
    markAsHomepage,
    configs: { homepage },
  } = useUserDashboard();
  const {
    sync: syncMenu,
    loading: loadingMenu,
    error: errorMenu,
    userAccessibleSections,
  } = useUserAccessibleSections();

  const currentBreakpoint = useBreakpoint();
  const [isFullScreenMode, setIsFullScreenMode] = useState<boolean>(false);

  const location = useLocation();
  const isSmallScreen = !["md", "lg", "xl", "xxl"].includes(currentBreakpoint);
  const toggleMobileMenu = () => {
    setIsMenuHidden(!isMenuHidden);
  };
  useRefreshToken();

  const isUserDashboardReady = !loading || sync;
  const isUserAccessibleSectionsReady = !loadingMenu || syncMenu;
  if ((!isUserDashboardReady || !isUserAccessibleSectionsReady) && !blur)
    return <CORELoading size={"lg"} />;
  if (error) {
    console.error(error);
    console.error(errorMenu);
    return (
      <COREEmpty
        description="Failed to load page data"
        testID={generateTestId("page", "core-empty-error-to-load-data")}
      />
    );
  }

  const isHomepageAllow =
    homepage && checkUserAccess(userAccessibleSections, homepage);

  if (location.pathname === "/" && !isPendingRole) {
    if (homepage && isHomepageAllow) {
      return <Redirect to={homepage} />;
    } else {
      markAsHomepage("/home");
      return <Redirect to={`/home`} />;
    }
  }

  return (
    <>
      <COREMaterialTableIsFullScreenModeContext.Provider
        value={{ isFullScreenMode, setIsFullScreenMode }}
      >
        <div
          className={classNames("dashboard-layout-block", {
            "main-layout-content-blur": blur,
          })}
        >
          <Layout>
            {!isFullScreenMode && (
              <Layout.Header className={"top-header-sticky"}>
                <CORETopHeader
                  toggleMobileMenu={toggleMobileMenu}
                  breadcrumbs={breadcrumbs}
                />
              </Layout.Header>
            )}
            <Layout
              className={classNames("ant-layout-has-sider", {
                "mobile-menu-active": isMenuHidden && isSmallScreen,
              })}
            >
              {!isPendingRole && (
                <>
                  <ReleaseNoteModal />
                  <COREMainMenuBlock
                    testID={generateTestId("page", "main-menu-block")}
                    isHidden={isMenuHidden && isSmallScreen}
                    setIsHidden={setIsMenuHidden}
                    isPanel={isPanel}
                    setIsPanel={setIsPanel}
                  />
                </>
              )}

              <Layout className={"content-layout"}>
                <Layout.Content className={"main-content-block navy-bg"}>
                  <Suspense fallback={<CORELoading />}>
                    <ErrorBoundary>
                      <div className={"main-layout-outer-block"}>
                        <div className={"main-layout-content-block"}>
                          {children}
                          <COREBackToTop
                            testID={generateTestId("page", `back-to-top`)}
                          />
                        </div>
                      </div>
                    </ErrorBoundary>
                  </Suspense>
                </Layout.Content>
              </Layout>
            </Layout>
          </Layout>
        </div>
      </COREMaterialTableIsFullScreenModeContext.Provider>
    </>
  );
});
