import React from "react";
import { Empty, EmptyProps } from "antd";
import "./COREEmpty.less";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { Merge } from "../../shared/TypeScriptHelpers";
import classNames from "classnames";
import { COREHeading } from "../Typography/COREHeading";
import { COREBody } from "../Typography/COREBody";

export type COREEmptyProps = Merge<
  EmptyProps,
  {
    description: string;
    hint?: string;
    testID: TestID;
  }
>;

export const COREEmpty: React.FC<COREEmptyProps> = ({
  description,
  hint,
  testID,
  children,
  ...props
}) => {
  const descriptionElement = (
    <>
      <COREHeading level={3} testID={`${testID}-title`}>
        {description}
      </COREHeading>
      {hint && <COREBody type={"p2"}>{hint}</COREBody>}
    </>
  );

  return (
    <TestIDWrapper testID={testID}>
      <Empty
        description={descriptionElement}
        className={classNames("core-empty-block", {
          "no-image": props.image === false,
        })}
        {...props}
      >
        {children}
      </Empty>
    </TestIDWrapper>
  );
};
