import React from "react";
import { Progress, ProgressProps } from "antd";
import { TestID } from "../../shared/testids/testids";
import "./COREProgress.less";
import classNames from "classnames";
import { isDevEnv } from "../../shared/state/rest";
import { ProgressSize } from "antd/lib/progress/progress";

export type ProgressCircleSize = "mini" | "small" | "medium" | "large";

type COREProgressSize =
  | {
      type?: "line";
      size?: ProgressProps["size"];
      hideOnEmpty?: boolean;
    }
  | {
      type?: "circle";
      size?: ProgressCircleSize;
      hideOnEmpty?: never;
    };

export type COREProgressProps = {
  hideOnComplete?: boolean;
  percent: number;
  testID: TestID;
} & Omit<ProgressProps, "size" | "type" | "strokeWidth"> &
  COREProgressSize;

interface styleType {
  transition: string;
  opacity?: number;
}

export const COREProgress: React.FC<COREProgressProps> = ({
  hideOnComplete = true,
  hideOnEmpty = hideOnComplete,
  percent, // note: COREProgress takes percent values from [0,1] unlike Antd Progress which is [0,100]
  status,
  testID,
  size,
  type,
  className,
  ...props
}) => {
  percent = Math.round((percent * 100)!);

  const isFinished = percent >= 100;
  let isHidden: boolean = false;
  if (hideOnComplete) isHidden = isFinished;
  if (hideOnEmpty && !isHidden) {
    // if we are already in a hidden state, we don't want to change it
    isHidden = percent <= 0;
  }

  const baseStyles: styleType = {
    transition: "opacity 2s ease-in 1.1s",
  };
  const style: styleType = isHidden
    ? { ...baseStyles, opacity: 0 }
    : baseStyles;

  const classNamesString = classNames([
    "core-progress-block",
    className,
    { [size as string]: type === "circle" },
    { "dev-env": isDevEnv() },
    { finished: isFinished },
  ]);

  const circleStrokeWidth = {
    mini: 16,
    small: 8,
    medium: 6,
    large: 7,
  };

  return (
    <Progress
      strokeLinecap={"round"}
      type={type}
      data-testid={testID}
      className={classNamesString}
      status={status}
      percent={percent}
      style={style}
      size={type === "line" ? (size as ProgressSize) : undefined}
      strokeWidth={
        type === "circle" && size ? circleStrokeWidth[size] : undefined
      }
      {...props}
    />
  );
};
