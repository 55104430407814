import React, { useState } from "react";
import { Typography } from "antd";
import { ParagraphProps } from "antd/lib/typography/Paragraph";
import classnames from "classnames";
import { TestID } from "../../shared/testids/testids";
import { Merge } from "../../shared/TypeScriptHelpers";
import "./COREBody.less";
import { AllColorHEX } from "../Content/COREColour";
import { CORETooltip } from "../Overlay/CORETooltip";
import {
  defaultResizeValue,
  OnResize,
  SetSizeObject,
} from "../../shared/OnResize";

const { Paragraph } = Typography;

export type COREBodyProps = Merge<
  Pick<
    ParagraphProps,
    "underline" | "italic" | "strong" | "children" | "onClick" | "ellipsis"
  >,
  {
    type?: "p1" | "p2" | "p3" | "p4" | "p5";
    testID?: TestID;
    marginBottom?: boolean;
    className?: string;
    inline?: boolean;
    color?: AllColorHEX;
    textWrap?: boolean;
    alignCenter?: boolean;
  }
>;

const getTooltipContent = (
  isTruncated: boolean,
  message: React.ReactNode,
  messageTooltip: React.ReactNode
): string => {
  switch (true) {
    case !!messageTooltip && !isTruncated:
      return `${messageTooltip}`;
    case !!messageTooltip && isTruncated:
      return `${message} - ${messageTooltip}`;
    default:
      return `${message}`;
  }
};

export const COREBodyTruncateWithTooltip: React.FC<
  Omit<COREBodyProps, "testID" | "ellipsis"> &
    Required<Pick<COREBodyProps, "testID">> & {
      truncateAfter?: number;
      messageTooltip?: React.ReactNode;
    }
> = ({ children, messageTooltip, testID, truncateAfter, ...props }) => {
  const [currentSize, setCurrentSize] =
    useState<SetSizeObject>(defaultResizeValue);
  const [fullSize, setFullSize] = useState<SetSizeObject>(defaultResizeValue);

  const fullContentHeight =
    fullSize && fullSize.height !== null ? fullSize.height : 0;
  const containerHeight =
    currentSize && currentSize.height !== null ? currentSize.height : 0;
  const isTruncated: boolean =
    !!truncateAfter && fullContentHeight > containerHeight;
  const hideTooltip = messageTooltip ? false : !isTruncated;
  const showMessageTooltip = getTooltipContent(
    isTruncated,
    children,
    messageTooltip
  );

  return (
    <>
      <CORETooltip
        title=""
        message={showMessageTooltip}
        position="top"
        width="auto"
        testID={`${testID}-tooltips` as TestID}
        hidden={hideTooltip}
        className="core-body-truncate-with-tooltip"
      >
        <OnResize onResize={setCurrentSize}>
          <div
            className="core-body-truncate-text"
            style={
              truncateAfter
                ? {
                    lineClamp: truncateAfter,
                    WebkitLineClamp: truncateAfter,
                  }
                : {}
            }
          >
            <COREBody {...props}>{children}</COREBody>
          </div>
        </OnResize>
        <div className="core-body-hidden-full-content">
          <OnResize onResize={setFullSize}>
            <COREBody {...props}>{children}</COREBody>
          </OnResize>
        </div>
      </CORETooltip>
    </>
  );
};

export const COREBody: React.FC<COREBodyProps> = ({
  underline,
  italic,
  strong,
  children,
  type = "p2",
  testID,
  marginBottom = true,
  textWrap = true,
  className,
  inline = false,
  color,
  onClick,
  ellipsis,
  alignCenter,
}) => {
  const classes = classnames([
    "core-body",
    type,
    className,
    { "no-margin-bottom": !marginBottom },
    { inline: inline },
    { onClick: onClick },
    { "no-text-wrap": !textWrap },
    { "align-center": alignCenter },
  ]);
  return (
    <Paragraph
      onClick={onClick}
      data-testid={testID}
      underline={underline}
      italic={italic}
      strong={strong}
      className={classes}
      ellipsis={ellipsis}
      {...(color && { style: { color: color } })}
    >
      {children}
    </Paragraph>
  );
};
