import React from "react";
import {
  COREFormItem,
  COREFormItemProps,
} from "../../../../COREDesignSystem/Form/COREFormItem";
import { generateTestId } from "../../../../shared/testids/testids";
import { CORETypographyInput } from "../../../../COREDesignSystem/Typography/CORETypographyInput";
import { COREInput } from "../../../../COREDesignSystem/Form/COREInput";
import { CORECheckbox } from "../../../../COREDesignSystem/Form/CORECheckbox";
import { DashboardTemplateSelect } from "../../../../shared/select/DashboardTemplateSelect";

export const DashboardTemplateFormItem = () => {
  return (
    <COREFormItem
      name="templates"
      testID={generateTestId("dashboard", "templete-form-item")}
      label={<CORETypographyInput type="label">Template</CORETypographyInput>}
      labelSize="lg"
    >
      <DashboardTemplateSelect
        testID={generateTestId("dashboard", "dashboard-widgets-select")}
        placeholder={"Default dashboard"}
      />
    </COREFormItem>
  );
};

export const DashboardNameFormItem: React.FC<
  Pick<COREFormItemProps, "rules">
> = ({ rules }) => (
  <COREFormItem
    name="name"
    testID={generateTestId("dashboard", "dashboard-name-form-item")}
    label={
      <CORETypographyInput type="label">Dashboard name</CORETypographyInput>
    }
    rules={[
      ...(rules
        ? rules
        : [
            {
              required: true,
              message: "Dashboard name is required",
            },
          ]),
    ]}
    labelSize="lg"
  >
    <COREInput.Input
      widthSize="xxl"
      placeholder="Add dashboard name"
      size="lg"
      testID={generateTestId("dashboard", "dashboard-name-input")}
    />
  </COREFormItem>
);

export const MarkAsHomepageFormItem = () => (
  <COREFormItem
    name="markAsHomepage"
    testID={generateTestId("dashboard", "mark-as-homepage-form-item")}
    label={
      <CORETypographyInput type="label">Mark as homepage</CORETypographyInput>
    }
    valuePropName="checked"
  >
    <CORECheckbox
      testID={generateTestId("dashboard", "mark-as-homepage-check-box")}
    />
  </COREFormItem>
);
