import React from "react";
import classNames from "classnames";
import "./testids.less";

export type Module =
  | "home"
  | "loginform"
  | "user"
  | "marketprices"
  | "dashboard"
  | "tradetracker"
  | "chartbuilder"
  | "traderview"
  | "table"
  | "projectmarketplace"
  | "certificateproject"
  | "carbondailycurve"
  | "page"
  | "dailycurve"
  | "storybook"
  | "useranalytics"
  | "company"
  | "mfaintroduction"
  | "aemomarketnotices"
  | "superadmin"
  | "tradetimeline"
  | "emissionsmanager"
  | "tradeparties"
  | "admin"
  | "wholesaleinventory"
  | "accountactivation"
  | "setpassword"
  | "forgotpassword"
  | "insights"
  | "markets";

export type TestID = `${Module}-${string}`;

export const generateTestId = (module: Module, uniqueName: string): TestID =>
  `${module}-${uniqueName}` as TestID;

export const TestIDWrapper: React.FC<{
  testID: TestID;
  inline?: boolean;
  inlineBlock?: boolean;
  className?: string;
  fullHeight?: boolean;
  style?: React.CSSProperties;
}> = ({
  children,
  testID,
  inline = false,
  inlineBlock = false,
  className,
  fullHeight = false,
  style,
}) => (
  <div
    data-testid={testID}
    style={style}
    className={classNames({
      ...(className === undefined ? {} : { [className]: true }),
      "full-height": fullHeight,
      "test-id-inline": inline,
      "test-id-inline-block": inlineBlock,
    })}
  >
    {children}
  </div>
);
