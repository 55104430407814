import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
} from "react";
import {
  COREChartProps,
  downloadCSVFile,
} from "../../COREDesignSystem/Chart/COREChart";
import moment from "moment-timezone";
import {
  ColorHex,
  ColorName,
  prepareChartColor,
} from "../../COREDesignSystem/Content/COREColour";
import sizeMe from "react-sizeme";
import { DeepRequired, Merge } from "../../shared/TypeScriptHelpers";
import Plotly, {
  Layout,
  LayoutAxis,
  PlotData,
} from "plotly.js/dist/plotly-myPlotly.js";
import {
  getDateFromRelativeDateRange,
  RangeValue,
  RelativeRangeValue,
} from "../../COREDesignSystem/Form/CORERangePicker";
import { NonEmptyRange, strToRange } from "../../shared/date/ranges";
import { Moment } from "moment";
import {
  AggAPI,
  aggOptions,
  ChartApiData,
  ConvertDataType,
  RpcAggFuncType,
  TracesAPIConfig,
  useChartBuilder,
} from "./useChartBuilderApiV2";
import { TestID } from "../../shared/testids/testids";
import { Empty } from "antd";
import { GroupByNative } from "../../shared/globals";
import {
  COREChartLegend,
  Legend,
} from "../../COREDesignSystem/Chart/COREChartLegend";
import { bodyFontFamily, chartFont, uniqueByKey } from "../../shared/global";
import { CORETagStyle } from "../../COREDesignSystem/Content/CORETag";
import { ChartProps, PlotRefType } from "../../COREDesignSystem/Chart/ChartHOC";
import { useFeatureFlags } from "../../shared/customHoooks/useFeatureFlags";

const lineColor = "line.color";

export type AggregatesChartProps = {
  chartId: string;
  chartTitle?: string;
  traces: Trace[];
  by?: By;
  downloadCsv: boolean;
  downloadPng?: boolean;
  downloadPngCanvas?: boolean;
  chartFilter: ChartFilterOptions;
  layout?: Partial<Layout>;
  size: { width: number };
  testID: TestID;
  hoverColor?: COREChartProps["hoverColor"];
  configRangeOfYAxis?: ConfigYAxisValue[];
  chartHoverMode?: Layout["hovermode"];
  adjustHeightOfChart?: boolean;
  enableEmptyIcon?: boolean;
  showlegend?: boolean;
  legendFilter?: boolean;
  plotRef?: MutableRefObject<PlotRefType>;
  chartLegendRef?: any;
  ref?: any;
  chartPanel?: boolean;
  empty: React.ReactElement;
  setProgressPercent?: Dispatch<SetStateAction<number>>;
  chartIsIn?: "panelChart" | "containerChart";
  isWidget?: boolean;
  customLayout?: boolean;
};

export type ConfigYAxisValue = {
  axis: string;
  setYCap?: number;
  setYFloor?: number;
  setYMax?: number;
  setYMin?: number;
};

export type ChartFilterOptions = {
  todayDate: string;
  hasTodayBreak: boolean;
};

export type Axis = {
  side: "left" | "right";
  title: string;
};
export type By = "day" | "month" | "year" | "all";
export type Trace = Merge<
  Merge<
    DeepRequired<Pick<PlotData, "type" | "title" | "name">, ["title", "text"]>,
    {
      axis: Axis;
      aggFunction: string;
      aggPeriod: string; // to support multiple aggPeriod in chart
      dateRange: RelativeRangeValue;
      columnName: string;
      selectedValue: string | number;
      tableName: string;
      convertDataTo?: ConvertDataType;
      "line.color"?: ColorName | ColorHex | string;
      tagStyle?: CORETagStyle;
    }
  >,
  Partial<Pick<PlotData, "hovertemplate" | "line.dash">>
>;

export type ChartSelect = {
  aggPeriod: string;
  dates: Moment[];
  selection: {
    id: number;
    traceName: string;
  };
};

type AxisSide = {
  axisTitle: string;
  traceNames: string[];
};

interface UniqueAxisSide {
  left: AxisSide[];
  right: AxisSide[];
}

type ChartLayoutAxis = Pick<
  LayoutAxis,
  "title" | "side" | "tickformat" | "showgrid" | "showline" | "rangemode"
> & {
  axisKey: string;
  traceName: string[];
  automargin?: LayoutAxis["automargin"];
};

export type AggregatesChartData = Merge<
  Pick<
    PlotData,
    | "name"
    | "x"
    | "type"
    | "mode"
    | "marker"
    | "yaxis"
    | "showlegend"
    | "hoverlabel"
    | "hovertemplate"
  >,
  {
    line?: PlotData["line"];
    y?: number[];
    close?: number | null[];
    high?: number | null[];
    low?: number | null[];
    open?: number | null[];
  }
>;

type SetHeightType = {
  height?: number;
};

export type AggOptionType = {
  batch: string;
  value: string;
  label: string;
};

const defPositionStep = 0.08;

export const getTodayDate = (aggPeriod) =>
  aggPeriod === "none" || aggPeriod === "half-hour"
    ? moment().tz("Australia/Brisbane")
    : moment().tz("Australia/Brisbane").startOf("day");

export const hasTodayDate = (aggPeriod, { from, to }) =>
  getTodayDate(aggPeriod).isBetween(from, to.add(1, "days"));

// We will fix the any type for (rawData: any[]) on #781
const generateChartAxisBySide = (axisItems: any[]): AxisSide[] => {
  let axisSide: AxisSide[] = [];
  axisItems.forEach((item) => {
    const {
      data,
      chartOptions: {
        axis: { title },
        name,
      },
    } = item;
    if (data !== undefined && data.length > 0) {
      const ind = axisSide.findIndex((i) => i.axisTitle === title);
      if (ind === -1) {
        axisSide.push({
          axisTitle: title,
          traceNames: [name],
        });
      } else {
        axisSide[ind].traceNames.push(name);
      }
    }
  });
  return axisSide;
};

export const getUniqueAxisLabel = (
  rawData: ChartApiData[] | undefined
): UniqueAxisSide => {
  let axisLeft: AxisSide[];
  let axisRight: AxisSide[];
  const leftSide: ChartApiData[] =
    rawData?.filter((i) => i.chartOptions.axis.side === "left") || [];
  const rightSide: ChartApiData[] =
    rawData?.filter((i) => i.chartOptions.axis.side === "right") || [];

  axisLeft = generateChartAxisBySide(leftSide);
  axisRight = generateChartAxisBySide(rightSide);

  // if leftSide is empty we should use the first item of rightSide as left axis by default
  if (
    (!axisLeft || axisLeft?.length === 0) &&
    axisRight &&
    axisRight?.length > 0
  ) {
    const tmpLeft: AxisSide | undefined = axisRight.pop();
    if (tmpLeft) {
      axisLeft.push(tmpLeft);
    }
  }

  return {
    left: Array.from(new Set<AxisSide>(axisLeft)),
    right: Array.from(new Set<AxisSide>(axisRight)),
  };
};

const getXDomain = (
  axisLabels: UniqueAxisSide
): [number | null, number | null] => {
  let leftDomain: number | null = null;
  let rightDomain: number | null = null;
  if (axisLabels) {
    let leftAxisLength = axisLabels.left.length;

    if (leftAxisLength === 0) {
      leftAxisLength = 1;
    }

    leftDomain = leftAxisLength * 0.075;
    rightDomain = 1 - axisLabels.right.length * 0.075;
  }

  return [leftDomain, rightDomain];
};

const defaultLeftAxis = (
  newAxis: string,
  traceNames: string[],
  axisTitle: string
): ChartLayoutAxis => {
  const otherConfig =
    newAxis === "yaxis"
      ? {
          zeroline: false,
          autorange: true,
        }
      : {};
  return {
    axisKey: newAxis,
    traceName: [...traceNames],
    title: {
      text: axisTitle,
      standoff: 0,
    },
    rangemode: "tozero",
    showgrid: false,
    showline: true,
    side: "left",
    ...otherConfig,
  };
};

const defaultRightAxis = (
  newAxis: string,
  traceNames: string[],
  axisTitle: string,
  position: number
): ChartLayoutAxis => {
  const otherProps =
    newAxis !== "yaxis"
      ? {
          overlaying: "y",
          position: position,
        }
      : {};
  return {
    axisKey: newAxis,
    traceName: [...traceNames],
    title: {
      text: axisTitle,
      standoff: 10,
    },
    showgrid: false,
    showline: true,
    rangemode: "tozero",
    automargin: true,
    side: "right",
    ...otherProps,
  };
};

const generalYAxis = (
  newAxis: string,
  traceNames: string[],
  axisTitle: string,
  position: number,
  axisSide: LayoutAxis["side"]
): ChartLayoutAxis => {
  const otherProps =
    newAxis !== "yaxis"
      ? {
          overlaying: "y",
          position: position,
        }
      : {};
  return {
    axisKey: newAxis,
    traceName: [...traceNames],
    title: {
      text: axisTitle,
      standoff: 10,
    },
    showgrid: false,
    showline: true,
    rangemode: "tozero",
    side: axisSide,
    ...otherProps,
  };
};

const getAxisIndex = (
  yAxisArray: ChartLayoutAxis[],
  axisTitle: string
): number => {
  return yAxisArray.findIndex((item) => {
    return typeof item.title !== "string"
      ? item.title.text === axisTitle
      : item.title === axisTitle;
  });
};

const getUniqueAxisKey = (yAxisArray: ChartLayoutAxis[]): string => {
  return yAxisArray.length === 0 ? "yaxis" : `yaxis${yAxisArray.length + 1}`;
};

export const getChartAxis = (
  rawData: any,
  uniqueAxis: UniqueAxisSide
): ChartLayoutAxis[] => {
  const xd: [number | null, number | null] = getXDomain(uniqueAxis);
  let yAxisArray: ChartLayoutAxis[] = [];
  let lastLeft: number = 0;
  let lastRight: number = 0;

  const generateYAxis = (items: AxisSide, axisSide: "left" | "right") => {
    const { axisTitle, traceNames } = items;
    const axisIndex = getAxisIndex(yAxisArray, axisTitle);
    if (axisIndex === -1) {
      const xPosition = axisSide === "left" ? xd[0] : xd[1];
      const sideItems = yAxisArray.filter((i) => i.side === axisSide).length;
      let newPs = xPosition;
      const newAxis = getUniqueAxisKey(yAxisArray);
      if (yAxisArray.length === 0 && sideItems === 0 && axisSide === "left") {
        lastLeft = newPs || 0;
        yAxisArray.push(defaultLeftAxis(newAxis, traceNames, axisTitle));
      } else if (axisSide === "right" && sideItems === 0) {
        lastRight = newPs || 0;
        yAxisArray.push(
          defaultRightAxis(newAxis, traceNames, axisTitle, lastRight)
        );
      } else {
        if (axisSide === "left") {
          newPs = lastLeft - defPositionStep;
          lastLeft = newPs;
        } else {
          newPs = lastRight + defPositionStep;
          lastRight = newPs;
        }
        yAxisArray.push(
          generalYAxis(newAxis, traceNames, axisTitle, newPs, axisSide)
        );
      }
    }
  };

  uniqueAxis.left.forEach((item) => {
    generateYAxis(item, "left");
  });

  uniqueAxis.right.forEach((item) => {
    generateYAxis(item, "right");
  });

  return yAxisArray;
};

const splitChartData = (
  data,
  todayDate,
  traceName,
  type,
  chartMode,
  colour,
  dataAxisKey,
  hovertemplate
): AggregatesChartData[] => {
  const splitData: AggregatesChartData[] = [];
  const halfLeftData = data.filter(({ date }) => date <= todayDate);
  const hx = halfLeftData.map((d) => d.date);
  const hy = halfLeftData.map((d) => d.value);
  splitData.push({
    name: traceName,
    x: hx,
    y: hy,
    type: type,
    mode: chartMode,
    line: { width: 2 },
    marker: { color: colour, ...(chartMode === "markers" ? { size: 10 } : {}) },
    yaxis: dataAxisKey,
    showlegend: true,
    hovertemplate: hovertemplate ? hovertemplate : "%{y:,.2f}",
    hoverlabel: { namelength: -1 },
  });

  const halfRightData = data.filter(({ date }) => date >= todayDate);
  const x = halfRightData.map((d) => d.date);
  const y = halfRightData.map((d) => d.value);

  splitData.push({
    name: traceName,
    x: x,
    y: y,
    type: type,
    mode: chartMode,
    line: { dash: "dot", width: 1.5 },
    showlegend: false,
    marker: { color: colour, ...(chartMode === "markers" ? { size: 10 } : {}) },
    yaxis: dataAxisKey,
    hovertemplate: "%{y:,.2f}",
    hoverlabel: { namelength: -1 },
  });
  return splitData;
};

export const convertChartValue = (
  value: number | null,
  convertDataTo?: ConvertDataType
): number | null => {
  if (value === null) return null;
  if (!convertDataTo) return value;
  const absValue = Math.abs(value);
  if (convertDataTo === "positive") return absValue;
  if (convertDataTo === "negative") return -absValue;
  return value;
};

const getYAxis = (type, aggFunc) => {
  if (type === "candlestick") {
    switch (aggFunc) {
      case "first":
        return "open";
      case "max":
        return "high";
      case "min":
        return "low";
      case "last":
        return "close";
    }
  }

  return "y";
};

const getLineOption = (
  lineDash: Trace["line.dash"] | undefined
): { dash?: Trace["line.dash"] } | undefined => {
  return lineDash ? { dash: lineDash } : undefined;
};

const mappingChartData = (
  hasTodayBreak: boolean,
  todayDate: string,
  axisState: ChartLayoutAxis[],
  dataColor: any
): AggregatesChartData[] => {
  const {
    data,
    chartOptions,
    aggFunc,
  }: {
    data;
    chartOptions: Trace;
    aggFunc: RpcAggFuncType;
  } = dataColor;

  const {
    title: { text: traceName },
    type,
    convertDataTo,
  } = chartOptions;
  if (!data) return [];
  const dataAxisKey: string = getChartAxisKey(axisState, traceName);
  const chartMode = data.length === 1 ? "markers" : "lines";
  const chartDataConverted = data.map((d) => ({
    date: d.x_axis_label ?? d.date,
    value:
      type === "candlestick"
        ? d.value
        : convertChartValue(d.value, convertDataTo),
  }));
  if (hasTodayBreak) {
    return splitChartData(
      chartDataConverted,
      todayDate,
      traceName,
      type,
      chartMode,
      chartOptions[lineColor],
      dataAxisKey,
      chartOptions["hovertemplate"]
    );
  } else {
    const x = chartDataConverted.map((d) => d.date);
    const y = chartDataConverted.map((d) => d.value);

    const yaxis = getYAxis(type, aggFunc);
    const lineDash = getLineOption(chartOptions["line.dash"]);

    return [
      {
        name: traceName,
        x: x,
        [yaxis]: y,
        type: type,
        mode: chartMode,
        marker: {
          color: chartOptions[lineColor],
          ...(chartMode === "markers" ? { size: 10 } : {}),
        },
        yaxis: dataAxisKey,
        showlegend: true,
        hovertemplate: chartOptions["hovertemplate"]
          ? chartOptions["hovertemplate"]
          : "%{y:,.2f}",
        hoverlabel: { namelength: -1 },
        ...(lineDash && { line: lineDash }),
      },
    ];
  }
};

const getChartAxisKey = (
  axisState: ChartLayoutAxis[],
  traceName: string
): string => {
  const axisStateItem = axisState.find((item) => {
    return item.traceName.includes(traceName);
  });
  return axisStateItem
    ? axisStateItem.axisKey.toString().replace("axis", "")
    : "y";
};

const mergeBar = (chartData) => {
  if (
    chartData &&
    chartData.length > 0 &&
    chartData.some((e) => e.type === "bar")
  ) {
    chartData = chartData.map((cd) => {
      if (cd.type !== "bar") return cd;
      return {
        ...cd,
        textposition: "none",
      };
    });
  }
  return chartData;
};

const mergeCandleStick = (chartData) => {
  if (chartData.length > 0 && chartData.some((e) => e.type === "candlestick")) {
    const candleData = chartData
      .filter((d) => d.type === "candlestick")
      .map((d) => {
        return {
          ...d,
          increasing: { fillcolor: "#242849", line: { color: "#242849" } },
          decreasing: { fillcolor: "#D84646", line: { color: "#D84646" } },
        };
      });

    const filteredData = chartData.filter((d) => d.type !== "candlestick");

    const xy2HoverTemplate =
      "<b>VOLUME</b><br>" +
      "<b>%{y:,}</b><br>" +
      "        -<br>" +
      "%{text},<br>" +
      "%{x}";

    const chartY2Data = filteredData.map((d) => {
      return {
        ...d,
        text: d.x.map((x) => moment(x).format("dddd")),
        yaxis: "y2",
        hovertemplate:
          d.type === "bar" ? xy2HoverTemplate : "skiphovertemplate",
        hoverlabel: {
          // Set #F00 for hack CSS selector - need to hide scatter chart (use scatter chart only for range slider)
          bgcolor: d.type === "bar" ? "#232848" : "#F00",
        },
        marker: {
          color: d.x.map((x) => "#232848"),
        },
        line: {
          color: "#00e9ac",
        },
      };
    });

    const mergedData = candleData.reduce((prev, cur) => {
      return { ...prev, ...cur };
    });

    const mergedChartY2Data = [mergedData, ...chartY2Data];

    return fillValueCandleStick(mergedChartY2Data);
  }

  return chartData;
};

const generateChartData = (
  apiData: ChartApiData[] | undefined,
  hasTodayBreak: boolean,
  todayDate: string,
  axisState: ChartLayoutAxis[],
  by?: By
): AggregatesChartData[] => {
  let chartData: AggregatesChartData[] = [];
  if (apiData !== undefined) {
    let data: ChartApiData[];
    if (by !== undefined) {
      // @ts-ignore
      data = apiData.flatMap((a) => {
        if (a.data === undefined) {
          return [a];
        }
        const groups = Object.entries(GroupByNative(a.data, "group"));
        return groups.map(
          // @ts-ignore
          ([group, values]: [
            string,
            { time: string; value: number; group: number }[]
          ]) => {
            return {
              ...a,
              data: values.map((v) => ({ date: v.time, value: v.value })),
            };
          }
        );
      });
    } else {
      data = apiData;
    }

    if (data !== undefined && data.length > 0) {
      chartData = data.flatMap((item) =>
        mappingChartData(hasTodayBreak, todayDate, axisState, item)
      );
    }
  }

  return mergeCandleStick(mergeBar(chartData));
};

const generateApiConfig = (traces: Trace[], by?: By): TracesAPIConfig[] => {
  const tracesWithCandleStick = traces.some((d) => d.type === "candlestick");

  return traces.map((t): TracesAPIConfig => {
    const dateRangObj: RangeValue = getDateFromRelativeDateRange(t.dateRange);
    const [from, to] = dateRangObj || [undefined, undefined];
    const startDateStr = from?.clone().format("Y-MM-DD");
    const endDateStr = to?.clone().add(1, "days").format("Y-MM-DD");
    const strDateRange = `[${startDateStr},${endDateStr})`;
    const range = strToRange(strDateRange) as NonEmptyRange;
    const aggBatchOption = aggOptions.find(
      ({ value }) => value === t.aggPeriod
    );
    const interval = aggBatchOption?.batch || "month";

    return {
      chartOptions: t,
      params: {
        params: {
          aggFunction: t.aggFunction,
          ...(by !== undefined && { by }),
          aggPeriod: t.aggPeriod,
          columnName: t.columnName,
          dateRange: strDateRange,
          selectedItem: t.selectedValue.toString(),
          tableName: t.tableName,
          includeTimesWithoutData: tracesWithCandleStick,
        },
        rangeOptions: { range, interval, field: "dateRange" },
      },
    };
  });
};

const setHeightOfChart = (adjustHeightOfChart, data) => {
  const filterShowInLegend = data.filter((item) => {
    return item.showlegend;
  });
  const divineHeight = Math.ceil(filterShowInLegend.length / 4);
  return adjustHeightOfChart ? { height: 430 + divineHeight * 20 } : {};
};

const fillValue = (arr, closeVal) => {
  if (!arr || arr.length === 0 || !closeVal || closeVal.length === 0)
    return arr;

  let prevValue = arr.find((el) => el !== null) ?? 0;

  return arr.map((d, i) => {
    if (d) prevValue = closeVal[i];
    return d ?? prevValue;
  });
};

const fillValueCandleStick = (chartData) => {
  return chartData.map((d) => {
    if (d.type !== "candlestick") return d;

    return {
      ...d,
      open: fillValue(d.open, d.close),
      close: fillValue(d.close, d.close),
      high: fillValue(d.high, d.close),
      low: fillValue(d.low, d.close),
    };
  });
};

const prepareChartLineType = (trace: Trace[]): Trace[] =>
  trace.map((data) => {
    const lineType: Object = data["line.dash"]
      ? { "line.dash": data["line.dash"] }
      : {};

    data = {
      ...data,
      ...lineType,
    };

    return data;
  });

const adjustLeftMarginOfYAxis = (
  chartData: AggregatesChartData[],
  chartIsIn: AggregatesChartProps["chartIsIn"]
) => {
  let marginValue = 25;

  if (chartIsIn === "containerChart") {
    marginValue = 35;
  } else if (chartIsIn === "panelChart") {
    marginValue = 40;
  }

  chartData.forEach((c) => {
    const filterValue = c.y?.filter((item) => item >= 1000);
    if (filterValue && filterValue.length > 0) {
      if (chartIsIn === "containerChart") {
        marginValue = 60;
      } else if (chartIsIn === "panelChart") {
        marginValue = 65;
      } else {
        marginValue = 40;
      }
    }
  });

  return marginValue;
};

const legendsWithTagStyle = (tracesWithColour: Trace[]): Legend[] => {
  return tracesWithColour?.map((i) => {
    let tagStyle = {};

    if (
      i["line.dash"] &&
      ["dash", "longdash", "dashdot", "longdashdot"].includes(i["line.dash"])
    ) {
      tagStyle = { tagStyle: `dashed` };
    } else if (i["line.dash"] === `solid`) {
      tagStyle = { tagStyle: `outline` };
    }

    return {
      label: i.title.text,
      color: i[lineColor],
      checked: true,
      key: i.title.text.replaceAll(" ", "-"),
      ...tagStyle,
    };
  });
};

const setChartAction = (
  setOnResetChart: ChartProps["setOnResetChart"],
  handleResetChart: Function,
  setOnDownloadPNG: ChartProps["setOnDownloadPNG"],
  handleDownloadPNG: Function,
  setOnDownloadCSV: ChartProps["setOnDownloadCSV"],
  handleDownloadCSV: Function,
  displayDownloadCsvButton: boolean
) => {
  if (setOnResetChart) setOnResetChart(() => handleResetChart);
  if (setOnDownloadPNG) setOnDownloadPNG(() => handleDownloadPNG);
  if (setOnDownloadCSV && displayDownloadCsvButton)
    setOnDownloadCSV(() => handleDownloadCSV);
};

export const AggregatesChartV2 = sizeMe({ noPlaceholder: true })(
  ({ plotRef, chartTitle, ...props }: AggregatesChartProps & ChartProps) => {
    const { features: featureList } = useFeatureFlags();
    const displayDownloadCsvButton =
      featureList &&
      featureList.includes(`chart-builder-environmental-product-tree-csv`);
    return (
      <AggregatesChart
        {...props}
        plotRef={plotRef}
        chartTitle={chartTitle}
        displayDownloadCsvButton={displayDownloadCsvButton}
      />
    );
  }
);

type AggregatesChartParams = AggregatesChartProps &
  ChartProps & {
    displayDownloadCsvButton: boolean;
  };

const checkIsCategoryAxis = (apiData: ChartApiData[] | undefined) =>
  apiData?.some((d: ChartApiData) => {
    if (!d.data) return false;
    return d.data.some((record) => {
      if (record.hasOwnProperty("x_axis_label")) {
        return (record as AggAPI).x_axis_label !== null;
      }
      return false;
    });
  }) ?? false;

const uniqueAPIDataByCategoryLabel = (apiData: ChartApiData[] | undefined) => {
  return apiData?.map((item) => {
    return {
      ...item,
      data: item.data ? uniqueByKey(item.data, "x_axis_label") : [],
    };
  });
};

const categoryArrayXAxis = (apiData: ChartApiData[] | undefined) => {
  const data =
    apiData
      ?.map((item) => item.data)
      .flat()
      .filter((item) => item) ?? [];
  const uniqueAxis = uniqueByKey(data, "x_axis_label");
  const sortedArray = uniqueAxis.sort(
    (a, b) =>
      new Date((a as AggAPI).date).getTime() -
      new Date((b as AggAPI).date).getTime()
  );
  return sortedArray.map((item) => (item as AggAPI).x_axis_label);
};

const chartLayoutWithCategoryConfig = (
  apiData: ChartApiData[] | undefined,
  chartLayout: COREChartProps["layout"] & {
    filename?: string;
  },
  isCategoryAxis: boolean
): COREChartProps["layout"] & {
  filename?: string;
} => {
  return isCategoryAxis
    ? {
        ...chartLayout,
        xaxis: {
          ...chartLayout["xaxis"],
          categoryorder: "array",
          categoryarray: categoryArrayXAxis(apiData),
        },
      }
    : chartLayout;
};

const AggregatesChart = sizeMe({ noPlaceholder: true })(
  ({
    chartId,
    chartTitle,
    traces,
    downloadCsv,
    downloadPng,
    downloadPngCanvas = false,
    chartFilter,
    layout,
    size: { width },
    testID,
    hoverColor,
    configRangeOfYAxis,
    chartHoverMode = "x",
    adjustHeightOfChart,
    by,
    enableEmptyIcon,
    showlegend = true,
    legendFilter = false,
    plotRef,
    chartPanel = false,
    chartLegendRef,
    chartIsIn,
    empty,
    setProgressPercent,
    setOnDownloadCSV,
    setOnDownloadPNG,
    setOnResetChart,
    displayDownloadCsvButton,
    isWidget,
    customLayout,
  }: AggregatesChartParams) => {
    const containerPadding = 50;
    const { hasTodayBreak, todayDate } = chartFilter;
    const tracesWithColour: Trace[] = prepareChartColor(traces);
    const tracesWithLineType: Trace[] = prepareChartLineType(tracesWithColour);
    const apiConfig: TracesAPIConfig[] = generateApiConfig(
      tracesWithLineType,
      by
    );
    const { progress, data } = useChartBuilder(apiConfig);
    const isCategoryAxis = checkIsCategoryAxis(data);
    const apiData = isCategoryAxis ? uniqueAPIDataByCategoryLabel(data) : data;
    const uniqueAxis: UniqueAxisSide = getUniqueAxisLabel(apiData);
    const axisState: ChartLayoutAxis[] = getChartAxis(apiData, uniqueAxis);
    const chartData: AggregatesChartData[] = generateChartData(
      apiData,
      hasTodayBreak,
      todayDate,
      axisState,
      by
    );

    if (chartData.find((d) => d.type === "candlestick" && d.x.length === 0)) {
      return (
        <div style={{ marginTop: 60 }}>
          <Empty />
        </div>
      );
    }

    useEffect(() => {
      const handleResetChart = () => {
        if (!plotRef?.current) {
          return;
        }
        Plotly.relayout(plotRef.current.el, {
          xaxis: {
            ...plotRef.current.props.layout.xaxis,
            autorange: true,
          },
          yaxis: {
            ...plotRef.current.props.layout.yaxis,
            autorange: true,
          },
        });
      };

      const handleDownloadPNG = () => {
        chartLegendRef?.current?.callDrawImage();
      };

      const handleDownloadCSV = () => {
        if (!plotRef?.current) {
          return;
        }
        plotRef.current.props.layout.filename = chartTitle;
        downloadCSVFile(plotRef.current.el);
      };

      setChartAction(
        setOnResetChart,
        handleResetChart,
        setOnDownloadPNG,
        handleDownloadPNG,
        setOnDownloadCSV,
        handleDownloadCSV,
        displayDownloadCsvButton
      );
    }, [
      chartLegendRef,
      chartTitle,
      displayDownloadCsvButton,
      plotRef,
      setOnDownloadCSV,
      setOnDownloadPNG,
      setOnResetChart,
    ]);

    let chartAxis = [];
    axisState.forEach((item) => {
      chartAxis[item.axisKey] = item;
    });

    const yAxisMargin = adjustLeftMarginOfYAxis(chartData, chartIsIn);
    setProgressPercent && setProgressPercent(progress.percent);
    const legends: Legend[] = legendsWithTagStyle(tracesWithLineType);
    const setHeight: SetHeightType = setHeightOfChart(
      adjustHeightOfChart,
      chartData
    );

    const chartLayout: COREChartProps["layout"] = {
      font: {
        family: chartFont,
      },
      filename: chartTitle,
      titlefont: {
        family: bodyFontFamily,
        size: width > 600 ? 20 : 14,
      },
      margin: {
        t: 30,
        r: 2,
        l: yAxisMargin,
        b: 50,
      },
      yaxis: {
        title: {
          font: {
            family: chartFont,
            size: 18,
          },
        },
        zeroline: false,
        rangemode: "tozero",
        autorange: true,
        showgrid: false,
        showline: true,
      },
      xaxis: {
        zeroline: false,
        autorange: true,
        showgrid: false,
        showline: true,
        domain: getXDomain(uniqueAxis) as number[],
        type: isCategoryAxis ? "category" : undefined,
        title: {
          text: "Time Starting",
          standoff: 15,
          font: {
            family: chartFont,
          },
        },
      },
      legend: {
        font: {
          family: bodyFontFamily,
        },
        orientation: "h",
        x: 0,
        y: -0.4,
      },
      showlegend: false,
      hovermode: chartHoverMode,
      ...(hasTodayBreak &&
        chartData.length > 0 && {
          shapes: [
            {
              type: "line",
              xref: "x",
              yref: "paper",
              x0: todayDate,
              y0: 0,
              x1: todayDate,
              y1: 1,
              opacity: 1,
              line: { dash: "dot", width: 1, color: "#ff0000" },
            },
          ],
        }),
      ...(chartPanel && { width: width - containerPadding }),
      ...setHeight,
      ...chartAxis,
      ...layout,
    };

    return (
      <COREChartLegend
        chartId={chartId}
        chartTitle={chartTitle}
        downloadCsv={downloadCsv}
        downloadPng={downloadPng}
        downloadPngCanvas={downloadPngCanvas}
        data={chartData}
        layout={chartLayoutWithCategoryConfig(
          apiData,
          chartLayout,
          isCategoryAxis
        )}
        configRangeOfYAxis={configRangeOfYAxis}
        hoverColor={hoverColor}
        enableEmptyIcon={enableEmptyIcon}
        testID={testID}
        legends={legends}
        showlegend={showlegend}
        legendFilter={legendFilter}
        plotRef={plotRef}
        chartPanel={chartPanel}
        ref={chartLegendRef}
        empty={empty}
        isWidget={isWidget}
        customLayout={customLayout}
      />
    );
  }
);
