import { BackTop } from "antd";
import React from "react";
import { TestID } from "../../shared/testids/testids";
import "./COREBackToTop.less";
import { COREButton } from "../Action/COREButton";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

interface COREBackToTopProps {
  testID: TestID;
}

export const COREBackToTop: React.FC<COREBackToTopProps> = ({ testID }) => {
  return (
    <div className={"back-to-top"}>
      <BackTop data-testid={testID}>
        <COREButton
          type={"default"}
          size={"lg"}
          icon={<COREIcon icon={icon({ name: "caret-up", style: "solid" })} />}
        ></COREButton>
      </BackTop>
    </div>
  );
};
