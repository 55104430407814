import React from "react";

import { Result } from "antd";
import {
  CORELoading,
  CORELoadingProps,
} from "../COREDesignSystem/Feedback/CORELoading";
import { useAPIQueryRequests } from "./customHoooks/useAPI";
import { ENDPOINTS } from "./state/rest";

export type action = keyof typeof ENDPOINTS;

export const datum = <R, Props extends { datum?: R }>(
  Child: React.ComponentType<Props>,
  action: action,
  apiOptions: object[],
  {
    loadingMessage,
    loadingLayout,
    loadingSize,
  }: {
    loadingMessage: string;
    loadingLayout?: CORELoadingProps["layout"];
    loadingSize?: CORELoadingProps["size"];
  }
) =>
  function Datum(props) {
    const { results, loading, error, sync } = useAPIQueryRequests(
      action as string,
      apiOptions
    );

    if (error) return <Result status="warning" title="Unknown error" />;
    if (loading && !sync)
      return (
        <CORELoading
          message={loadingMessage}
          layout={loadingLayout}
          size={loadingSize}
        />
      );

    return (
      <Child
        datum={results.map((r) => ({ ...r, data: { data: r.data } }))}
        {...props}
      />
    );
  };
