import React from "react";
import { Tooltip } from "antd";
import { TooltipPlacement, TooltipProps } from "antd/lib/tooltip";
import classnames from "classnames";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { COREBody, COREBodyProps } from "../Typography/COREBody";
import "./CORETooltip.less";
import { COREDivider } from "../Layout/COREDivider";

export type CORETooltipProps = {
  title?: COREBodyProps["children"];
  message: COREBodyProps["children"];
  position?: TooltipPlacement;
  width?: string;
  testID: TestID;
  hidden?: boolean;
  noFitContent?: boolean;
  inlineBlock?: boolean;
  footerAction?: React.ReactNode;
} & TooltipProps;

const BodyTooltip: React.FC<
  Pick<CORETooltipProps, "title" | "message" | "footerAction">
> = ({ title, message, footerAction }) => (
  <>
    {title && (
      <COREBody
        type={"p2"}
        strong={true}
        className={"title-message"}
        marginBottom={false}
      >
        {title}
      </COREBody>
    )}
    <COREBody type={"p3"} className={"body-message"} marginBottom={false}>
      {message}
    </COREBody>
    {footerAction && (
      <>
        <COREDivider space="xs" dashed />
        {footerAction}
      </>
    )}
  </>
);

export const CORETooltip: React.FC<CORETooltipProps> = ({
  children,
  title,
  message,
  position,
  width = "auto",
  testID,
  hidden = false,
  className,
  trigger = "hover",
  noFitContent = false,
  inlineBlock = false,
  footerAction,
}) => {
  const modalStyle = {
    backgroundColor: "#FFFFFF",
    padding: "12px 16px",
    borderRadius: "12px",
    color: "#000000",
    width: width,
  };

  return (
    <TestIDWrapper testID={testID} inlineBlock={inlineBlock}>
      <Tooltip
        overlayClassName={"core-tooltip"}
        trigger={trigger}
        placement={position}
        title={
          <BodyTooltip
            title={title}
            message={message}
            footerAction={footerAction}
          />
        }
        color={"#ffffff"}
        overlayInnerStyle={modalStyle}
        visible={hidden ? false : undefined}
        arrowPointAtCenter
        mouseEnterDelay={0.4}
      >
        <div
          className={classnames(className, {
            "core-tooltip-container": !noFitContent,
          })}
        >
          {children}
        </div>
      </Tooltip>
    </TestIDWrapper>
  );
};
