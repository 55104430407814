import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import zxcvbn from "zxcvbn";
import moment from "moment";

window.React = React;
window.ReactDOM = ReactDOM;

export const uniqByNative = (arr, predicate) => {
  const cb = typeof predicate === "function" ? predicate : (o) => o[predicate];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};

export const KeyByNative = (array, key) =>
  (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});

export const sortByNative = (key) => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export const SumNative = (arr) => {
  return arr.reduce(function (a, b) {
    return a + b;
  }, 0);
};

export const GroupByNative = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const NumberFormat = (val, digit = 0, commaSeparated) => {
  if (val === undefined || val === null) {
    return "";
  }

  if (commaSeparated && digit === 0) {
    return val.toLocaleString("en-US");
  }

  return isNaN(val)
    ? "-"
    : !isFinite(val)
    ? "∞"
    : parseFloat(val)
        .toFixed(digit)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const DATE_DISPLAY_FORMAT = "DD/MM/YYYY";

const round = (number, decimalPlaces) =>
  Number(
    Math.round(parseFloat(number + "e" + decimalPlaces)) + "e-" + decimalPlaces
  ).toFixed(decimalPlaces);

export const priceParser = (value) => value.replace(/\$\s?|(,*)/g, "");

export const priceFormatter = (
  value,
  dollarSign = true,
  shouldRound = true
) => {
  if (value === "" || value === undefined || value === null) return "";
  let formattedValue = value;
  if (shouldRound) formattedValue = round(value, 2);
  formattedValue = formattedValue
    .toString()
    .replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  return dollarSign ? `$ ${formattedValue}` : formattedValue;
};

export const volumeParser = (value) => value.replace(/(,*)/g, "");
export const volumeFormatter = (value) => {
  if (value === "" || value === undefined || value === null) return "";
  return NumberFormat(parseFloat(value), 0);
};

export const validatePhone = ({ getFieldValue }) => {
  return { validator: validatePhoneFunc };
};

export const replaceAll = (string, find, replace) => {
  return string.split(find).join(replace);
};
export const validatePhoneFunc = (rule, value) => {
  return new Promise((resolve, reject) => {
    const numberNoDash = replaceAll(value, "-", "");
    const numberNoSpace = replaceAll(value, " ", "");
    const regExp = new RegExp(/[+]?[0-9]{9,}$/);
    if (
      numberNoDash.match(regExp) ||
      numberNoSpace.match(regExp) ||
      numberNoDash === ""
    ) {
      return resolve();
    }
    return reject("The input is not a valid phone number");
  });
};

export const regionSortOrder = ["Qld", "NSW", "Vic", "Tas", "SA"];
export const MonthsOrder = [
  "CY",
  "FY",
  "Q1",
  "Q2",
  "Q3",
  "Q4",
  "Jan-Jun",
  "Jul-Dec",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const asxProductsSortOrder = ["Flat", "Peak", "Off Peak", "$300 Cap"];
export const rehProductsSortOrder = ["Solar", "Inverse Solar", "Super Peak"];
export const productSortOrder =
  rehProductsSortOrder.concat(asxProductsSortOrder);

export const environmentalProductSortOrder = [
  "LGC",
  "STC",
  "ESC",
  "VEEC",
  "ACCU",
  "ACCU Agriculture",
  "ACCU Avoided deforestation",
  "ACCU HIR",
  "ACCU Industrial",
  "ACCU Landfill gas capture",
  "ACCU Plantings",
  "ACCU Savanna burning",
  "ACCU Savanna burning+",
  "ACCU Soil Carbon",
  "NZU",
  "CER Renewables",
  "GS Community-based",
  "GS Forestry",
  "GS Renewables",
  "VCS Community-based",
  "VCS REDD+",
  "VCS Renewables",
  "VCS Re/afforestation",
];

export const isEmptyObject = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const sortByTitle = (items, sortOrder) => {
  return items.sort(
    (a, b) => sortOrder.indexOf(a.title) - sortOrder.indexOf(b.title)
  );
};

export const sortByRegions = (items) => {
  return sortByTitle(items, regionSortOrder);
};

export const sortByProducts = (items) => {
  return sortByTitle(items, productSortOrder);
};

export const sortByEnvironmentalProducts = (items) => {
  return sortByTitle(items, environmentalProductSortOrder);
};

export const sortByShortName = (items) => {
  const spotItem = items.filter((i) => i.value === "Spot");
  const otherItem = items.filter((i) => i.value !== "Spot");
  otherItem.sort((a, b) => a.value - b.value);
  const sortOder = [
    ...spotItem.map((i) => i.value),
    ...otherItem.map((i) => i.value.toString()),
  ];
  return sortByTitle(items, sortOder);
};

export const passwordStrength = (password) => {
  let score = password.length > 0 ? 0 : -1;
  let result = null;
  const minLength = 5;

  const isTooShort = (password, minLength) => password.length < minLength;

  if (password === "") return -1;

  if (isTooShort(password, minLength) === false) {
    result = zxcvbn(password);
    score = result.score;
  }

  return score;
};

export const validatePassword = (rule, value) => {
  return new Promise((resolve, reject) => {
    if (!value || value === "") reject("Missing password");

    const score = passwordStrength(value);

    if (score < 2) return reject("Password is too weak");

    return resolve();
  });
};

export const confirmValidatePassword = (rule, value, password) => {
  if (password === "" || password === value) {
    return Promise.resolve();
  }
  return Promise.reject("Passwords do not match");
};

const allowedSeparators = ["/", "-"];
const allowedYearFormats = ["YY", "YYYY"];
const allowedMonthFormats = ["M", "MM"];
const allowedDayFormats = ["D", "DD"];
const allowedHourFormats = ["H", "HH"];
const allowedMinFormats = ["m", "mm"];
const allowedSecFormats = ["s", "ss"];
const allowedTZFormats = ["", " Z"];
export const AllowedMomentDateFormats = allowedSeparators.flatMap((sep) =>
  allowedTZFormats.flatMap((tz) =>
    allowedSecFormats.flatMap((s) =>
      allowedMinFormats.flatMap((m) =>
        allowedHourFormats.flatMap((h) =>
          allowedDayFormats.flatMap((D) =>
            allowedMonthFormats.flatMap((M) =>
              allowedYearFormats.flatMap((Y) => [
                `${D}${sep}${M}${sep}${Y} ${h}:${m}:${s}${tz}`,
                `${D}${sep}${M}${sep}${Y} ${h}:${m}:${s}`,
                `${D}${sep}${M}${sep}${Y} ${h}:${m}${tz}`,
                `${Y}${sep}${M}${sep}${D} ${h}:${m}`,
                `${Y}${sep}${M}${sep}${D} ${h}:${m}:${s}${tz}`,
                `${Y}${sep}${M}${sep}${D} ${h}:${m}:${s}`,
                `${Y}${sep}${M}${sep}${D} ${h}:${m}${tz}`,
                `${Y}${sep}${M}${sep}${D} ${h}:${m}`,
              ])
            )
          )
        )
      )
    )
  )
);

export const getStrictMomentFormat = (dateStr) =>
  moment(dateStr, AllowedMomentDateFormats, true);
export const chartConfig = {
  treeSelections: {
    electricityTree: {
      change: [
        {
          id: 1,
          shortName: "Qld",
          aemoId: "QLD1",
          variable: "Price",
          traceName: "Qld Price",
        },
        {
          id: 2,
          shortName: "NSW",
          aemoId: "NSW1",
          variable: "Price",
          traceName: "NSW Price",
        },
        {
          id: 3,
          shortName: "Vic",
          aemoId: "VIC1",
          variable: "Price",
          traceName: "Vic Price",
        },
        {
          id: 4,
          shortName: "Tas",
          aemoId: "TAS1",
          variable: "Price",
          traceName: "Tas Price",
        },
        {
          id: 5,
          shortName: "SA",
          aemoId: "SA1",
          variable: "Price",
          traceName: "SA Price",
        },
      ],
      value: [
        {
          id: 1,
          shortName: "Qld",
          aemoId: "QLD1",
          variable: "Price",
          traceName: "Qld Price",
        },
        {
          id: 2,
          shortName: "NSW",
          aemoId: "NSW1",
          variable: "Price",
          traceName: "NSW Price",
        },
        {
          id: 3,
          shortName: "Vic",
          aemoId: "VIC1",
          variable: "Price",
          traceName: "Vic Price",
        },
        {
          id: 4,
          shortName: "Tas",
          aemoId: "TAS1",
          variable: "Price",
          traceName: "Tas Price",
        },
        {
          id: 5,
          shortName: "SA",
          aemoId: "SA1",
          variable: "Price",
          traceName: "SA Price",
        },
      ],
      checkedKeys: [
        "Qld-Price-[object Object]",
        "NSW-Price-[object Object]",
        "Vic-Price-[object Object]",
        "Tas-Price-[object Object]",
        "SA-Price-[object Object]",
      ],
    },
    FCASTree: { checkedKeys: [], change: [], value: [] },
    interconnectorsTree: { checkedKeys: [], change: [], value: [] },
    SCADATree: { checkedKeys: [], change: [], value: [] },
    productTree: { checkedKeys: [], change: [], value: [] },
    environmentalProductTree: { checkedKeys: [], change: [], value: [] },
    generationProfileTree: { checkedKeys: [], change: [], value: [] },
    loadProfileTree: { checkedKeys: [], change: [], value: [] },
    weatherObservationTree: { checkedKeys: [], change: [], value: [] },
  },
  aggPeriod: "half-hour",
  dateRange: { isRelative: true, from: -1, to: 1 },
  title: "Live Rolling 24hr 30min NEM Prices",
  name: "ChartBuilder",
};

// Hook
export function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}

export const capitalizeWordString = (word) =>
  word.replace("-", " ").replace(/\b\w/g, (l) => l.toUpperCase());

export const camelize = (str) => {
  return str
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};
