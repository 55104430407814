import { Col, Row } from "antd";
import React, { Dispatch, SetStateAction, useState } from "react";
import { COREHeading } from "../../COREDesignSystem/Typography/COREHeading";
import {
  COREDropdownMenu,
  DropdownMenuItem,
} from "../../COREDesignSystem/Form/COREDropdownMenu";
import { COREButton } from "../../COREDesignSystem/Action/COREButton";
import { COREDivider } from "../../COREDesignSystem/Layout/COREDivider";
import { generateTestId, TestID } from "../../shared/testids/testids";
import { COREIcon } from "../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./WidgetPanel.less";
import { COREMenuItem } from "../../COREDesignSystem/Navigation/COREMenuItem";
import {
  COREProgress,
  COREProgressProps,
} from "../../COREDesignSystem/Feedback/COREProgress";

export type WidgetProps<T, Filters extends object> = {
  props?:
    | {
        widgetType: T;
        filters: Filters;
      }
    | string;
  name: string;
  setProps: (value: unknown) => void;
  disableFilter?: boolean;
};

export type ContentWidgetProps<T, WidgetFilterType extends object> = {
  widgetProps: WidgetProps<T, WidgetFilterType>;
  testID: TestID;
  widgetType: T;
} & ContentWithChartProps;

export type SetupWidgetProps<T, WidgetFilterType extends object> = {
  widgetProps: WidgetProps<T, WidgetFilterType>;
  testID: TestID;
};

export type ContentWithChartProps = {
  setOnDownloadPNG?: Dispatch<SetStateAction<Function>>;
  setOnDownloadCSV?: Dispatch<SetStateAction<Function>>;
  setOnResetChart?: Dispatch<SetStateAction<Function>>;
  setProgressPercent?: Dispatch<SetStateAction<COREProgressProps["percent"]>>;
};

export const currentWidgetTestID = (props: { name: string }) => {
  return props
    ? generateTestId("dashboard", `${props.name}`)
    : generateTestId("dashboard", "widget");
};

export const currentWidgetFilters = <T, WidgetFilterType extends object>(
  widgetProps: WidgetProps<T, WidgetFilterType>
) => {
  return widgetProps?.props && typeof widgetProps.props !== "string"
    ? widgetProps.props
    : null;
};

export const onSelectSetupMenu = <T, WidgetFilterType extends object>(
  widgetProps: WidgetProps<T, WidgetFilterType>,
  type: T
) => {
  const currentProps = currentWidgetFilters(widgetProps);
  if (currentProps && type === currentProps.widgetType) return;
  widgetProps.setProps({
    widget: {
      widgetType: type,
    },
    i: widgetProps.name,
  });
};

export const onSelectFilters = <T, WidgetFilterType extends object>(
  widgetProps: WidgetProps<T, WidgetFilterType>,
  filterValues: WidgetFilterType
) => {
  widgetProps.setProps({
    widget: {
      ...currentWidgetFilters(widgetProps),
      filters: filterValues,
    },
    i: widgetProps.name,
  });
};

export const buildChartMenu = (
  testID: TestID,
  onDownloadPNG?: Function,
  onDownloadCSV?: Function,
  onResetChart?: Function
) => {
  const interactionsMenu: DropdownMenuItem[] = [];
  if (onDownloadCSV) {
    interactionsMenu.push({
      component: (
        <COREMenuItem
          onClick={() => onDownloadCSV()}
          testID={`${testID}-download-as-csv-menu`}
          icon={
            <COREIcon icon={icon({ name: "file-csv", style: "regular" })} />
          }
        >
          Download CSV
        </COREMenuItem>
      ),
      closeWhenClick: true,
    });
  }
  if (onDownloadPNG) {
    interactionsMenu.push({
      component: (
        <COREMenuItem
          onClick={() => onDownloadPNG()}
          testID={`${testID}-download-as-png-menu`}
          icon={<COREIcon icon={icon({ name: "image", style: "regular" })} />}
        >
          Download PNG
        </COREMenuItem>
      ),
      closeWhenClick: true,
    });
  }
  if (onResetChart) {
    interactionsMenu.push({
      component: (
        <COREMenuItem
          onClick={() => onResetChart()}
          testID={`${testID}-reset-chart-menu`}
          icon={<COREIcon icon={icon({ name: "undo", style: "regular" })} />}
        >
          Reset Chart
        </COREMenuItem>
      ),
      closeWhenClick: true,
    });
  }

  return interactionsMenu;
};

export const WidgetPanel: React.FC<{
  interactionsMenu?: DropdownMenuItem[];
  testID: TestID;
  panelTitle: string;
}> = ({ interactionsMenu, testID, panelTitle, children }) => {
  const [onDownloadPNG, setOnDownloadPNG] = useState<Function>();
  const [onDownloadCSV, setOnDownloadCSV] = useState<Function>();
  const [onResetChart, setOnResetChart] = useState<Function>();
  const [progressPercent, setProgressPercent] =
    useState<COREProgressProps["percent"]>(0);

  const chartMenu: DropdownMenuItem[] = buildChartMenu(
    testID,
    onDownloadPNG,
    onDownloadCSV,
    onResetChart
  );

  const menuItems = [...(interactionsMenu ?? []), ...chartMenu];

  return (
    <WidgetPanelRender
      menuItems={menuItems}
      testID={testID}
      header={panelTitle}
      progressPercent={progressPercent}
    >
      {children &&
        React.cloneElement(children as React.ReactElement, {
          setOnDownloadCSV: setOnDownloadCSV,
          setOnDownloadPNG: setOnDownloadPNG,
          setOnResetChart: setOnResetChart,
          setProgressPercent: setProgressPercent,
        })}
    </WidgetPanelRender>
  );
};

export const WidgetPanelRender: React.FC<{
  menuItems: DropdownMenuItem[];
  testID: TestID;
  progressPercent: number;
  header: React.ReactNode | string;
}> = ({ menuItems, testID, progressPercent, header, children }) => (
  <div className={"widget-panel"}>
    <Row gutter={0} wrap={false}>
      <Col flex="auto" className={"widget-title"}>
        {typeof header === "string" ? (
          <COREHeading
            testID={`${testID}-header` as TestID}
            level={3}
            marginBottom={true}
          >
            {header}
          </COREHeading>
        ) : (
          header
        )}
      </Col>
      {menuItems.length !== 0 && (
        <Col flex={"34px"}>
          <COREDropdownMenu
            interactionsMenu={menuItems}
            testID={`${testID}-menu-button` as TestID}
            isDisabled={false}
            placement="bottomRight"
            overlayStyle={{ minWidth: "192px" }}
          >
            <COREButton
              type="default"
              size="md"
              icon={
                <COREIcon icon={icon({ name: "bars", style: "regular" })} />
              }
            />
          </COREDropdownMenu>
        </Col>
      )}
    </Row>
    <COREDivider space="none" />
    <COREProgress
      type={"line"}
      percent={progressPercent}
      testID={testID}
      showInfo={false}
      hideOnEmpty={true}
      hideOnComplete={true}
      className={"widget-progress"}
    />
    {children}
  </div>
);
