import "./COREDatePicker.less";
import React, { FC } from "react";
import { DatePicker, DatePickerProps } from "antd";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { WidthSize } from "./COREInput";
import classnames from "classnames";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { customizePickerIcon } from "./CORERangePicker";
import classNames from "classnames";
import { PickerPanelDateProps } from "rc-picker/lib/PickerPanel";
import { Moment } from "moment";

export type SizeDatePicker = "sm" | "md" | "lg";
export const CORESizeToAntSize: Record<
  SizeDatePicker,
  DatePickerProps["size"]
> = {
  lg: "large",
  md: "middle",
  sm: "small",
};

export type COREDatePickerProps = Omit<DatePickerProps, "size"> & {
  widthSize?: WidthSize;
  testID: TestID;
  size?: SizeDatePicker;
} & Pick<PickerPanelDateProps<Moment>, "showTime">;

export const COREDatePicker: FC<COREDatePickerProps> = ({
  widthSize = "md",
  size = "md",
  testID,
  ...props
}) => {
  const defaultProps = {
    suffixIcon: (
      <>
        <div className={"default-suffix-icon"}>
          <COREIcon icon={icon({ name: "calendar", style: "regular" })} />
        </div>
        <div className={"solid-suffix-icon"}>
          <COREIcon icon={icon({ name: "calendar", style: "solid" })} />
        </div>
      </>
    ),
    clearIcon: (
      <COREIcon icon={icon({ name: "circle-xmark", style: "solid" })} />
    ),
    ...customizePickerIcon(),
    ...props,
    className: classnames(props.className, `input-width-${widthSize}`),
    dropdownClassName: classnames(
      props.dropdownClassName,
      "core-datepicker-dropdown"
    ),
  };

  const hasValue = !!props.value || !!props.defaultValue;
  if (hasValue)
    defaultProps.className = classNames(defaultProps.className, "has-value");

  return (
    <TestIDWrapper testID={testID} className={"core-datepicker"}>
      <DatePicker size={CORESizeToAntSize[size]} {...defaultProps} />
    </TestIDWrapper>
  );
};
