import { Breadcrumb, BreadcrumbItemProps, BreadcrumbProps } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { Merge } from "../../shared/TypeScriptHelpers";
import "./COREBreadcrumb.less";
import {
  OnResize,
  SetSizeObject,
  defaultResizeValue,
} from "../../shared/OnResize";
import { CORETooltip } from "../Overlay/CORETooltip";
import classNames from "classnames";
import { COREBody } from "../Typography/COREBody";
import {
  BreakpointKeys,
  useBreakpoint,
} from "../../shared/customHoooks/useBreakpoint";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

type COREBreadcrumbProps = Merge<
  BreadcrumbProps,
  {
    testID: TestID;
    collapsed?: boolean;
  }
>;

export type BreadcrumbItem = BreadcrumbItemProps & {
  title: React.ReactNode;
};

const HomeTitleIcon = () => {
  const [homeSolid, setHomeSolid] = useState<boolean>(false);

  const displayHomeFiled = () => setHomeSolid(true);
  const displayHomeOutlined = () => setHomeSolid(false);

  return homeSolid ? (
    <COREIcon
      icon={icon({ name: "home", style: "solid" })}
      onMouseEnter={displayHomeFiled}
      onMouseLeave={displayHomeOutlined}
    />
  ) : (
    <COREIcon
      icon={icon({ name: "home", style: "regular" })}
      onMouseEnter={displayHomeFiled}
      onMouseLeave={displayHomeOutlined}
    />
  );
};

const homeTitle = {
  title: <HomeTitleIcon />,
  href: "/",
};

const BreadcrumbContent: React.FC<{
  title: BreadcrumbItem["title"];
  href: BreadcrumbItem["href"];
  testID: TestID;
}> = ({ title, href, testID }) => {
  const [itemSize, setItemSize] = useState<SetSizeObject>(defaultResizeValue);

  return (
    <div className={"display-inline"}>
      <OnResize onResize={setItemSize}>
        <CORETooltip
          title={""}
          message={
            <COREBody
              type="p3"
              marginBottom={false}
              testID={`${testID}-tooltips-message`}
            >
              {title}
            </COREBody>
          }
          position={"bottom"}
          width={"auto"}
          testID={`${testID}-tooltips`}
          hidden={(itemSize?.width ?? 0) < 180}
        >
          <div
            className={classNames("overflow-breadcrumb", {
              "hover-underline": href,
            })}
          >
            {title}
          </div>
        </CORETooltip>
      </OnResize>
    </div>
  );
};

const BreadcrumbItems: React.FC<{
  breadcrumbs: BreadcrumbItem[];
  testID: TestID;
}> = ({ breadcrumbs, testID }) => {
  const history = useHistory();

  return (
    <>
      {breadcrumbs.map(({ title, href, ...props }) => (
        <Breadcrumb.Item
          key={`${title}${href}`}
          {...(href && {
            href,
            onClick: (e) => {
              const isControl = e.ctrlKey;
              if (isControl) {
                // we want browser to handle (and no change page client side)
                return;
              }
              // we handle client side not browser
              e.preventDefault();
              history.push(href);
            },
            style: { cursor: "pointer" },
          })}
          {...props}
        >
          <BreadcrumbContent testID={testID} title={title} href={href} />
        </Breadcrumb.Item>
      ))}
    </>
  );
};

const BreadcrumbItemForSmallScreen: React.FC<
  COREBreadcrumbProps & {
    breadcrumbs: BreadcrumbItem[];
  }
> = ({ testID, breadcrumbs, ...otherProps }) => {
  if (breadcrumbs.length <= 3) {
    breadcrumbs = breadcrumbs.slice(0, -1);
  } else {
    breadcrumbs = breadcrumbs
      .slice(breadcrumbs.length - 2, breadcrumbs.length - 1)
      .map((i) => {
        return {
          ...i,
          title: (
            <>
              <COREIcon
                icon={icon({ name: "arrow-left", style: "regular" })}
                size={"xs"}
                className={"mr-10"}
              />
              {i.title}
            </>
          ),
        };
      });
  }

  return (
    <TestIDWrapper
      testID={testID}
      className={classNames("core-breadcrumb", "breadcrumb-for-small-screen")}
    >
      <Breadcrumb {...otherProps}>
        <BreadcrumbItems breadcrumbs={breadcrumbs} testID={testID} />
      </Breadcrumb>
    </TestIDWrapper>
  );
};

export const COREBreadcrumb: React.FC<
  COREBreadcrumbProps & {
    breadcrumbs?: BreadcrumbItem[];
  }
> = ({ breadcrumbs = [], testID, collapsed, ...otherProps }) => {
  const screenBreakpoint = useBreakpoint();
  const breadcrumbsWithHome = [homeTitle, ...breadcrumbs];

  switch (screenBreakpoint as BreakpointKeys) {
    case "xxs":
    case "xs":
    case "sm":
      return (
        <BreadcrumbItemForSmallScreen
          testID={testID}
          breadcrumbs={breadcrumbsWithHome}
          {...otherProps}
        />
      );
    case "md":
      if (!collapsed) {
        return (
          <BreadcrumbItemForSmallScreen
            testID={testID}
            breadcrumbs={breadcrumbsWithHome}
            {...otherProps}
          />
        );
      }
  }

  return (
    <TestIDWrapper testID={testID} className={"core-breadcrumb"}>
      <Breadcrumb {...otherProps}>
        <BreadcrumbItems breadcrumbs={breadcrumbsWithHome} testID={testID} />
      </Breadcrumb>
    </TestIDWrapper>
  );
};

export const COREBreadcrumbInitStorybookData: React.FC<COREBreadcrumbProps> = ({
  ...otherProps
}) => {
  const breadcrumbs = [
    {
      title: <HomeTitleIcon />,
      href: "/?path=/story/core-COREBreadcrumb--default",
    },
    {
      title: "Ut sagittis consequat condimentum.",
      href: "/",
    },
    {
      title: "Duis laoreet sagittis pellentesque. Donec accumsan porta.",
      href: "/",
    },
    {
      title: "Morbi nisl erat, euismod quis maximus nec.",
      href: "/",
    },
    {
      title: "Integer elementum.",
    },
  ];

  return <COREBreadcrumb {...otherProps} breadcrumbs={breadcrumbs} />;
};
