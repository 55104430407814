import Icon from "@ant-design/icons";
import { message } from "antd";
import moment from "moment";
import React, { ComponentProps, useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { escapeDoubleQuotes } from "../../shared/global";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { DEPRECATEDCsvDateFormat } from "../../shared/date/DateFormatContext";
import { ButtonType, COREButton, COREButtonProps } from "./COREButton";

export type TMomentFormat = {
  format: string;
};

export type CSVHeader = {
  label: string;
  key: string;
};

export type CSVData = ComponentProps<typeof CSVLink>["data"];

export type TCSVData = {
  headers?: string[] | CSVHeader[];
  data: string[][] | CSVData;
};

export type CORECSVButtonTypeProps =
  | {
      buttonType: Extract<ButtonType, "action" | undefined>;
      icon: React.ReactElement<typeof Icon>;
    }
  | {
      buttonType?: Exclude<ButtonType, "action">;
      icon?: React.ReactElement<typeof Icon>;
    };

export type CORECSVButtonBaseProps = {
  testID: TestID;
  filename?: string;
  getData: () => TCSVData | Promise<TCSVData>;
  disabled?: boolean;
  buttonSize?: COREButtonProps["size"];
  children?: React.ReactNode;
};

export type CORECSVButtonProps = CORECSVButtonBaseProps &
  CORECSVButtonTypeProps;

// Data should be Array of Objects without headers
export const CORECSVButton: React.FC<CORECSVButtonProps> = ({
  testID,
  filename = "CSV Data",
  getData,
  disabled = false,
  buttonType,
  buttonSize,
  icon,
  children,
}) => {
  const csvLinkRef: React.RefObject<CSVLink> = React.createRef();
  const [csvHeaders, setCsvHeaders] = useState<
    string[] | CSVHeader[] | undefined
  >([]);
  const [csvData, setCsvData] = useState<CSVData>([]);
  const [downloadReady, setDownloadReady] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const momentCsvDate: TMomentFormat = useContext(DEPRECATEDCsvDateFormat);
  const dateStr: string = moment(Date.now()).format(momentCsvDate.format);
  const csvFileName: string = `${filename} - ${dateStr}.csv`;

  useEffect(() => {
    if (csvLinkRef && csvLinkRef.current && downloadReady) {
      // @ts-ignore
      csvLinkRef.current.link.click();
      setDownloadReady(false);
    }
  }, [downloadReady, csvLinkRef]);

  const onClickDownloadCSV = async () => {
    try {
      setIsButtonLoading(true);
      const { headers, data } = await getData();
      setCsvHeaders(headers);
      setCsvData(data);
      setDownloadReady(true);
      setIsButtonLoading(false);
    } catch (error) {
      message.error(`Failed to download`);
      console.error("Download csv fail", error);
      setIsButtonLoading(false);
    }
  };

  return (
    <TestIDWrapper testID={testID}>
      <COREButton
        testID={(testID + "-button") as TestID}
        loading={isButtonLoading}
        disabled={disabled}
        onClick={() => onClickDownloadCSV()}
        size={buttonSize}
        {...(buttonType === "action"
          ? {
              type: "action",
              icon: icon,
            }
          : {
              type: buttonType,
              icon: icon,
            })}
      >
        {children}
      </COREButton>
      <CSVLink
        data={escapeDoubleQuotes(csvData)}
        headers={escapeDoubleQuotes(csvHeaders)}
        filename={csvFileName}
        // @ts-ignore
        ref={csvLinkRef}
        className="hidden"
        target="_blank"
      />
    </TestIDWrapper>
  );
};
