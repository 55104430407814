import { ReleaseNote } from "./shared/customHoooks/useUserReleaseNote";

export const releaseNoteData: ReleaseNote[] = [
  // // Example data
  // {
  //   version: "1.2.3",
  //   date: "11-Sep-2024",
  //   changes: [
  //     "Added feature A",
  //     "Fixed bug in feature B",
  //     "Improved performance for feature C",
  //   ],
  // },
  // {
  //   version: "1.2.2",
  //   date: "10-Sep-2024",
  //   changes: ["Fixed minor UI issue", "Updated documentation"],
  // },
  {
    version: "15.0.0",
    date: "29-Nov-2024",
    changes: [
      "This update brings a major revision to the way you can find markets information across the app.",
      "The new ‘Markets’ section replaces ‘Market Prices’ and unifies market data including live pricing, daily curves and trade timeline into a common navigable location.",
      "Within ‘Markets’ each product class is populated with geographic markets providing all relevant information in a consolidated manner.",
      "Key features including ‘Project Marketplace’ and ‘Chart Builder’ have been elevated to top level elements in the main menu panel for easier access.",
      "‘Trade log’ has been renamed to ‘Trade timeline’ and is now located in the Markets section. The capability of this feature has been increased significantly, allowing you to organise the data how you prefer, including: grouping by attributes, re-ordering the columns, show or hide columns that are not relevant to you and adjust the row density to your preference.",
    ],
  },
  {
    version: "14.0.0",
    date: "15-Nov-2024",
    changes: [
      "Introducing the global ‘CORE Markets home’ page. This page showcases the latest live pricing and insights from our Markets and Advisory teams.",
      "The ‘Mark as favourite’ dashboard functionality has been upgraded to ‘Mark as homepage’. You can now select any page that features a home icon (found on the top right of the page) to be your landing page each time you log in.",
      "The CORE Markets ‘Insights’ section is now available. You can access premium content – including reports, market updates and other insights from our experts.",
      "ACCU Market Forecast subscribers can now access quarterly releases of this report via the ‘Reports’ page.",
      "Create dashboards quickly and easily with a selection of new templates found using the ‘Create New’ dashboard menu button.",
      "Select from a range of new and improved dashboard widgets that will power-up your custom dashboards experience.",
      "Visit the new ‘Markets snapshot’ page to see live pricing across a range of the most active products in market.",
    ],
  },
];
