import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { CollapseProps } from "antd/lib/collapse";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import "./COREContainerPanel.less";
import { HOCErrorBoundary } from "../../shared/errors/ErrorBoundary";
import classNames from "classnames";

export type COREAccordionProps = {
  testID: TestID;
  spacing?: "xl" | "xs" | "none";
} & CollapseProps;

const COREAccordionNoError: React.FC<COREAccordionProps> = ({
  testID,
  spacing = "xl",
  accordion = true,
  children,
  expandIcon = ({ isActive }) => (
    <CaretRightOutlined
      className={"header-expand-icon"}
      rotate={isActive ? 90 : 0}
    />
  ),
  bordered,
  ...props
}) => (
  <TestIDWrapper
    testID={testID}
    className={classNames("core-container", {
      "core-container-space-small": spacing === "xs",
      "core-container-space-none": spacing === "none",
    })}
  >
    <Collapse
      {...props}
      accordion={accordion}
      expandIcon={expandIcon}
      className={classNames({
        "core-container-space-small": spacing === "xs",
      })}
      bordered={bordered}
    >
      {children}
    </Collapse>
  </TestIDWrapper>
);

export const COREAccordion = HOCErrorBoundary(COREAccordionNoError);
