import React from "react";
import { withRouter } from "react-router-dom";
import { appQueryClient as queryClient } from "../../shared/state/appQueryClient";
import { useUserLoggedInStatus } from "../../shared/state/user";

const logPageChange = async (pathname) => {
  try {
    await queryClient.fetchQuery({
      staleTime: 0,
      queryKey: [
        "recordUserVisitPage",
        {
          action: "recordUserVisitPage",
          fetchOptions: { method: "POST" },
          body: {
            page: pathname,
            version: process.env.REACT_APP_TAG || " Unknown",
          },
          enabled: true,
        },
      ],
    });
  } catch (error) {
    console.error("record user visit page failed", error);
  }
};

function callUserIsLoggedInStatus(Component) {
  return function WrappedComponent(props) {
    const isLoggedIn = useUserLoggedInStatus();
    return <Component {...props} isLoggedIn={isLoggedIn} />;
  };
}

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window && window.scrollTo(0, 0);
      const isLoggedIn = this.props.isLoggedIn;
      if (isLoggedIn) logPageChange(this.props.location.pathname);
    }
  }

  render() {
    return null;
  }
}

export const ScrollToTopWithRouter = callUserIsLoggedInStatus(
  withRouter(ScrollToTop)
);
