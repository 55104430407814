import { FetchQueryOptions } from "react-query";
import { appQueryClient, mutation } from "../state/appQueryClient";
import { useAPIQueries, UseAPIQuery, useAPIQuery } from "./useAPITypes";
import { AxiosError, AxiosResponse } from "axios";
import { generateSequence, removeWhiteSpace } from "../global";
import { COREPaginationProps } from "../../COREDesignSystem/Navigation/COREPagination";
import { DurationInputArg2 } from "moment";
import { NonEmptyRange } from "../date/ranges";
import { useAPIQueriesPerDates } from "./useAPIQueriesPerDates";
import { useCallback } from "react";
import {
  LocalStorageUntilLogoutKey,
  useLocalStorageUntilLogout,
} from "./useLocalStorageUntilLogout";

export type APIResponse<T> =
  | { hasError: false; readonly data: AxiosResponse<T> }
  | { hasError: true; error: APIError };

export type APIError = {
  message: string;
  code: string;
  hint: string | null;
  details: string | null;
};

export type UpdatableItem<
  Response,
  UpdateRequest = Response,
  UpdateResponse = Response,
  DeleteResponse = Response
> =
  | UpdatableItemSync<Response, UpdateRequest, UpdateResponse, DeleteResponse>
  | {
      loading: boolean;
      error: Error | false;
      sync: false;
      data: undefined;
      delete: undefined;
      update: undefined;
    };

export type UpdatableItemSync<
  Response,
  UpdateRequest = Response,
  UpdateResponse = Response,
  DeleteResponse = Response
> = {
  loading: boolean;
  error: Error | false;
  sync: true;
  data: Response;
  delete: () => Promise<APIResponse<DeleteResponse>>;
  update: (newItem: UpdateRequest) => Promise<APIResponse<UpdateResponse>>;
};

export type TableOptions = {
  pagination: Required<
    Pick<
      COREPaginationProps,
      "total" | "current" | "hideOnSinglePage" | "pageSize"
    >
  >;
  filters?: any;
};

export type useUpdatableFunction<
  Params,
  Response,
  AddRequest = Response,
  AddResponse = Response,
  UpdateRequest = Response,
  UpdateResponse = Response,
  DeleteResponse = Response
> = (
  args: Params,
  tableOption?: TableOptions
) => UpdatableHookWithPaginate<
  Response,
  AddRequest,
  AddResponse,
  UpdateRequest,
  UpdateResponse,
  DeleteResponse
>;

const missingSetupError = {
  hasError: true,
  error: {
    message: "missing updatable setup",
    code: "",
    hint: null,
    details: null,
  } as APIError,
};

export type UpdatablesHook<
  Response,
  AddRequest = Response,
  AddResponse = Response,
  UpdateRequest = Response,
  UpdateResponse = Response,
  DeleteResponse = Response
> = {
  sync: boolean;
  loading: boolean;
  error: Error | false;
  headers: ReturnType<UseAPIQuery<Response>>["headers"];
  updatable: UpdatableItem<
    Response,
    UpdateRequest,
    UpdateResponse,
    DeleteResponse
  >[];
  add: (newItems: AddRequest) => Promise<APIResponse<AddResponse>>;
};

export type UpdatableHookWithPaginate<
  Response,
  AddRequest = Response,
  AddResponse = Response,
  UpdateRequest = Response,
  UpdateResponse = Response,
  DeleteResponse = Response
> = UpdatablesHook<
  Response,
  AddRequest,
  AddResponse,
  UpdateRequest,
  UpdateResponse,
  DeleteResponse
> & {
  progress: { percent: number };
  hasMore: boolean;
};

export type InvalidateQueryFirstParam = Parameters<
  typeof appQueryClient.invalidateQueries
>[0];
export const invalidateQueries = (
  queries?: InvalidateQueryFirstParam | InvalidateQueryFirstParam[]
) =>
  Array.isArray(queries)
    ? queries.forEach((q) => appQueryClient.invalidateQueries(q))
    : appQueryClient.invalidateQueries(queries);

export const displayAPIError = (error: APIError | undefined): string => {
  if (!error) return "";

  const { hint, details, message } = error;

  return [message, hint, details]
    .filter((messagePart) => messagePart !== null)
    .join(" ");
};

export const getContentRange = (
  headers: [string, string][]
): string | undefined => {
  const contentRange = headers.find(([h]) => h === "content-range");
  if (!contentRange) return undefined;
  return contentRange[1];
};

export const getMutator = async <T>(
  action: FetchQueryOptions<{ readonly data: T }> | undefined,
  invalidateQueriesArgs?:
    | InvalidateQueryFirstParam
    | InvalidateQueryFirstParam[]
) => {
  if (!action) {
    return missingSetupError as APIResponse<T>;
  }
  try {
    const data = await mutation<AxiosResponse<T>>(action);
    invalidateQueriesArgs && invalidateQueries(invalidateQueriesArgs);
    return {
      hasError: false,
      data: data,
    };
  } catch (errorRaw: unknown) {
    const error = errorRaw as Exclude<AxiosError<APIError>, undefined>;
    return {
      hasError: true,
      error: error.response?.data as APIError,
    };
  }
};

const addAction = async <
  Response,
  RawResponse,
  AddRequest,
  AddResponse,
  AddRequestToApi,
  UpdateResponse,
  UpdateRequest,
  UpdateRequestToApi,
  DeleteResponse
>(
  newItem: AddRequest,
  args: Updatables<
    Response,
    RawResponse,
    AddRequest,
    AddResponse,
    AddRequestToApi,
    UpdateRequest,
    UpdateResponse,
    UpdateRequestToApi,
    DeleteResponse
  >
) => {
  if (!args.add) {
    return missingSetupError as APIResponse<AddResponse>;
  }
  const transformData = args.transformToInsertAPI
    ? args.transformToInsertAPI(newItem)
    : newItem;
  return (await getMutator<AddResponse>(
    args.add(transformData),
    args.invalidateQueries
  )) as APIResponse<AddResponse>;
};

const updateAction = async <
  Response,
  RawResponse,
  AddRequest,
  AddResponse,
  AddRequestToApi,
  UpdateResponse,
  UpdateRequest,
  UpdateRequestToApi,
  DeleteResponse
>(
  newItem: UpdateRequest,
  value: Response,
  args: Updatables<
    Response,
    RawResponse,
    AddRequest,
    AddResponse,
    AddRequestToApi,
    UpdateRequest,
    UpdateResponse,
    UpdateRequestToApi,
    DeleteResponse
  >
) => {
  if (!args.update) {
    return missingSetupError as APIResponse<UpdateResponse>;
  }
  const dataToApi = args.transformToUpdateAPI
    ? args.transformToUpdateAPI(newItem)
    : newItem;
  return (await getMutator<UpdateResponse>(
    args.update(dataToApi, value),
    args.invalidateQueries
  )) as APIResponse<UpdateResponse>;
};

const deleteAction = async <
  Response,
  RawResponse,
  AddRequest,
  AddResponse,
  AddRequestToApi,
  UpdateResponse,
  UpdateRequest,
  UpdateRequestToApi,
  DeleteResponse
>(
  value: Response,
  args: Updatables<
    Response,
    RawResponse,
    AddRequest,
    AddResponse,
    AddRequestToApi,
    UpdateRequest,
    UpdateResponse,
    UpdateRequestToApi,
    DeleteResponse
  >
) => {
  if (!args.delete) {
    return missingSetupError as APIResponse<DeleteResponse>;
  }
  return (await getMutator<DeleteResponse>(
    args.delete(value),
    args.invalidateQueries
  )) as APIResponse<DeleteResponse>;
};

export type Updatables<
  Response,
  RawResponse = Response,
  AddRequest = Response,
  AddResponse = Response,
  AddRequestToApi = AddRequest,
  UpdateRequest = Response,
  UpdateResponse = Response,
  UpdateRequestToApi = UpdateRequest,
  DeleteResponse = Response
> = {
  get: Parameters<UseAPIQuery<Response>>;
  add?: (
    newItem: AddRequest | AddRequestToApi
  ) => FetchQueryOptions<{ readonly data: AddResponse }>;
  update?: (
    newItem: UpdateRequest | UpdateRequestToApi,
    oldItem: Response
  ) => FetchQueryOptions<{ readonly data: UpdateResponse }>;
  delete?: (
    oldItem: Response
  ) => FetchQueryOptions<{ readonly data: DeleteResponse }>;
  invalidateQueries?: InvalidateQueryFirstParam;
  transformToRichTypes: (record: RawResponse) => Response;
  transformToInsertAPI?: (record: AddRequest) => AddRequestToApi;
  transformToUpdateAPI?: (record: UpdateRequest) => UpdateRequestToApi;
};

export const buildParamsWithTableOptions = (
  params: object,
  tableOption?: TableOptions
) => {
  return {
    ...params,
    ...(tableOption && tableOption.pagination
      ? buildPaginationParams(tableOption.pagination)
      : {}),
  };
};

const buildPaginationParams = (pagination: TableOptions["pagination"]) => ({
  limit: pagination.pageSize,
  offset: (pagination.current - 1) * pagination.pageSize,
});

const getResponseObjectToUpdatable = <
  Response,
  RawResponse = Response,
  AddRequest = Response,
  AddResponse = Response,
  AddRequestToApi = AddRequest,
  UpdateRequest = Response,
  UpdateResponse = Response,
  UpdateRequestToApi = UpdateRequest,
  DeleteResponse = Response
>(
  response: ReturnType<UseAPIQuery<RawResponse[]>>,
  args: Updatables<
    Response,
    RawResponse,
    AddRequest,
    AddResponse,
    AddRequestToApi,
    UpdateRequest,
    UpdateResponse,
    UpdateRequestToApi,
    DeleteResponse
  >
): ((
  item: RawResponse
) => UpdatableItem<
  Response,
  UpdateRequest,
  UpdateResponse,
  DeleteResponse
>) => {
  return (
    item: RawResponse
  ): UpdatableItem<Response, UpdateRequest, UpdateResponse, DeleteResponse> => {
    const transformData: Response = args.transformToRichTypes(item);
    return {
      error: response.error,
      loading: response.loading,
      sync: true,
      data: transformData,
      update: async (newItem: UpdateRequest) =>
        (await updateAction(
          newItem,
          transformData,
          args
        )) as APIResponse<UpdateResponse>,
      delete: async () =>
        (await deleteAction(
          transformData,
          args
        )) as APIResponse<DeleteResponse>,
    };
  };
};

export const useUpdatables = <
  Response,
  RawResponse = Response,
  AddRequest = Response,
  AddResponse = Response,
  AddRequestToApi = AddRequest,
  UpdateRequest = Response,
  UpdateResponse = Response,
  UpdateRequestToApi = UpdateRequest,
  DeleteResponse = Response
>(
  args: Updatables<
    Response,
    RawResponse,
    AddRequest,
    AddResponse,
    AddRequestToApi,
    UpdateRequest,
    UpdateResponse,
    UpdateRequestToApi,
    DeleteResponse
  >,
  tableOption?: TableOptions
): UpdatablesHook<
  Response,
  AddRequest,
  AddResponse,
  UpdateRequest,
  UpdateResponse,
  DeleteResponse
> => {
  const action = args.get[0];
  const params = buildParamsWithTableOptions(args.get[1], tableOption);
  const skip = args.get[2];
  const body = args.get[3];
  const customHeader = args.get[4];
  const response = useAPIQuery<RawResponse[]>(
    action,
    params,
    skip,
    body,
    customHeader
  );

  const { loading, sync, error, headers } = response;

  const responseObjectToUpdatable = getResponseObjectToUpdatable(
    response,
    args
  );
  const updatable: UpdatableItem<
    Response,
    UpdateRequest,
    UpdateResponse,
    DeleteResponse
  >[] =
    response.data === undefined
      ? []
      : response.data.map(responseObjectToUpdatable);

  return {
    headers,
    sync: sync,
    loading: loading,
    error: error ? error : false,
    updatable,
    add: async (newItem: AddRequest) =>
      (await addAction(newItem, args)) as APIResponse<AddResponse>,
  };
};

export const getTotalRecords = <T>(
  header: ReturnType<UseAPIQuery<T>>["headers"] | undefined
) => {
  const contentRange = header ? getContentRange(header) : undefined;
  return contentRange ? parseInt(contentRange.split("/")[1]) : 0;
};

export const totalPageToLoad = <T>(
  pagesToLoad: number | undefined,
  header: ReturnType<UseAPIQuery<T>>["headers"] | undefined,
  limitRecordsPerPage: number
) => {
  const totalRecords: number = getTotalRecords(header);

  const pagesToLoadNow =
    pagesToLoad !== undefined &&
    pagesToLoad * limitRecordsPerPage <= totalRecords
      ? pagesToLoad
      : totalRecords
      ? Math.ceil(totalRecords / limitRecordsPerPage)
      : 0;

  return generateSequence(pagesToLoadNow);
};

const formatterUpdatable = <
  Response,
  RawResponse,
  AddRequest,
  AddResponse,
  AddRequestToApi,
  UpdateRequest,
  UpdateResponse,
  UpdateRequestToApi,
  DeleteResponse
>(
  allRequests: ReturnType<UseAPIQuery<RawResponse[]>>[],
  args: Updatables<
    Response,
    RawResponse,
    AddRequest,
    AddResponse,
    AddRequestToApi,
    UpdateRequest,
    UpdateResponse,
    UpdateRequestToApi,
    DeleteResponse
  >
) => {
  return allRequests
    .flatMap(
      (
        response: ReturnType<UseAPIQuery<RawResponse[]>>
      ): UpdatableItem<
        Response,
        UpdateRequest,
        UpdateResponse,
        DeleteResponse
      >[] => {
        const responseObjectToUpdatable = getResponseObjectToUpdatable(
          response,
          args
        );

        return response.data === undefined
          ? []
          : response.data.map(responseObjectToUpdatable);
      }
    )
    .filter((obj, index, arr) => {
      return (
        arr.findIndex(
          (el) => JSON.stringify(el.data) === JSON.stringify(obj.data)
        ) === index
      );
    });
};

export const useUpdatablesWithPaginate = <
  Response,
  RawResponse = Response,
  AddRequest = Response,
  AddResponse = Response,
  AddRequestToApi = AddRequest,
  UpdateRequest = Response,
  UpdateResponse = Response,
  UpdateRequestToApi = UpdateRequest,
  DeleteResponse = Response
>(
  args: Updatables<
    Response,
    RawResponse,
    AddRequest,
    AddResponse,
    AddRequestToApi,
    UpdateRequest,
    UpdateResponse,
    UpdateRequestToApi,
    DeleteResponse
  >,
  tableOption?: TableOptions,
  limitRecordsPerPage: number = 5,
  pagesToLoad: number | undefined = undefined
): UpdatableHookWithPaginate<
  Response,
  AddRequest,
  AddResponse,
  UpdateRequest,
  UpdateResponse,
  DeleteResponse
> => {
  const action = args.get[0];
  const params = {
    ...buildParamsWithTableOptions(args.get[1], tableOption),
    offset: 0,
    limit: limitRecordsPerPage,
  };
  const skip = args.get[2];
  const body = args.get[3];
  const customHeader = args.get[4];

  const firstResponse = useAPIQuery<RawResponse[]>(
    action,
    params,
    skip,
    body,
    customHeader
  );
  const {
    loading: loadingFirst,
    sync: syncFirst,
    error: errorFirst,
    headers: headersFirst,
  } = firstResponse;

  const pages = totalPageToLoad(pagesToLoad, headersFirst, limitRecordsPerPage);
  const shiftedPage = pages.slice(1);
  const bodies = shiftedPage.map((page) => {
    const params = {
      ...buildParamsWithTableOptions(args.get[1], tableOption),
      offset: page * limitRecordsPerPage,
      limit: limitRecordsPerPage,
    };
    return {
      params,
      skip: skip || !syncFirst,
    };
  });

  const {
    error,
    progress,
    results: responses,
  } = useAPIQueries<RawResponse[]>(action, bodies);

  const allRequests: ReturnType<UseAPIQuery<RawResponse[]>>[] = [
    firstResponse,
    ...(responses ?? []),
  ];

  const updatable: UpdatableItem<
    Response,
    UpdateRequest,
    UpdateResponse,
    DeleteResponse
  >[] = formatterUpdatable(allRequests, args);
  const totalRecords: number = getTotalRecords(headersFirst);
  const hasMoreToLoad = totalRecords !== 0 && updatable.length < totalRecords;
  const sync = responses?.some((r) => r.sync === false) ? false : syncFirst;
  const loading = responses?.some((r) => r.loading === true) || loadingFirst;

  return {
    headers: headersFirst,
    sync: sync,
    loading: loading,
    error: errorFirst ?? error ?? false,
    updatable: updatable,
    progress: progress,
    hasMore: sync && !loading && hasMoreToLoad,
    add: async (newItem: AddRequest) =>
      (await addAction(newItem, args)) as APIResponse<AddResponse>,
  };
};

export const useUpdatablesWithDatePaginate = <
  Response,
  RawResponse = Response,
  AddRequest = Response,
  AddResponse = Response,
  AddRequestToApi = AddRequest,
  UpdateRequest = Response,
  UpdateResponse = Response,
  UpdateRequestToApi = UpdateRequest,
  DeleteResponse = Response
>(
  args: Updatables<
    Response,
    RawResponse,
    AddRequest,
    AddResponse,
    AddRequestToApi,
    UpdateRequest,
    UpdateResponse,
    UpdateRequestToApi,
    DeleteResponse
  >,
  key?: keyof RawResponse | null,
  tableOption?: TableOptions,
  loadedRange?: NonEmptyRange,
  unit?: DurationInputArg2
): UpdatableHookWithPaginate<
  Response,
  AddRequest,
  AddResponse,
  UpdateRequest,
  UpdateResponse,
  DeleteResponse
> => {
  const action = args.get[0];
  const params = {
    ...buildParamsWithTableOptions(args.get[1], tableOption),
    ...(key && {
      offset: 0,
      limit: 20,
    }),
  };
  const skip = args.get[2];
  const body = args.get[3];
  const customHeader = args.get[4];

  const firstResponse = useAPIQuery<RawResponse[]>(
    action,
    params,
    skip,
    body,
    customHeader
  );
  const {
    loading: loadingFirst,
    sync: syncFirst,
    error: errorFirst,
    headers: headersFirst,
  } = firstResponse;

  const {
    error,
    progress,
    results: responses,
  } = useAPIQueriesPerDates(args, key, tableOption, loadedRange, unit);

  const allRequests: ReturnType<UseAPIQuery<RawResponse[]>>[] = [
    firstResponse,
    ...(responses && !skip && syncFirst ? responses : []),
  ];

  const updatable: UpdatableItem<
    Response,
    UpdateRequest,
    UpdateResponse,
    DeleteResponse
  >[] = formatterUpdatable(allRequests, args);
  const totalRecords: number = getTotalRecords(headersFirst);
  const hasMoreToLoad = totalRecords !== 0 && updatable.length < totalRecords;

  return {
    headers: headersFirst,
    sync: responses?.some((r) => r.sync === false) ? false : syncFirst,
    loading: responses?.some((r) => r.loading === true) || loadingFirst,
    error: errorFirst ?? error ?? false,
    updatable: updatable,
    progress: progress,
    hasMore: hasMoreToLoad,
    add: async (newItem: AddRequest) =>
      (await addAction(newItem, args)) as APIResponse<AddResponse>,
  };
};

type UpdatableHook<
  Response,
  AddRequest = Response,
  AddResponse = Response,
  UpdateRequest = Response,
  UpdateResponse = Response,
  DeleteResponse = Response
> = Omit<
  UpdatablesHook<
    Response,
    AddRequest,
    AddResponse,
    UpdateRequest,
    UpdateResponse,
    DeleteResponse
  >,
  "updatable"
> & {
  updatable:
    | UpdatableItem<Response, UpdateRequest, UpdateResponse, DeleteResponse>
    | undefined;
};

export const useUpdatable = <
  Response,
  RawResponse = Response,
  AddRequest = Response,
  AddResponse = Response,
  AddRequestToApi = AddRequest,
  UpdateRequest = Response,
  UpdateResponse = Response,
  UpdateRequestToApi = UpdateRequest,
  DeleteResponse = Response
>(
  args: Updatables<
    Response,
    RawResponse,
    AddRequest,
    AddResponse,
    AddRequestToApi,
    UpdateRequest,
    UpdateResponse,
    UpdateRequestToApi,
    DeleteResponse
  >
): UpdatableHook<
  Response,
  AddRequest,
  AddResponse,
  UpdateRequest,
  UpdateResponse,
  DeleteResponse
> => {
  const updatables = useUpdatables<
    Response,
    RawResponse,
    AddRequest,
    AddResponse,
    AddRequestToApi,
    UpdateRequest,
    UpdateResponse,
    UpdateRequestToApi,
    DeleteResponse
  >(args);
  return {
    ...updatables,
    updatable: updatables.updatable[0],
  };
};

export type Order =
  | "asc"
  | "desc"
  | "nullsfirst"
  | "nullslast"
  | "asc.nullsfirst"
  | "asc.nullslast"
  | "desc.nullsfirst"
  | "desc.nullslast";

type FieldsOrder<Fields extends string> = `${Fields}.${Order}` | Fields;
export type FieldsOrders<Fields extends string> =
  | FieldsOrder<Fields>
  | `${FieldsOrder<Fields>}, ${FieldsOrder<Fields>}`;

export type SortMode = "asc" | "desc";
export const useSortBy = (
  localStorageName: LocalStorageUntilLogoutKey,
  init: SortMode
): {
  sortBy: SortMode;
  onSortBy: () => void;
} => {
  const [sortBy, setSortBy] = useLocalStorageUntilLogout<SortMode>({
    key: localStorageName as LocalStorageUntilLogoutKey,
    initialValue: init,
  });

  const onSortBy = useCallback(() => {
    setSortBy(sortBy === "asc" ? "desc" : "asc");
  }, [sortBy, setSortBy]);
  return { sortBy, onSortBy };
};

export const sortedByOrderKey = <RecordType>(
  updatable: UpdatableItem<RecordType>[],
  sortKey: keyof RecordType,
  sortMode: SortMode
): UpdatableItem<RecordType>[] => {
  return updatable
    .map((i) => i)
    .sort((a, b) => {
      const valueA = (a.data && a.data[sortKey]) ?? 0;
      const valueB = (b.data && b.data[sortKey]) ?? 0;
      if (sortMode === "desc") {
        if (typeof valueA === "number" && typeof valueB === "number") {
          return valueB - valueA;
        }
        return removeWhiteSpace(String(valueB)).localeCompare(
          removeWhiteSpace(String(valueA))
        );
      }

      if (typeof valueA === "number" && typeof valueB === "number") {
        return valueA - valueB;
      }

      return removeWhiteSpace(String(valueA)).localeCompare(
        removeWhiteSpace(String(valueB))
      );
    });
};
