/* eslint-disable */
import { Portfolio, PortfolioPeriodScope } from "./usePortfolio";
import {
  PortfolioCategories as PortfolioCategoriesAdmin,
  PortfolioCategoryHeadings as PortfolioCategoryHeadingsAdmin,
  PortfolioPeriodScopeWSData as PortfolioPeriodScopeWSAdmin,
  PortfolioWSData as PortfolioWSDataAdmin,
} from "../../openapi-typescript/admin/models/portfolio";
import {
  PortfolioCategories as PortfolioCategoriesApproved,
  PortfolioCategoryHeadings as PortfolioCategoryHeadingsApproved,
  PortfolioPeriodScopeWSData as PortfolioPeriodScopeWSApproved,
  PortfolioWSData as PortfolioWSDataApproved,
} from "../../openapi-typescript/approved/models/portfolio";
import { keysToCamelCase } from "../../shared/global";
import { QueryClient } from "react-query";
import { WebsocketMessage } from "../../shared/state/websocket_helper";
import { QueryFilters } from "react-query/types/core/utils";
import { AxiosResponse } from "axios";

type PortfolioWSData = Portfolio &
  (PortfolioWSDataAdmin | PortfolioWSDataApproved);
export type PortfolioPeriodScopeWSData = PortfolioPeriodScope &
  (PortfolioPeriodScopeWSAdmin | PortfolioPeriodScopeWSApproved);
type PortfolioCategories =
  | PortfolioCategoriesAdmin
  | PortfolioCategoriesApproved;
type PortfolioCategoryHeadings =
  | PortfolioCategoryHeadingsAdmin
  | PortfolioCategoryHeadingsApproved;

const defaultBasePortfolio = {
  portfolioCategoryHeadings: [],
  portfolioCategories: [],
  headingLabels: [],
};
const mapPortfolioWSToRest = (
  wsRow: PortfolioWSData,
  basePortfolio: Portfolio | null
): Portfolio => {
  return {
    ...defaultBasePortfolio,
    ...basePortfolio,
    ...wsRow,
  };
};

const mapCategoryToPortfolio = (
  queryCategoriesHeading: AxiosResponse<PortfolioCategoryHeadings[]>,
  queryCategories: AxiosResponse<PortfolioCategories[]>
): Pick<Portfolio, "portfolioCategories" | "headingLabels"> => {
  const categoryDatas = queryCategories?.data ?? [];
  const formattedCategoryHeadings: PortfolioCategoryHeadings[] =
    queryCategoriesHeading?.data
      ? keysToCamelCase(queryCategoriesHeading.data)
      : [];
  const categoryHeadings = formattedCategoryHeadings.map((d) => d.headingName);
  const portfolioCategories: PortfolioCategories[] =
    keysToCamelCase(categoryDatas);

  return {
    portfolioCategories: portfolioCategories,
    headingLabels: categoryHeadings,
  };
};

let portfolioPeriodScopesMissingAPortfolio: PortfolioPeriodScopeWSData[] = [];

const addPPSForMissingPortfolio = (
  portfolioPeriodScope: PortfolioPeriodScopeWSData
) => {
  portfolioPeriodScopesMissingAPortfolio = [
    ...portfolioPeriodScopesMissingAPortfolio,
    portfolioPeriodScope,
  ];
};

const getAndRemoveMissingPortfolioPeriodScopes = (
  portfolioID: PortfolioWSData["id"]
): Portfolio["portfolioPeriodScopes"] => {
  const matchPPS = portfolioPeriodScopesMissingAPortfolio.filter(
    (d) => d.portfolio === portfolioID
  );

  portfolioPeriodScopesMissingAPortfolio =
    portfolioPeriodScopesMissingAPortfolio.filter(
      (d) => d.portfolio !== portfolioID
    );

  return matchPPS;
};

export const portfolioHandler = async (
  data: WebsocketMessage<PortfolioWSData>,
  queryClient: QueryClient
) => {
  if (!data.channel.startsWith("portfolio-company-")) return;
  const key = "portfolios";
  const operation = data.payload.operation;
  const row: PortfolioWSData = keysToCamelCase(data.payload.row);

  const queryFilter: QueryFilters = {
    predicate: (query) => (query.queryKey[0] as string).startsWith(key),
  };

  let queryCategoriesHeading: null | AxiosResponse<
      PortfolioCategoryHeadings[]
    > = null,
    queryCategories: null | AxiosResponse<PortfolioCategories[]> = null;
  if (operation === "INSERT") {
    queryClient.setQueriesData(
      queryFilter,
      (current: { data: Portfolio[] } | undefined) => {
        const curData: Portfolio[] = current?.data ?? [];
        const newData: Portfolio[] = [
          ...curData,
          ...[
            {
              ...mapPortfolioWSToRest(row, null),
              portfolioPeriodScopes: getAndRemoveMissingPortfolioPeriodScopes(
                row.id
              ),
            },
          ],
        ];

        return {
          ...current,
          data: newData,
        };
      }
    );
  }
  queryCategoriesHeading = await queryClient.fetchQuery({
    queryKey: [
      "portfolioCategoryHeadings",
      {
        action: "portfolioCategoryHeadings",
        params: {
          portfolio: `eq.${row.id}`,
        },
        enabled: true,
      },
    ],
  });
  queryCategories = await queryClient.fetchQuery({
    queryKey: [
      "getPortfolioCategories",
      {
        action: "getPortfolioCategories",
        params: {
          portfolio: `eq.${row.id}`,
        },
        enabled: true,
      },
    ],
  });

  queryClient.setQueriesData(
    queryFilter,
    (current: { data: Portfolio[] } | undefined) => {
      const curData: Portfolio[] = current?.data ?? [];
      const newData: Portfolio[] = curData?.map((d): Portfolio => {
        if (d.id !== row.id) {
          return d;
        }

        if (queryCategoriesHeading && queryCategories)
          return {
            ...mapPortfolioWSToRest(row, d),
            ...mapCategoryToPortfolio(queryCategoriesHeading, queryCategories),
            portfolioPeriodScopes: [
              ...(getAndRemoveMissingPortfolioPeriodScopes(row.id) ?? []),
              ...(d.portfolioPeriodScopes ?? []),
            ],
          };
        return {
          ...mapPortfolioWSToRest(row, d),
          portfolioPeriodScopes: [
            ...(getAndRemoveMissingPortfolioPeriodScopes(row.id) ?? []),
            ...(d.portfolioPeriodScopes ?? []),
          ],
        };
      });

      return {
        ...current,
        data: newData,
      };
    }
  );
};

const insertPortfolioPPSQuery = (
  current: { data: Portfolio[] } | undefined,
  webSocketPPSRow: PortfolioPeriodScopeWSData,
  ppsWithCalculated: PortfolioPeriodScopeWSData[]
) => {
  const curData: Portfolio[] = current?.data ?? [];
  const isMissingPortfolio: boolean = !curData.some(
    (d) => d.id === webSocketPPSRow.portfolio
  );
  if (isMissingPortfolio) {
    addPPSForMissingPortfolio(webSocketPPSRow);
    return current;
  }

  const newData: Portfolio[] = curData?.map((portfolio): Portfolio => {
    if (portfolio.id !== webSocketPPSRow.portfolio) return portfolio;
    const newPPSCalculate = ppsWithCalculated.find(
      (d) => d.id === webSocketPPSRow.id
    );
    return {
      ...portfolio,
      portfolioPeriodScopes: [
        ...(portfolio.portfolioPeriodScopes ?? []),
        ...[{ ...webSocketPPSRow, ...newPPSCalculate }],
      ],
    };
  });

  return {
    ...current,
    data: newData,
  };
};

export const updatePortfolioPPSQuery = (
  current: { data: Portfolio[] } | undefined,
  webSocketPPSRow: PortfolioPeriodScopeWSData,
  ppsWithCalculated: PortfolioPeriodScopeWSData[]
) => {
  const curData: Portfolio[] = current?.data ?? [];
  const newData: Portfolio[] = curData?.map((portfolio): Portfolio => {
    if (portfolio.id !== webSocketPPSRow.portfolio) return portfolio;
    const newPPS = portfolio.portfolioPeriodScopes?.map((pps) => {
      const newPPSCalculate = ppsWithCalculated.find((d) => d.id === pps.id);

      return pps.id === webSocketPPSRow.id
        ? {
            ...pps,
            ...webSocketPPSRow,
            ...newPPSCalculate,
          }
        : {
            ...pps,
            ...newPPSCalculate,
          };
    });

    return {
      ...portfolio,
      portfolioPeriodScopes: newPPS,
    };
  });

  return {
    ...current,
    data: newData,
  };
};

const filterPortfolioPeriodScopeWSToRest = (
  wsRow: PortfolioPeriodScopeWSData,
  basePortfolio: Portfolio | null
): Portfolio | null => {
  if (basePortfolio === null) {
    return null;
  }

  const newPPS = basePortfolio.portfolioPeriodScopes?.filter(
    (d) => d.id !== wsRow.id
  );

  return {
    ...basePortfolio,
    portfolioPeriodScopes: newPPS,
  };
};

const deletePortfolioPPSQuery = (
  current: { data: Portfolio[] } | undefined,
  webSocketPPSRow: PortfolioPeriodScopeWSData
) => {
  const curData: Portfolio[] = current?.data ?? [];
  const newData: Portfolio[] = curData?.map((portfolio): Portfolio => {
    if (portfolio.id !== webSocketPPSRow?.portfolio) return portfolio;

    const newPortfolio = filterPortfolioPeriodScopeWSToRest(
      webSocketPPSRow,
      portfolio
    );

    return newPortfolio ?? portfolio;
  });

  return {
    ...current,
    data: newData,
  };
};

let messagesToProcess: WebsocketMessage<PortfolioPeriodScopeWSData>[] = [];
let alreadyWaiting = false;
const debounceTimeout = 200;

const transformToPPS = (payload: PortfolioPeriodScopeWSData) => {
  const formattedWsRow: PortfolioPeriodScopeWSData = keysToCamelCase(payload);

  return {
    ...formattedWsRow,
    hasDependents: false,
    budget: null,
    targetEmissions: null,
    status: "N/A",
    grossEmissions: null,
    netEmissions: null,
    differenceEmissions: null,
    initiativeImpact: 0,
    offsetsAssigned: 0,
    priorGrossEmissions: null,
    priorPeriodGrossEmissionsRatioChange: null,
    diffPriorGrossEmissionsAndGrossEmissions: null,
    targetEmissionsCalculated: null,
  };
};

export const portfolioPeriodScopesHandler = (
  data: WebsocketMessage<PortfolioPeriodScopeWSData>,
  queryClient: QueryClient
) => {
  if (!data.channel.startsWith("portfolio-period-scope-company-")) return;

  messagesToProcess.push(data);
  if (alreadyWaiting) return; // already about to call no need to reschedule

  setTimeout(() => {
    portfolioPeriodScopesHandlerDebounced(
      messagesToProcess,
      queryClient
    ).then();
    messagesToProcess = [];
    alreadyWaiting = false;
  }, debounceTimeout);

  alreadyWaiting = true;
};

const portfolioPeriodScopesHandlerDebounced = async (
  messages: WebsocketMessage<PortfolioPeriodScopeWSData>[],
  queryClient: QueryClient
) => {
  const key = "portfolios";
  const queryFilter: QueryFilters = {
    predicate: (query) => (query.queryKey[0] as string).startsWith(key),
  };

  const distinctPortfolios = [
    ...new Set(messages.map((message) => message.payload.row.portfolio)),
  ];
  for (const portfolio of distinctPortfolios) {
    await queryClient
      .fetchQuery<AxiosResponse<PortfolioPeriodScopeWSData[]>>({
        queryKey: [
          "portfolioPeriodScopeWithCalculation",
          {
            action: "portfolioPeriodScopeWithCalculation",
            params: {
              portfolio: `eq.${portfolio}`,
            },
            enabled: true,
          },
        ],
        staleTime: 0,
      })
      .then((value) => {
        const formattedPPSCalculate: PortfolioPeriodScope[] = value.data
          ? keysToCamelCase(value.data)
          : [];

        queryClient.setQueriesData(
          queryFilter,
          (current: { data: Portfolio[] } | undefined) => {
            let result: typeof current = current;
            messages.forEach((data) => {
              const operation = data.payload.operation;
              const row: PortfolioPeriodScopeWSData = data.payload.row;
              const formattedWsRow: PortfolioPeriodScopeWSData =
                transformToPPS(row);
              switch (operation) {
                case "INSERT":
                  result = insertPortfolioPPSQuery(
                    { ...current, data: [...(result?.data ?? [])] },
                    formattedWsRow,
                    formattedPPSCalculate
                  );

                  break;
                case "UPDATE":
                  result = updatePortfolioPPSQuery(
                    { ...current, data: [...(result?.data ?? [])] },
                    formattedWsRow,
                    formattedPPSCalculate
                  );
                  break;
                case "DELETE":
                  result = deletePortfolioPPSQuery(
                    { ...current, data: [...(result?.data ?? [])] },
                    formattedWsRow
                  );
                  break;
              }
            });

            return {
              ...result,
              data: result?.data ?? [],
            };
          }
        );
      });
  }
};
