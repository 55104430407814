import { Col, Row } from "antd";
import Plotly, { Layout, PlotData } from "plotly.js/dist/plotly-myPlotly.js";
import React, { useEffect, useRef } from "react";
import { chartFont, spacing, uniqueByKey } from "../../shared/global";
import { Merge } from "../../shared/TypeScriptHelpers";
import { COREBody } from "../Typography/COREBody";
import { ColorHex, getColorName } from "../Content/COREColour";
import { COREHeading } from "../Typography/COREHeading";
import { CORETag, CORETagProps, CORETagStyle } from "../Content/CORETag";
import { ChartProps } from "./ChartHOC";
import {
  ChartDataType,
  COREChart,
  COREChartProps,
  downloadCSVFile,
} from "./COREChart";
import { drawImageIntoCanvas } from "./COREChartLegend";
import "./COREWaterfallChart.less";

export type Traces = {
  label: string;
  yaxis: number;
  color: PlotData["marker.color"];
  measure: "priorPeriod" | "relative" | "totals" | "target" | "differential";
  type?: string;
  tagStyle?: CORETagStyle;
  hovertemplate?: PlotData["hovertemplate"];
};

type Data = Partial<ChartDataType>;
type LegendProps = {
  data: Merge<
    Pick<CORETagProps, "label">,
    {
      color: ColorHex;
      tagStyle?: CORETagStyle;
    }
  >[];
};

export type COREWaterfallChartProps = Merge<
  Pick<
    COREChartProps,
    | "downloadPng"
    | "downloadCsv"
    | "testID"
    | "plotRef"
    | "titleRef"
    | "maxHeight"
    | "customLayout"
  >,
  {
    layout?: Partial<Layout>;
    traces: Traces[];
    target?: number;
    chartTitle?: string;
  }
>;

const Legend: React.FC<LegendProps> = ({ data }) => {
  return (
    <Row>
      <Col>
        <COREBody strong className={"legend-text"}>
          Legend
        </COREBody>
      </Col>
      <Col span={20}>
        <Row gutter={[spacing.xxs, 0]}>
          {data?.map((item) => (
            <Col key={`${item.label}`}>
              <CORETag
                type={"colourful"}
                label={item.label}
                colour={getColorName(item.color)}
                tagStyle={item.tagStyle ?? "solid"}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

const calculateYRemaining = (
  lastYAxisPosition: Traces["yaxis"],
  currentTraceAxis: Traces["yaxis"],
  measure: Traces["measure"],
  index: number
) => {
  if (index === 0) return currentTraceAxis;

  switch (measure) {
    case "relative":
      return (lastYAxisPosition -= currentTraceAxis);
    case "totals":
    case "target":
      return currentTraceAxis;
    case "differential":
      if (currentTraceAxis < 0) {
        return lastYAxisPosition;
      }
      return lastYAxisPosition - currentTraceAxis;
    default:
      return lastYAxisPosition;
  }
};

export const COREWaterfallChart: React.FC<
  COREWaterfallChartProps & ChartProps
> = ({
  traces,
  target,
  testID,
  plotRef,
  titleRef,
  chartTitle,
  setOnDownloadCSV,
  setOnDownloadPNG,
  setOnResetChart,
  displayTitle = true,
  layout,
  maxHeight,
  customLayout,
}) => {
  const legendRef = useRef<HTMLDivElement>(null);
  const uniqueByKeyTrace = uniqueByKey(traces, "type").filter((t) => t.type);
  const legendData: LegendProps["data"] = uniqueByKeyTrace.map((t) => {
    const color = Array.isArray(t.color) ? t.color[0] : t.color;
    return {
      label: t.type,
      color: color as ColorHex,
      ...(t.tagStyle && {
        tagStyle: t.tagStyle,
      }),
    };
  });
  const xData: Data["x"] = traces.map((trace) => trace.label);
  const lineColor = traces.map((trace) =>
    Array.isArray(trace.color) ? trace.color[0] : trace.color
  );
  let yData: Exclude<Data["y"], undefined | string[]> = [];
  let lastYRemaining = 0;
  const firstData: Data = {
    x: xData,
    y: yData,
    marker: {
      color: "rgba(1,1,1,0.0)",
    },
    type: "bar",
    hoverinfo: "none",
  };
  const data: Data[] = traces.map((trace, index): Data => {
    const yAxis = Array(traces.length).fill(0);
    if (trace.yaxis === 0 && trace.measure !== "relative") {
      yData.push(0);
    } else {
      lastYRemaining = calculateYRemaining(
        lastYRemaining,
        trace.yaxis,
        trace.measure,
        index
      );

      const yAxisStart: number =
        trace.measure === "totals" || trace.measure === "target" || index === 0
          ? 0
          : lastYRemaining;
      const yAxisEnd: number =
        trace.measure === "differential" ? Math.abs(trace.yaxis) : trace.yaxis;
      yData.push(yAxisStart);
      yAxis.splice(index, 1, yAxisEnd);
    }

    const hovertemplate = trace.hovertemplate ?? `%{x}: %{y}<extra></extra>`;

    return {
      x: xData,
      y: yAxis,
      marker: {
        ...(Array.isArray(trace.color)
          ? {
              color: trace.color[1],
              line: {
                color: lineColor,
                width: 1,
              },
            }
          : {
              color: trace.color,
            }),
      } as PlotData["marker"],
      type: "bar",
      ...(hovertemplate && { hovertemplate }),
    };
  });
  data.unshift(firstData);

  const chartLayout: Partial<Layout> & {
    filename?: string;
    isWaterFallChart?: true;
  } = {
    filename: chartTitle,
    isWaterFallChart: true,
    barmode: "stack",
    hovermode: "closest",
    height: 516,
    showlegend: false,
    font: {
      family: chartFont,
    },
    xaxis: {
      linecolor: "#F2F2F2",
      showline: false,
      mirror: "all",
      automargin: true,
      title: {
        font: {
          family: chartFont,
        },
      },
    },
    yaxis: {
      zerolinecolor: "#F2F2F2",
      linecolor: "#F2F2F2",
      showline: true,
      showgrid: true,
      title: {
        text: "tCO₂e",
        font: {
          family: chartFont,
        },
      },
      mirror: "all",
    },
    margin: { t: 10, b: 30, l: 60, r: 10 },
    ...(target && {
      shapes: [
        {
          type: "line",
          xref: "paper",
          x0: 0,
          y0: target,
          x1: 1,
          y1: target,
          line: {
            color: "#979A9B",
            width: 2,
            dash: "dot",
          },
        },
      ],
    }),
    ...layout,
  };

  useEffect(() => {
    const handleResetChart = () => {
      if (!plotRef?.current) {
        return;
      }
      Plotly.relayout(plotRef.current.el, {
        xaxis: {
          ...plotRef.current.props.layout.xaxis,
          autorange: true,
        },
        yaxis: {
          ...plotRef.current.props.layout.yaxis,
          autorange: true,
        },
      });
    };

    const handleDownloadCSV = () => {
      if (!plotRef?.current) {
        return;
      }
      plotRef.current.props.layout.filename = chartTitle;
      downloadCSVFile(plotRef.current.el);
    };

    const handleDownloadPNG = () => {
      if (!plotRef?.current) {
        return;
      }
      delete plotRef.current.props.layout.margin?.b;
      drawImageIntoCanvas(plotRef, titleRef, legendRef, chartTitle);
    };

    if (setOnDownloadCSV) setOnDownloadCSV(() => handleDownloadCSV);
    if (setOnDownloadPNG) setOnDownloadPNG(() => handleDownloadPNG);
    if (setOnResetChart) setOnResetChart(() => handleResetChart);
  }, [
    chartTitle,
    legendRef,
    plotRef,
    setOnDownloadCSV,
    setOnDownloadPNG,
    setOnResetChart,
    titleRef,
  ]);

  return (
    <div className={"core-waterfall-chart"}>
      {displayTitle && (
        <div ref={titleRef} className={"chart-title"}>
          <COREHeading
            level={3}
            testID={`${testID}-core-waterfall-chart-title`}
          >
            {chartTitle}
          </COREHeading>
        </div>
      )}
      <div ref={legendRef}>
        <Legend data={legendData} />
      </div>
      <COREChart
        customLayout={customLayout}
        maxHeight={maxHeight}
        chartTitle={chartTitle}
        plotRef={plotRef}
        chartId={testID}
        data={data}
        layout={chartLayout}
        testID={`${testID}-core-waterfall-chart`}
        downloadCsv={false}
        downloadPng={false}
        legendRef={legendRef}
        titleRef={titleRef}
      />
    </div>
  );
};
