import React from "react";

export const momentFormat = {
  timestamp: {
    format: "lll",
  },
  server: {
    format: "YYYY-MM-DD",
  },
  date: {
    format: "MMM DD, YYYY",
  },
  time: {
    format: "LT",
  },
  month: {
    format: "M",
  },
  locale: {
    format: "en-AU",
  },
  dateMarket: {
    format: "MMM DD, YYYY",
  },
  csvDateTime: {
    format: "YYYY-MM-DD HH:mm:ss", // yyyy-mm-dd hh24:mi:ss
  },
  csvDate: {
    format: "YYYY-MM-DD", // yyyy-mm-dd
  },
  dateOnly: {
    format: "DD MMM YYYY", // yyyy-mm-dd
  },
  datePicker: {
    format: "DD-MMM-YYYY",
  },
};

export const DEPRECATEDDateFormatContext = React.createContext(
  momentFormat.timestamp
);
export const DEPRECATEDDateFormatContextStandard = React.createContext(
  momentFormat.server
);
export const DEPRECATEDDateFormatContextDate = React.createContext(
  momentFormat.date
);
export const DEPRECATEDDateFormatContextDateOnly = React.createContext(
  momentFormat.dateOnly
);
export const DEPRECATEDDateFormatContextTime = React.createContext(
  momentFormat.time
);
export const DEPRECATEDDateFormatMarket = React.createContext(
  momentFormat.dateMarket
);
export const DEPRECATEDDatePickerContext = React.createContext(
  momentFormat.datePicker
);
export const DEPRECATEDCsvDateTimeFormat = React.createContext(
  momentFormat.csvDateTime
);
export const DEPRECATEDCsvDateFormat = React.createContext(
  momentFormat.csvDate
);
