import { Col, Drawer, Row, Space } from "antd";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { ModalFeedback } from "../../modules/pages/feedback/ModalFeedback";
import { COREButton } from "../Action/COREButton";
import { COREDivider } from "../Layout/COREDivider";
import { COREMenu } from "../Navigation/COREMenu";
import { COREMenuItem } from "../Navigation/COREMenuItem";
import { COREIcon } from "../Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./COREUserDrawer.less";
import { COREBody } from "../Typography/COREBody";
import { HELP_URL, spacing } from "../../shared/global";

export type COREUserDrawerProps = {
  name: string;
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
  testId: TestID;
};

export const COREUserDrawer: React.FC<COREUserDrawerProps> = ({
  name,
  collapsed = true,
  setCollapsed,
  testId,
}) => {
  const onClose = () => {
    setCollapsed(true);
  };
  const history = useHistory();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);

  const location = useLocation();
  let selectedKeys: string[] = [];
  if (location && location.pathname === "/my-details")
    selectedKeys.push("my-details");
  if (location && location.pathname === "/help") selectedKeys.push("help");
  return (
    <Drawer
      title={
        <TestIDWrapper testID={(testId + "-title") as TestID}>
          <Row wrap={false} align={"middle"} justify={"space-between"}>
            <Col>
              <Space size={8}>
                <COREIcon
                  icon={icon({ name: "user", style: "regular" })}
                  size={"xs"}
                  className={"user-icon"}
                />
                <COREBody
                  marginBottom={false}
                  type={"p2"}
                  strong
                  testID={(testId + "-username") as TestID}
                >
                  {name}
                </COREBody>
              </Space>
            </Col>
            <Col>
              <COREButton
                testID={(testId + `-close-btn`) as TestID}
                onClick={onClose}
                type={"action"}
                size={"lg"}
                icon={
                  <COREIcon
                    icon={icon({ name: "circle-xmark", style: "solid" })}
                  />
                }
              />
            </Col>
          </Row>
        </TestIDWrapper>
      }
      placement="right"
      visible={!collapsed}
      closable={false}
      className={"core-user-drawer"}
      onClose={onClose}
    >
      <TestIDWrapper testID={(testId + "-body") as TestID}>
        <COREMenu
          className={"user-drawer-menu"}
          selectedKeys={selectedKeys}
          testID={(testId + "-menu") as TestID}
        >
          <COREMenuItem
            key={"my-details"}
            icon={<COREIcon icon={icon({ name: "user", style: "regular" })} />}
            testID={(testId + "-menu-my-details") as TestID}
            onClick={() => {
              history.push(`/my-details`);
            }}
          >
            My Details
          </COREMenuItem>
          <COREMenuItem
            key={"preference"}
            icon={<COREIcon icon={icon({ name: "gear", style: "regular" })} />}
            testID={(testId + "-menu-preference") as TestID}
            disabled
          >
            Preferences
          </COREMenuItem>
          <COREMenuItem
            key={"project-watchlist"}
            icon={
              <COREIcon icon={icon({ name: "bookmark", style: "regular" })} />
            }
            testID={(testId + "-menu-project-watchlist") as TestID}
            style={{ display: `none` }}
          >
            Project Watchlist
          </COREMenuItem>
          <COREMenuItem
            key={"help"}
            icon={
              <COREIcon
                icon={icon({ name: "circle-question", style: "regular" })}
              />
            }
            testID={(testId + "-menu-help") as TestID}
            onClick={() => window.open(HELP_URL)}
          >
            Help / FAQ
          </COREMenuItem>
          <COREMenuItem
            key={"support"}
            icon={
              <COREIcon icon={icon({ name: "envelope", style: "regular" })} />
            }
            testID={(testId + "-menu-support") as TestID}
            onClick={() => setIsFeedbackModalOpen(true)}
          >
            Support
          </COREMenuItem>
        </COREMenu>
        <Row gutter={[0, spacing.md]} className={"bottom-container"}>
          <Col span={24}>
            <div className={"divider"}>
              <COREDivider space={"sm"} />
            </div>
          </Col>
          <Col span={24}>
            <COREButton
              size={"lg"}
              danger
              icon={
                <COREIcon
                  icon={icon({
                    name: "right-from-bracket",
                    style: "regular",
                  })}
                />
              }
              onClick={() => {
                history.push(`/logout`);
              }}
            >
              Log out
            </COREButton>
          </Col>
        </Row>
      </TestIDWrapper>
      <ModalFeedback
        isFeedbackModalOpen={isFeedbackModalOpen}
        setIsFeedbackModalOpen={setIsFeedbackModalOpen}
      />
    </Drawer>
  );
};
