import { useAPIQuery } from "../../../shared/customHoooks/useAPITypes";
import { keysToCamelCase } from "../../../shared/global";
import { useInterval } from "react-use";
import { Query, QueryClient } from "react-query";
import { QueryFilters } from "react-query/types/core/utils";
import { WebsocketMessage } from "../../../shared/state/websocket_helper";

const fileRefreshInterval = 30000;

export type JobStatus =
  | "pending"
  | "in progress"
  | "done"
  | "failed"
  | "pending success job";

type JobError = Object;
export type Job = {
  id: number;
  queuedTime: string;
  startProcessedTime: string | null;
  endProcessedTime: string | null;
  function: string | null;
  status: JobStatus;
  error: JobError;
  fullErrors: JobError[] | null;
  runnerId: string | null;
  parentJob: number | null;
  postSuccessJob: number | null;
  progress: number;
  childPostStatus: JobStatus;
  fullStatus: JobStatus;
};
type JobWs = Omit<
  Job,
  "fullErrors" | "progress" | "fullStatus" | "childPostStatus"
>;
export const useJobs = (ids: (null | Job["id"])[]) => {
  const lookupIds: number[] = ids.filter((id) => id !== null) as number[];
  const { data, ...rest } = useAPIQuery<Job[]>(
    "jobDetails",
    {
      id: `in.(${lookupIds.join(",")})`,
    },
    lookupIds.length === 0
  );
  useInterval(() => {
    rest.forceRefresh();
  }, fileRefreshInterval);
  return {
    ...rest,
    jobs: ids.map((id): Job | undefined => {
      return keysToCamelCase(data?.find((d) => d.id === id));
    }),
  };
};

const mapWSToRest = (wsRow: JobWs, baseRestData: Job | null): Job => {
  if (baseRestData === null) {
    // fake initial data
    return {
      fullErrors: null,
      progress: 0,
      childPostStatus: "done",
      fullStatus: "done",
      ...wsRow,
    };
  }
  return { ...baseRestData, ...wsRow };
};
export const jobsHandler = (
  data: WebsocketMessage<JobWs>,
  queryClient: QueryClient
) => {
  if (!data.channel.startsWith("job-")) return;
  const key = "jobDetails";
  const operation = data.payload.operation;
  const row: JobWs = data.payload.row;
  const queryFilter: QueryFilters = {
    predicate: (query: Query<unknown, unknown, unknown>): boolean => {
      if (!query.hasOwnProperty("queryKey")) {
        return false;
      }
      const a: string = query.queryKey[0] as string;
      if (!a.includes(key)) {
        return false;
      }
      const id: string = (query.queryKey[1] as { params: { id: string } })
        .params.id as string;

      const ids = id.substr(4, -1).split(",");

      return ids.includes(data.payload.row.id.toString());
    },
  };
  queryClient.setQueriesData(
    queryFilter,
    (current: { data: Job[] } | undefined) => {
      const curData: Job[] = keysToCamelCase(current?.data);
      const newData: Job[] =
        operation === "INSERT"
          ? [...curData, mapWSToRest(row, null)]
          : curData.map(
              (d): Job => (d.id === row.id ? mapWSToRest(row, d) : d)
            );
      return {
        ...current,
        data: newData,
      };
    }
  );
  queryClient.invalidateQueries(queryFilter);
};
