import { useAPIQueries, UseAPIQueriesResponse } from "./useAPITypes";
import {
  dateFormatWithTimeZone,
  formatNonEmptyRangeToRangeFilter,
} from "../date/DateTime";
import {
  createArrayOfRangeBetweenDates,
  createRange,
  NonEmptyRange,
  Range,
} from "../date/ranges";
import {
  Updatables,
  TableOptions,
  buildParamsWithTableOptions,
} from "./useUpdatable";
import { DurationInputArg2 } from "moment";
import { toSnake } from "../global";

export const useAPIQueriesPerDates = <
  Response,
  RawResponse = Response,
  AddRequest = Response,
  AddResponse = Response,
  AddRequestToApi = AddRequest,
  UpdateRequest = Response,
  UpdateResponse = Response,
  UpdateRequestToApi = UpdateRequest,
  DeleteResponse = Response
>(
  args: Updatables<
    Response,
    RawResponse,
    AddRequest,
    AddResponse,
    AddRequestToApi,
    UpdateRequest,
    UpdateResponse,
    UpdateRequestToApi,
    DeleteResponse
  >,
  key?: keyof RawResponse | null,
  tableOption?: TableOptions,
  loadedRange?: NonEmptyRange,
  unit?: DurationInputArg2
): Omit<UseAPIQueriesResponse<RawResponse[]>, "forceRefresh"> => {
  const dateUnit = unit ?? "day";
  const action = args.get[0];
  const datesArray = loadedRange
    ? createArrayOfRangeBetweenDates(loadedRange, dateUnit, undefined)
    : [];

  const bodies =
    (key &&
      datesArray?.reverse().flatMap((date: Range) => {
        if (date === "empty" || !date.from || !date.to) return [];
        const currentRange = createRange(
          date?.from.clone(),
          date?.to.clone(),
          "[)"
        );
        const filterValue = formatNonEmptyRangeToRangeFilter(
          currentRange,
          toSnake(key as string),
          dateFormatWithTimeZone
        );
        const params = {
          ...buildParamsWithTableOptions(args.get[1], tableOption),
          ...{ and: filterValue },
        };
        return {
          params,
        };
      })) ??
    [];

  return useAPIQueries<RawResponse[]>(action, bodies);
};
