import React from "react";
import { COREError } from "../Content/COREError";
import { CORELoading } from "../Feedback/CORELoading";
import { Endpoint } from "../Form/CORESelectAPI";
import { useAPIQuery } from "../../shared/customHoooks/useAPI";
import { CORERadarChart, CORERadarChartProps } from "./CORERadarChart";

export type CORERadarChartAPIProps<
  T extends object,
  Input extends object = T
> = Omit<CORERadarChartProps, "traces"> & {
  callback?: (data: T[]) => void;
  endpoint: Endpoint;
  params?: Input;
  transform: (arg: T[]) => CORERadarChartProps["traces"];
};

export const CORERadarChartAPI = <T extends object, Input extends object>({
  endpoint,
  params,
  transform,
  ...props
}: CORERadarChartAPIProps<T, Input>) => {
  const {
    sync,
    loading,
    error,
    data: { data },
  } = useAPIQuery(endpoint, {
    ...params,
  });
  if (error) return <COREError />;

  const traces = transform(data);

  return (
    <CORELoading loading={loading && !sync} size={"lg"}>
      <CORERadarChart {...props} traces={traces} />
    </CORELoading>
  );
};
