import React, { useState } from "react";
import { Form, Input, message, Modal, Radio } from "antd";
import { appQueryClient as queryClient } from "../../../shared/state/appQueryClient";

export const ModalFeedback = ({
  isFeedbackModalOpen,
  setIsFeedbackModalOpen,
}: {
  isFeedbackModalOpen: boolean;
  setIsFeedbackModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const [feedbackOption, setFeedbackOption] = useState("feedback");
  const [form] = Form.useForm();

  const onCancelForm = (): void => {
    form.resetFields();
    setIsFeedbackModalOpen(false);
  };

  const onSubmitForm = (): void => {
    form.validateFields().then((values) => {
      queryClient
        .fetchQuery({
          queryKey: [
            "feedbacks",
            {
              action: "feedbacks",
              fetchOptions: {
                body: JSON.stringify({
                  type: feedbackOption,
                  summary: values.summary, // eslint-disable-next-line camelcase
                  tell_us_more: values.detail ?? null,
                }),
              },
              body: {
                body: JSON.stringify({
                  type: feedbackOption,
                  summary: values.summary, // eslint-disable-next-line camelcase
                  tell_us_more: values.detail ?? null,
                }),
              }.body,
              enabled: true,
            },
          ],
        })
        .then(() => {
          message.success("Your feedback has been sent");
          form.resetFields();
          setIsFeedbackModalOpen(false);
        })
        .catch((error: Error) => {
          message.error(error.toString());
        });
    });
  };

  return (
    <>
      <Modal
        title="Feedback"
        okText="Submit"
        okButtonProps={{ htmlType: "submit" }}
        visible={isFeedbackModalOpen}
        onCancel={onCancelForm}
        onOk={onSubmitForm}
      >
        <Form form={form}>
          <h2>Feedback please</h2>
          <p>
            We would love to hear your thoughts, suggestions, problems, or
            general feedback so we can make our product even better.
          </p>
          <Form.Item>
            <Radio.Group
              value={feedbackOption}
              onChange={(e) => setFeedbackOption(e.target.value)}
            >
              <Radio value={"feedback"}>Feedback</Radio>
              <Radio value={"problem"}>Problem</Radio>
              <Radio value={"suggestion"}>Suggestion</Radio>
              <Radio value={"question"}>Question</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Summary"
            name="summary"
            rules={[{ required: true, message: "Please input summary!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Tell us more!" name="detail">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
