import { useEffect } from "react";
import { strToRange } from "../../shared/date/ranges";
import { useAPIRange } from "../../shared/customHoooks/useAPI";
import { message } from "antd";
import { useUserLoggedInStatus } from "../../shared/state/user";
import { camelize } from "../../shared/globals";

const configTree = [
  {
    treeName: "electricityTree",
    tableName: {
      price: "dispatch", //  line
      demand: "dispatch", //  stacked
      generation: "dispatch", //  stacked
      availability: "dispatch", //  stacked
      rooftop: "rooftop", //  stacked
      "stpasa demand": "stpasa",
      "mtpasa demand": "mtpasa",
    },
    columnNames: {
      price: "rrp",
      demand: "clearedsupply",
      generation: "dispatchablegeneration",
      // semi-sched gen: "semischedule_clearedmw"
      availability: "availablegeneration",
      rooftop: "power",
      "stpasa demand": "demand50",
      "mtpasa demand": "demand50",
    },
    aggFunc: {
      price: "avg", //  line
      demand: "avg", //  stacked
      generation: "avg", //  stacked
      availability: "avg", //  stacked
      rooftop: "avg", //  stacked
      "stpasa demand": "avg",
      "mtpasa demand": "avg",
    },
    axis: {
      price: "$/MWh",
      demand: "MW",
      generation: "MW",
      availability: "MW",
      rooftop: "MW",
      "stpasa demand": "MW",
      "mtpasa demand": "MW",
    },
    axisSide: {
      price: "left",
      demand: "right",
      generation: "right",
      availability: "right",
      rooftop: "right",
      "stpasa demand": "right",
      "mtpasa demand": "right",
    },
    value: "aemoId",
  },
  {
    treeName: "FCASTree",
    tableName: "dispatch",
    columnNames: {
      "raise 6 sec rrp": "raise6secrrp",
      "raise 60 sec rrp": "raise60secrrp",
      "raise 5 min rrp": "raise5minrrp",
      "raise reg rrp": "raiseregrrp",
      "lower 6 sec rrp": "lower6secrrp",
      "lower 60 sec rrp": "lower60secrrp",
      "lower 5 min rrp": "lower5minrrp",
      "lower reg rrp": "lowerregrrp",
    },
    axis: "$/MWh",
    axisSide: "left",
    value: "aemoId",
  },
  {
    treeName: "generationProfileTree",
    tableName: "generation_profile_shapes",
    columnNames: "generation",
    axis: "MWh",
    axisSide: "right",
    value: "id",
  },
  {
    treeName: "loadProfileTree",
    tableName: "load_profile_shapes",
    columnNames: "load",
    axis: "MWh",
    axisSide: "right",
    value: "id",
  },
  {
    treeName: "forwardPriceCurveTree",
    tableName: "forward_price_curve_shapes",
    columnNames: "price",
    axis: "MWh",
    axisSide: "right",
    value: "id",
  },
  {
    treeName: "weatherObservationTree",
    tableName: "weather",
    columnNames: {
      "max temp": "MAX_TEMP",
      "min temp": "MIN_TEMP",
      wind: "WIND",
      solar: "SOLAR",
    },
    value: "id",
    axis: {
      "max temp": "°C",
      "min temp": "°C",
      wind: "m/s",
      solar: "W/m²",
    },
    axisSide: {
      "max temp": "right",
      "min temp": "right",
      wind: "right",
      solar: "right",
    },
  },
  {
    treeName: "interconnectorsTree",
    tableName: "interconnector",
    columnNames: {
      "import limit": "importlimit",
      "export limit": "exportlimit",
      flow: "mwflow", // put flow last so it is drawn on top of limit lines
    },
    aggFunc: {
      flow: "avg",
      "import limit": "avg",
      "export limit": "avg",
    },
    value: "id",
    axis: "MW",
    axisSide: "right",
  },
  {
    treeName: "SCADATree",
    tableName: "scada",
    columnNames: "scadavalue",
    axis: "MW",
    axisSide: "right",
    value: "id",
  },
  {
    treeName: "productTree",
    tableName: "trading",
    columnNames: {
      "broker curve": "curve",
      trades: "trade_price",
    },
    aggFunc: {
      "broker curve": "avg",
      trades: "avg",
    },
    axis: "$/MWh",
    axisSide: "left",
    value: "id",
  },
  {
    treeName: "productVWAPsTree",
    tableName: "trading",
    columnNames: {
      vwap: "vwap",
    },
    aggFunc: {
      vwap: "avg",
    },
    axis: "$/MWh",
    axisSide: "left",
    value: "id",
  },
  {
    treeName: "environmentalProductTree",
    tableName: "trading",
    columnNames: {
      "broker curve": "curve",
      vwap: "vwap",
      trades: "trade_price",
      "strip trades": "trade_price",
    },
    aggFunc: {
      trades: "avg",
      "broker curve": "avg",
      "strip trades": "avg",
      vwap: "avg",
    },
    axis: "$/certificate",
    axisSide: "right",
    value: "id",
  },
];
export const aggOptions = [
  { batch: "month", value: "none", label: "5 Min" },
  { batch: "month", value: "half-hour", label: "30 Min" },
  { batch: "month", value: "day", label: "Daily" },
  { batch: "month", value: "month", label: "Monthly" },
  { batch: "quarter", value: "quarter", label: "Quarterly" },
  { batch: "year", value: "year", label: "Yearly" },
];

export function getChartBuilderApiConfig(allSelections, customInterval = null) {
  const as = allSelections === undefined ? [] : allSelections;
  const stcs = as.map((selection) => selectionToChart(selection));

  const apiOptions = stcs.map((stc) => {
    const range = strToRange(stc.apiOptions.dateRange);
    const interval = aggOptions.find(
      ({ value }) => value === stc.apiOptions.aggPeriod
    ).batch;
    const field = "dateRange";
    return {
      body: stc.apiOptions,
      rangeOptions: { range, interval: customInterval ?? interval, field },
    };
  });
  const chartOptions = stcs.map((stc) => stc.chartOptions);

  return apiOptions.map((a, i) => ({
    params: a,
    chartOptions: chartOptions[i],
  }));
}

const getColumnName = (treeSelection, selectionData) => {
  return treeSelection.hasOwnProperty("variable")
    ? selectionData.columnNames[treeSelection.variable.toLowerCase()]
    : selectionData.columnNames;
};

const getSelectionData = (treeSelection, selectionData, key) => {
  return typeof selectionData[key] === "object"
    ? selectionData[key][treeSelection.variable.toLowerCase()]
    : selectionData[key];
};

const getTraceName = (treeSelection, selectionData, columnName) => {
  return typeof treeSelection === "object" &&
    treeSelection.hasOwnProperty("traceName")
    ? treeSelection.traceName
    : `${selectionData.tableName} - ${columnName}`;
};

const selectionToChart = ({
  tree: treeName,
  selection: treeSelection,
  dates: [startDate, endDate],
  aggPeriod,
}) => {
  const selectionData = configTree.find((item) => item.treeName === treeName);

  if (!selectionData) return null;

  const columnName = getColumnName(treeSelection, selectionData);
  const treeValue = selectionData.hasOwnProperty("value")
    ? treeSelection[selectionData.value]
    : treeSelection;
  const axisTitle = getSelectionData(treeSelection, selectionData, "axis");
  const axisSide = getSelectionData(treeSelection, selectionData, "axisSide");
  const traceName = getTraceName(treeSelection, selectionData, columnName);
  const aggFunc = selectionData.hasOwnProperty("aggFunc")
    ? selectionData.aggFunc[treeSelection.variable.toLowerCase()]
    : "avg";
  const tableName = getSelectionData(treeSelection, selectionData, "tableName");

  const startDateStr = startDate.clone().format("Y-MM-DD");
  const endDateStr = endDate.clone().add(1, "days").format("Y-MM-DD");
  return {
    apiOptions: {
      tableName: tableName,
      columnName: columnName,
      selectedItem: encodeURIComponent(treeValue), // ideally this should be handled by our urlStringify method in urlUtil. But adding that there would risk breaking other areas. This should be refactored when we upgrade our aggregates chart.
      aggPeriod: aggPeriod,
      aggFunction: aggFunc,
      dateRange: `[${startDateStr},${endDateStr})`, // all of start day, and all of end day
    },
    chartOptions: {
      axisTitle,
      axisSide,
      traceName,
      treeName,
      variable: camelize(treeSelection.variable),
    },
  };
};

export function useChartBuilder(apiConfig) {
  const isLoggedIn = useUserLoggedInStatus();

  const apiOptions = apiConfig.map((ap) => ap.params);
  const chartOptions = apiConfig.map((ap) => ap.chartOptions);

  const apiOptionsNew = apiOptions.map((o) => {
    return {
      skip: !isLoggedIn,
      ...o,
    };
  });

  const { results, progress, error, forceRefresh, loading } = useAPIRange(
    "aggregates",
    apiOptionsNew
  );

  const responses = results.map(({ data }) => data);
  if (!loading && error) {
    console.error(error);
    message.error(
      "There was a error loading a chart. A refresh might fix the problem."
    );
  }

  useEffect(() => {
    const timer = setTimeout(() => forceRefresh(), 5 * 60 * 1000);
    return () => clearTimeout(timer);
  }, [forceRefresh]);

  return {
    error,
    loading,
    progress,
    data: responses.map((r, i) => ({
      data: r ? r : undefined,
      chartOptions: chartOptions[i],
    })),
  };
}
