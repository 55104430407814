import { useMemo } from "react";
import { FeatureFlag, useFeatureFlags } from "./useFeatureFlags";

type Widget = {
  i: string;
  sizes: Record<
    keyof ReactGridLayout.Layouts,
    Pick<ReactGridLayout.Layout, "w" | "h" | "x" | "y">
  >;
  props?: Record<string, string>;
};

type Layout = ReactGridLayout.Layout & {
  props?: Record<string, string>;
};

type DashboardLayout = Record<keyof ReactGridLayout.Layouts, Layout[]>;
type DashboardTemplateKey =
  | "Carbon dashboard"
  | "Environmental dashboard"
  | "Electricity dashboard";

const LivePriceWidget = "1|LivePriceWidget";
const dashboardTemplate: Record<DashboardTemplateKey, Widget[]> = {
  "Carbon dashboard": [
    {
      i: LivePriceWidget,
      props: { widgetType: "carbon" },
      sizes: {
        lg: { w: 9, h: 18, x: 0, y: 0 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "2|TradeTimeline",
      props: {},
      sizes: {
        lg: { w: 3, h: 18, x: 9, y: 0 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "3|MarketCommentaryWidget",
      props: { widgetType: "carbon" },
      sizes: {
        lg: { w: 3, h: 19, x: 0, y: 18 },
        md: { w: 6, h: 15, x: 6, y: 0 },
        sm: { w: 6, h: 15, x: 6, y: 0 },
        xs: { w: 6, h: 15, x: 6, y: 0 },
        xxs: { w: 6, h: 15, x: 6, y: 0 },
      },
    },
    {
      i: "4|DailyCurveTableWidget",
      props: { widgetType: "accu" },
      sizes: {
        lg: { w: 9, h: 19, x: 3, y: 18 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "5|DailyCurveHistoryChartWidget",
      props: { widgetType: "accu" },
      sizes: {
        lg: { w: 4, h: 20, x: 0, y: 37 },
        md: { w: 6, h: 15, x: 6, y: 0 },
        sm: { w: 6, h: 15, x: 6, y: 0 },
        xs: { w: 6, h: 15, x: 6, y: 0 },
        xxs: { w: 6, h: 15, x: 6, y: 0 },
      },
    },
    {
      i: "6|DailyCurveHistoryChartWidget",
      props: { widgetType: "vcu" },
      sizes: {
        lg: { w: 4, h: 20, x: 4, y: 37 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "7|DailyCurveHistoryChartWidget",
      props: { widgetType: "gs" },
      sizes: {
        lg: { w: 4, h: 20, x: 8, y: 37 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "8|OHLCPriceHistoryWidget",
      props: { widgetType: "accu" },
      sizes: {
        lg: { w: 4, h: 20, x: 0, y: 57 },
        md: { w: 6, h: 15, x: 6, y: 0 },
        sm: { w: 6, h: 15, x: 6, y: 0 },
        xs: { w: 6, h: 15, x: 6, y: 0 },
        xxs: { w: 6, h: 15, x: 6, y: 0 },
      },
    },
    {
      i: "9|ProjectFinder",
      props: {},
      sizes: {
        lg: { w: 8, h: 20, x: 4, y: 57 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "10|NewsAndAlertsWidget",
      props: {},
      sizes: {
        lg: { w: 4, h: 22, x: 0, y: 77 },
        md: { w: 6, h: 15, x: 6, y: 0 },
        sm: { w: 6, h: 15, x: 6, y: 0 },
        xs: { w: 6, h: 15, x: 6, y: 0 },
        xxs: { w: 6, h: 15, x: 6, y: 0 },
      },
    },
    {
      i: "11|ReportsWidget",
      props: {},
      sizes: {
        lg: { w: 4, h: 22, x: 4, y: 77 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "12|ArticlesAndUpdatesWidget",
      props: {},
      sizes: {
        lg: { w: 4, h: 22, x: 8, y: 77 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
  ],
  "Environmental dashboard": [
    {
      i: LivePriceWidget,
      props: {
        widgetType: "environmental",
      },
      sizes: {
        lg: { w: 8, h: 19, x: 0, y: 0 },
        md: { w: 6, h: 15, x: 6, y: 0 },
        sm: { w: 6, h: 15, x: 6, y: 0 },
        xs: { w: 6, h: 15, x: 6, y: 0 },
        xxs: { w: 6, h: 15, x: 6, y: 0 },
      },
    },
    {
      i: "2|TradeTimeline",
      sizes: {
        lg: { w: 4, h: 19, x: 8, y: 0 },
        md: { w: 6, h: 15, x: 6, y: 0 },
        sm: { w: 6, h: 15, x: 6, y: 0 },
        xs: { w: 6, h: 15, x: 6, y: 0 },
        xxs: { w: 6, h: 15, x: 6, y: 0 },
      },
    },
    {
      i: "3|MarketCommentaryWidget",
      props: {
        widgetType: "environmental",
      },
      sizes: {
        lg: { w: 4, h: 18, x: 0, y: 19 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "4|DailyCurveTableWidget",
      props: {
        widgetType: "lgc",
      },
      sizes: {
        lg: { w: 8, h: 18, x: 4, y: 19 },
        md: { w: 6, h: 15, x: 6, y: 0 },
        sm: { w: 6, h: 15, x: 6, y: 0 },
        xs: { w: 6, h: 15, x: 6, y: 0 },
        xxs: { w: 6, h: 15, x: 6, y: 0 },
      },
    },
    {
      i: "5|DailyCurveHistoryChartWidget",
      props: {
        widgetType: "lgc",
      },
      sizes: {
        lg: { w: 4, h: 19, x: 0, y: 37 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "6|DailyCurveHistoryChartWidget",
      props: {
        widgetType: "veec",
      },
      sizes: {
        lg: { w: 4, h: 19, x: 4, y: 37 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "7|DailyCurveHistoryChartWidget",
      props: {
        widgetType: "stc",
      },
      sizes: {
        lg: { w: 4, h: 19, x: 8, y: 37 },
        md: { w: 6, h: 15, x: 6, y: 0 },
        sm: { w: 6, h: 15, x: 6, y: 0 },
        xs: { w: 6, h: 15, x: 6, y: 0 },
        xxs: { w: 6, h: 15, x: 6, y: 0 },
      },
    },

    {
      i: "8|OHLCPriceHistoryWidget",
      props: {
        widgetType: "lgc",
      },
      sizes: {
        lg: { w: 4, h: 19, x: 0, y: 56 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "9|NewsAndAlertsWidget",
      sizes: {
        lg: { w: 4, h: 19, x: 4, y: 56 },
        md: { w: 6, h: 15, x: 6, y: 0 },
        sm: { w: 6, h: 15, x: 6, y: 0 },
        xs: { w: 6, h: 15, x: 6, y: 0 },
        xxs: { w: 6, h: 15, x: 6, y: 0 },
      },
    },
    {
      i: "10|ReportsWidget",
      sizes: {
        lg: { w: 4, h: 19, x: 8, y: 56 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "11|ArticlesAndUpdatesWidget",
      sizes: {
        lg: { w: 4, h: 17, x: 0, y: 75 },
        md: { w: 6, h: 15, x: 6, y: 0 },
        sm: { w: 6, h: 15, x: 6, y: 0 },
        xs: { w: 6, h: 15, x: 6, y: 0 },
        xxs: { w: 6, h: 15, x: 6, y: 0 },
      },
    },
  ],
  "Electricity dashboard": [
    {
      i: LivePriceWidget,
      props: { widgetType: "electricity" },
      sizes: {
        lg: { w: 6, h: 9, x: 0, y: 0 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "2|AemoPriceMapOverview",
      props: {},
      sizes: {
        lg: { w: 6, h: 26, x: 6, y: 0 },
        md: { w: 6, h: 26, x: 6, y: 0 },
        sm: { w: 3, h: 26, x: 6, y: 0 },
        xs: { w: 6, h: 26, x: 6, y: 0 },
        xxs: { w: 6, h: 26, x: 6, y: 0 },
      },
    },
    {
      i: "3|ChartBuilderDashboard",
      props: {},
      sizes: {
        lg: { w: 6, h: 17, x: 0, y: 9 },
        md: { w: 6, h: 13, x: 0, y: 0 },
        sm: { w: 3, h: 13, x: 0, y: 0 },
        xs: { w: 6, h: 13, x: 0, y: 0 },
        xxs: { w: 6, h: 13, x: 0, y: 0 },
      },
    },
    {
      i: "4|AemoMarketNotices",
      props: {},
      sizes: {
        lg: { w: 6, h: 18, x: 0, y: 26 },
        md: { w: 6, h: 15, x: 6, y: 0 },
        sm: { w: 6, h: 15, x: 6, y: 0 },
        xs: { w: 6, h: 15, x: 6, y: 0 },
        xxs: { w: 6, h: 15, x: 6, y: 0 },
      },
    },
    {
      i: "5|NewsAndAlertsWidget",
      props: {},
      sizes: {
        lg: { w: 6, h: 18, x: 6, y: 26 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
    {
      i: "6|ReportsWidget",
      props: {},
      sizes: {
        lg: { w: 6, h: 21, x: 6, y: 44 },
        md: { w: 6, h: 15, x: 6, y: 0 },
        sm: { w: 6, h: 15, x: 6, y: 0 },
        xs: { w: 6, h: 15, x: 6, y: 0 },
        xxs: { w: 6, h: 15, x: 6, y: 0 },
      },
    },
    {
      i: "7|ArticlesAndUpdatesWidget",
      props: {},
      sizes: {
        lg: { w: 6, h: 21, x: 0, y: 44 },
        md: { w: 6, h: 15, x: 0, y: 0 },
        sm: { w: 6, h: 15, x: 0, y: 0 },
        xs: { w: 6, h: 15, x: 0, y: 0 },
        xxs: { w: 6, h: 15, x: 0, y: 0 },
      },
    },
  ],
};

export const normalizeLayout = (key: DashboardTemplateKey) => {
  const result: DashboardLayout | undefined = {
    lg: [],
    md: [],
    sm: [],
    xs: [],
    xxs: [],
  };

  dashboardTemplate[key].forEach((widget) => {
    Object.keys(widget.sizes).forEach((size) => {
      const resultSize = result[size as keyof DashboardLayout];
      if (!resultSize) return;

      const widgetData: Layout = {
        i: widget.i,
        w: widget.sizes[size].w,
        h: widget.sizes[size].h,
        static: false,
        x: widget.sizes[size].x,
        y: widget.sizes[size].y,
        props:
          widget.props && Object.keys(widget.props).length
            ? widget.props
            : undefined,
      };

      resultSize.push(widgetData);
    });
  });

  return result;
};

export const useDashboardTemplate = () => {
  const { features } = useFeatureFlags();

  return useMemo(() => {
    const permissions: Record<DashboardTemplateKey, FeatureFlag> = {
      "Environmental dashboard": "dashboard-template-environmental",
      "Carbon dashboard": "dashboard-template-carbon",
      "Electricity dashboard": "dashboard-template-electricity",
    };
    return Object.entries(permissions)
      .filter(([, permission]) => features.includes(permission))
      .map(([label]) => ({ label, value: label }));
  }, [features]);
};
