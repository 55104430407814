import React from "react";
import { Divider, DividerProps } from "antd";
import classnames from "classnames";
import { FC } from "react";
import { TestID } from "../../shared/testids/testids";
import { COREBody } from "../Typography/COREBody";
import "./COREDivider.less";
import { AllColorName } from "../Content/COREColour";

type DividerLabelPros = "labelColor" | "textColor" | "labelWeight";

const setLabelPros = (
  labelProps: DividerLabelPros,
  param: LabelTextColor | LabelWeight
) => {
  if (labelProps === "labelColor")
    return param === "default" ? "label-bg-grey20" : "label-bg-" + param;
  if (labelProps === "textColor")
    return param === "default" ? "label-color-grey140" : "label-color-" + param;
  if (labelProps === "labelWeight") return "label-weight-" + param;
};

type LabelTextColor =
  | Extract<
      AllColorName,
      | "grey20"
      | "grey40"
      | "grey80"
      | "grey100"
      | "grey120"
      | "grey140"
      | "indigo10"
      | "indigo80"
      | "turquoise10"
      | "turquoise100"
      | "blue10"
      | "blue100"
      | "lime10"
      | "lime100"
      | "yellow10"
      | "yellow100"
      | "purple10"
      | "purple100"
      | "orange10"
      | "orange100"
      | "red10"
      | "red100"
      | "pink10"
      | "pink100"
      | "black"
      | "white"
    >
  | "default";

type DividerSize = "xs" | "sm" | "md" | "lg" | "xl" | "none";

type LabelWeight = "lighter" | "normal" | "bold";
interface CommonProps {
  testID?: TestID;
  dashed?: boolean;
  space?: DividerSize;
  orientation?: orientation;
  noOrientationMargin?: boolean;
  labelColor?: LabelTextColor;
  textColor?: LabelTextColor;
  labelWeight?: LabelWeight;
}

type DividerType =
  | {
      type?: "horizontal";
      height?: never;
    }
  | {
      type?: "vertical";
      height?: number | string;
    };

type orientation = "left" | "center" | "right";

type COREDividerProps = CommonProps &
  Omit<DividerProps, "type" | "plain"> &
  DividerType;
export const COREDivider: FC<COREDividerProps> = ({
  testID,
  className,
  children,
  orientation,
  noOrientationMargin = false,
  height = "100%",
  type = "horizontal",
  space = "lg",
  labelColor = "default",
  textColor = "default",
  labelWeight = "normal",
  ...props
}) => {
  const labelBg = setLabelPros("labelColor", labelColor);
  const textLabelColor = setLabelPros("textColor", textColor);
  const fontWeight = setLabelPros("labelWeight", labelWeight);
  return (
    <Divider
      {...(type === "vertical" && height ? { style: { height } } : {})}
      {...(orientation !== undefined ? { orientation } : {})}
      {...(noOrientationMargin ? { orientationMargin: 0 } : {})}
      {...props}
      data-testid={testID}
      type={type}
      className={classnames(
        "core-divider",
        className,
        space,
        labelBg,
        textLabelColor,
        fontWeight
      )}
    >
      {children && (
        <COREBody type="p3" marginBottom={false} className={"body-padding"}>
          {children}
        </COREBody>
      )}
    </Divider>
  );
};
