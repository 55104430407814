import { useAPIQueryRequest } from "./useAPI";
import {
  appQueryClient as queryClient,
  mutation,
} from "../state/appQueryClient";
import { useMutation } from "react-query";
import { message } from "antd";
import { useUserLoggedInStatus, useUserRole } from "../state/user";

// T type sent from Called function, In each case different type
export type Data<T> = {
  config: T;
  id: number;
  me: boolean;
  type: string; // eslint-disable-next-line camelcase
  user_id: number;
};

export type UpdatableConfigs<T> = {
  data: Data<T>;
  updateConfig: (newConfig: T) => Promise<T | undefined>;
  deleteConfig: () => Promise<T | undefined>;
};

export type UseUserConfigs<T> = {
  sync?: boolean;
  loading?: boolean;
  error: Error | false;
  configs: UpdatableConfigs<T>[];
  add: (config: T) => Promise<T | undefined>;
};

type Parameter = {
  type?: string;
  me: string;
};

export const useUserConfigs = <T>(type: string): UseUserConfigs<T> => {
  const isLoggedIn = useUserLoggedInStatus();
  const isPendingRole = useUserRole() === "pending";

  const params: Parameter = {
    me: `eq.true`,
    ...(type !== "all" && { type: `eq.${type}` }),
  };

  const {
    sync,
    loading,
    error,
    data: { data: cs },
  } = useAPIQueryRequest(
    "user_configs",
    params,
    //skip this request for pending user because they don't have permission on user_configs and it will return error and make many of retry
    !isLoggedIn || isPendingRole
  );

  const allData: Data<T>[] = cs === undefined ? [] : cs;

  const addUserConfig: ({
    config,
  }: {
    config: T;
  }) => Promise<{ readonly data: [T] }> = ({ config }: { config: T }) => {
    return queryClient.fetchQuery({
      queryKey: [
        "user_configs_post",
        {
          action: "user_configs_post",
          body: JSON.stringify({
            config: config,
            type: type,
          }),
          enabled: true,
        },
      ],
    });
  };

  const addMutation = useMutation<
    { readonly data: [T] },
    { readonly response: { data: { message: string } } },
    { config: T },
    void
  >(addUserConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries("user_configs");
    },
    onError: (error) => {
      message.error(
        error?.response?.data?.message ?? "Unable to add new config."
      );
    },
  });

  const add: (config: T) => Promise<T | undefined> = async (config: T) => {
    const result = await addMutation.mutateAsync({ config });
    return result?.data[0];
  };

  const updateUserConfig: ({
    config,
    configId,
  }: {
    config: T;
    configId: number;
  }) => Promise<{ readonly data: [T] }> = ({
    config: newConfig,
    configId,
  }: {
    config: T;
    configId: number;
  }) => {
    return mutation({
      queryKey: [
        "user_configs_patch",
        {
          action: "user_configs_patch",
          params: { id: configId },
          body: JSON.stringify({
            config: newConfig,
            type: type,
            id: configId,
          }),
          enabled: true,
        },
      ],
    }) as Promise<{ readonly data: [T] }>;
  };

  const updateMutation = useMutation<
    { readonly data: [T] },
    { readonly data: { message: string } },
    { config: T; configId: number },
    void
  >(updateUserConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries("user_configs");
    },
    onError: (x) => {
      message.error("unknown error");
      console.error(x?.data);
    },
  });

  const deleteUserConfig: ({
    configId,
  }: {
    configId: number;
  }) => Promise<{ readonly data: [T] }> = ({
    configId,
  }: {
    configId: number;
  }) => {
    return mutation({
      queryKey: [
        "user_configs_delete",
        {
          action: "user_configs_delete",
          params: { id: configId },
          enabled: true,
        },
      ],
    }) as Promise<{ readonly data: [T] }>;
  };

  const deleteMutation = useMutation<
    { readonly data: [T] },
    { readonly data: { message: string } },
    { configId: number },
    void
  >(deleteUserConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries("user_configs");
    },
    onError: (error) => {
      message.error(error?.data.message ?? "unknown error");
    },
  });

  const updatableConfigs: UpdatableConfigs<T>[] = allData.map(
    (value: Data<T>) => {
      const updateConfig = async (newConfig: T) => {
        const res = await updateMutation.mutateAsync({
          config: newConfig,
          configId: value.id,
        });
        return res?.data[0]; // Ensure the return type is T or undefined
      };

      const deleteConfig = async () => {
        const result = await deleteMutation.mutateAsync({ configId: value.id });
        return result?.data[0]; // Ensure the return type is T or undefined
      };

      return {
        data: value,
        updateConfig,
        deleteConfig,
      };
    }
  );

  return {
    sync: sync || false,
    loading: loading || false,
    error: error || false,
    configs: updatableConfigs,
    add,
  };
};
