import React, { Dispatch, SetStateAction, useState } from "react";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { ColorHex, getColorName } from "../Content/COREColour";
import { CORETag, CORETagProps } from "../Content/CORETag";
import { Legend } from "./COREChartLegend";
import { useBreakpoint } from "../../shared/customHoooks/useBreakpoint";
import { COREModal } from "../Overlay/COREModal";
import { COREButton } from "../Action/COREButton";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { COREIcon } from "../Content/COREIcon";
import { useOpenClose } from "../../shared/global";
import {
  defaultResizeValue,
  OnResize,
  SetSizeObject,
} from "../../shared/OnResize";
import { CORETagList, CORETagListProps } from "../Content/CORETagList";

type tagLegendProps = {
  selectLegend: Legend[];
  setSelectLegend: Dispatch<SetStateAction<Legend[]>>;
  legendFilter?: boolean;
  testID?: TestID;
  truncateAfter?: CORETagListProps["truncateAfter"];
  maxWidth?: CORETagProps["maxWidth"];
  isShowModal?: boolean;
};

const SelectedTagLegends: React.FC<
  tagLegendProps &
    Pick<
      CORETagListProps,
      "wrap" | "justify" | "showTooltip" | "span" | "vertical"
    >
> = ({
  selectLegend,
  setSelectLegend,
  legendFilter,
  testID,
  maxWidth,
  truncateAfter = 10,
  wrap,
  justify,
  showTooltip,
  span,
  vertical,
}) => {
  const handleClick = (tagName: string) => {
    const selectedTags = selectLegend.map((legend) => {
      if (legend.label === tagName) legend.checked = !legend.checked;
      return legend;
    });
    setSelectLegend(selectedTags);
  };
  return (
    <CORETagList
      testID={testID}
      truncateAfter={truncateAfter}
      wrap={wrap}
      justify={justify}
      showTooltip={showTooltip}
      span={span}
      vertical={vertical}
    >
      {selectLegend?.map((item) => {
        const colorX = getColorName(item.color as ColorHex);
        return (
          <CORETag
            key={item.key}
            {...(testID && {
              testID: `${testID}-${item.key}` as TestID,
            })}
            type={"colourful"}
            label={item.label}
            maxWidth={maxWidth}
            colour={colorX}
            tagStyle={item.tagStyle || `solid`}
            checked={item.checked}
            closable={false}
            {...(legendFilter && {
              onClick: () => handleClick(item.label as string),
              onClose: (e) => e.preventDefault(),
            })}
          />
        );
      })}
    </CORETagList>
  );
};

const SelectedTagsModal: React.FC<tagLegendProps> = ({
  testID,
  selectLegend,
  setSelectLegend,
  legendFilter,
  maxWidth,
}) => {
  const { isOpen, closer, opener } = useOpenClose(false);
  const [containerSize, setContainerSize] =
    useState<SetSizeObject>(defaultResizeValue);
  const [hiddenTooltip, setHiddenTooltip] = useState<boolean>(false);

  return (
    <>
      <COREModal
        testID={`${testID}-modal` as TestID}
        type={"basic"}
        visible={isOpen}
        onCancel={() => {
          setHiddenTooltip(false);
          closer();
        }}
        title={"Legend"}
        destroyOnClose
        footer={[
          <COREButton
            key={"close"}
            testID={`${testID}-view-legend` as TestID}
            type={"primary"}
            icon={
              <COREIcon
                icon={icon({ name: "circle-check", style: "regular" })}
              />
            }
            onClick={() => {
              setHiddenTooltip(false);
              closer();
            }}
          >
            Close
          </COREButton>,
        ]}
      >
        <OnResize onResize={setContainerSize}>
          <SelectedTagLegends
            testID={testID}
            selectLegend={selectLegend}
            setSelectLegend={setSelectLegend}
            legendFilter={legendFilter}
            maxWidth={containerSize?.width as number}
            wrap={true}
            showTooltip={false}
            span={24}
          />
        </OnResize>
      </COREModal>
      <COREButton
        testID={`${testID}-view-legend` as TestID}
        type={"text"}
        icon={<COREIcon icon={icon({ name: "eye", style: "solid" })} />}
        onClick={() => {
          setHiddenTooltip(true);
          opener();
        }}
        displayTooltip={true}
        {...(!hiddenTooltip && {
          tooltipTitle: (
            <SelectedTagLegends
              testID={testID}
              selectLegend={selectLegend}
              setSelectLegend={setSelectLegend}
              legendFilter={legendFilter}
              maxWidth={maxWidth}
              wrap={true}
              justify={"center"}
              vertical={true}
            />
          ),
        })}
      >
        View legend
      </COREButton>
    </>
  );
};

export const CORELegend: React.FC<tagLegendProps> = ({
  selectLegend,
  setSelectLegend,
  legendFilter,
  testID,
  truncateAfter,
  maxWidth,
  isShowModal = true,
}) => {
  const breakPoint = useBreakpoint();
  const isSmallScreen = isShowModal
    ? ["xxs"].includes(breakPoint)
    : isShowModal;

  return (
    <TestIDWrapper testID={testID as TestID}>
      {!isSmallScreen ? (
        <SelectedTagLegends
          testID={testID}
          selectLegend={selectLegend}
          setSelectLegend={setSelectLegend}
          legendFilter={legendFilter}
          maxWidth={maxWidth}
          truncateAfter={truncateAfter}
          wrap={true}
          justify={"center"}
          showTooltip={true}
        />
      ) : (
        <SelectedTagsModal
          testID={testID}
          selectLegend={selectLegend}
          setSelectLegend={setSelectLegend}
          legendFilter={legendFilter}
          maxWidth={maxWidth}
        />
      )}
    </TestIDWrapper>
  );
};
