import React, { ReactNode } from "react";
import { Checkbox } from "antd";
import { TestID, TestIDWrapper } from "../../shared/testids/testids";
import { CheckboxProps } from "antd/lib/checkbox";
import { Merge } from "../../shared/TypeScriptHelpers";
import "./CORECheckbox.less";

type CheckboxPropsType = Merge<
  CheckboxProps,
  {
    label?: ReactNode;
    testID: TestID;
  }
>;

export const CORECheckbox: React.FC<CheckboxPropsType> = ({
  label,
  testID,
  ...otherProps
}) => (
  <TestIDWrapper testID={testID}>
    <div className={"core-checkbox"}>
      <Checkbox {...otherProps}>{label}</Checkbox>
    </div>
  </TestIDWrapper>
);
