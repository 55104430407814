import { Spin } from "antd";
import React, { FunctionComponent, ReactNode } from "react";
import {
  generateTestId,
  TestID,
  TestIDWrapper,
} from "../../shared/testids/testids";
import {
  CORESpinner,
  CORESpinnerProps,
  CORESpinnerSize,
} from "../Content/CORESpinner";
import "./CORELoading.less";
import { COREBody, COREBodyProps } from "../Typography/COREBody";

export type CORELoadingProps = {
  message?: string;
  component?: ReactNode;
  delay?: number;
  children?: ReactNode;
  loading?: boolean;
  testID?: TestID;
  layout?: "vertical" | "horizontal";
} & Pick<CORESpinnerProps, "size">;

const bodySize: Record<CORESpinnerSize, COREBodyProps["type"]> = {
  lg: "p1",
  md: "p2",
  sm: "p3",
};

export const CORELoading: FunctionComponent<CORELoadingProps> = ({
  message = "Loading",
  component: c,
  delay = 1000,
  children,
  loading = false,
  testID = generateTestId("page", "loading-component"),
  size = "md",
  layout = "vertical",
}) => {
  if (children) {
    return (
      <TestIDWrapper testID={testID} className={"core-loading"}>
        <Spin
          tip={
            <COREBody type={bodySize[size]} marginBottom={false}>
              {message}
            </COREBody>
          }
          delay={delay}
          spinning={loading}
          indicator={<CORESpinner size={size} />}
          className={`core-loading-layout-${layout}`}
        >
          {children}
        </Spin>
      </TestIDWrapper>
    );
  }

  const component = c || (
    <TestIDWrapper testID={testID} className={"core-loading"}>
      <Spin
        tip={
          <COREBody type={bodySize[size]} marginBottom={false}>
            {message}
          </COREBody>
        }
        delay={delay}
        data-testid={testID}
        indicator={<CORESpinner size={size} />}
        className={`core-loading-layout-${layout}`}
      />
    </TestIDWrapper>
  );
  return <>{component}</>;
};
